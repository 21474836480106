/* eslint-disable no-console */
import makeAPIRequest from "../../../../services/makeRequest";
import config from "./../../../../config";

async function createImpactReport(payload, accessToken) {
	console.log(payload, "in submit");
	const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/admin/impactreport`;

	const response = await makeAPIRequest(
		API_ENDPOINT,
		"POST",
		payload,
		accessToken
	);
	const jsonResponse = await response.json();
	return jsonResponse;
}

export default createImpactReport;
