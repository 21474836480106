import React from "react";
import PropTypes from "prop-types";

function Logo({ color }) {
  const logoSource =
    color === "green" ? "https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/static/images/COhatch%20Logo%20Horizontal%20w_tag%20green.png" : "https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/static/images/COhatch%20logo_horizontal%20w_tag_white%20RGB%20(1).png";
  return (
    <>
      <span className="sr-only">COhatch+</span>
      <img className="h-8 w-auto sm:h-10" src={logoSource} alt="COhatch+ Logo" />
    </>
  );
}

Logo.propTypes = {
  color: PropTypes.string,
};

export default React.memo(Logo);


