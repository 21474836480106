import config from "../../config";
import makeAPIRequest from "../../services/makeRequest";

async function changePlan(accessToken, payload) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/admin/member/change-plan`;
  const response = await makeAPIRequest(API_ENDPOINT, "POST", payload, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default changePlan;
