import config from "../../config";
import makeAPIRequest from "../../services/makeRequest";

const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/admin/contact/reply`;

async function createContactMessageReply(body, accessToken) {
  const response = await makeAPIRequest(`${API_ENDPOINT}`, "POST", body, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default createContactMessageReply;
