/* eslint-disable no-console */
import makeAPIRequest from "../../../services/makeRequest";
import config from "../../../config";

async function getAppNotifications(accessToken, applicationId) {
  

  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/admin/impactnotification/${applicationId}`;
  const response = await makeAPIRequest(API_ENDPOINT, "GET", null, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default getAppNotifications;