/* eslint-disable no-console */
import makeAPIRequest from "../../services/makeRequest";
import config from "./../../config";

async function submitPassworResetRequestForm(payload) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/admin/admin_password/password_reset_request`;
  const response = await makeAPIRequest(API_ENDPOINT, "POST", payload);
  const jsonResponse = await response.json();
  return jsonResponse;

}

export default submitPassworResetRequestForm;
