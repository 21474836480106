/*eslint-disable*/
import React, {useCallback} from "react";
import { EllipsisHorizontalIcon, PencilIcon } from "@heroicons/react/20/solid";
import ApplicantCard from "../../scholarship_applications/ApplicantCard";
import {debounce} from "../../../services/util";
import {PLANS,} from "../../../constant";
import Select from "react-select";
import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import { PieChart, Pie, Tooltip, Cell } from "recharts";
import Table from "../../../components/table/Table";
import Widget from "../dashboard/Widgets";




function renderApps(app, index) {
	return <ApplicantCard key={index} application={app}/>;
}
const tabs = [
  { name: "Give", href: "#", current: true },
  { name: "Boost", href: "#", current: false },
  { name: "Main St.", href: "#", current: false },
  { name: "All", href: "#", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}



function LocationDashboard() {
const data = [
  { name: 'Group A', value: 400, color: '#0088FE' },
  { name: 'Group B', value: 300, color: '#00C49F' },
  { name: 'Group C', value: 300, color: '#FFBB28' },
  { name: 'Group D', value: 200, color: '#FF8042'},
];

const users = [
  {location: 'Delaware', approved: '0', pending: '0', denied: "0", goal: 15, icon: <div className="w-5"><PencilIcon/></div>},
  {location: 'Easton',  approved: '0', pending: '0', denied: "0", goal: 15, icon: <div className="w-5"><PencilIcon/></div>},
   {location: 'Upper Arlington',  approved: '0', pending: '0', denied: "0", goal: 15, icon: <div className="w-5"><PencilIcon/></div>},
  {location: 'Polaris',  approved: '0', pending: '0', denied: "0", goal: 15, icon: <div className="w-5"><PencilIcon/></div>},
  {location: 'Worthington - Hardware',  approved: '0', pending: "0", denied: "0", goal: 15, icon: <div className="w-5"><PencilIcon/></div>},
  {location: 'Worthington - Library',  approved: '0', pending: '0', denied: "0", goal: 15, icon: <div className="w-5"><PencilIcon/></div>},
  {location: 'Dublin',  approved: '0', pending: '0', denied: "0", goal: 15, icon: <div className="w-5"><PencilIcon/></div>},
  {location: 'Gateway',  approved: '0', pending: '0', denied: "0", goal: 15, icon: <div className="w-5"><PencilIcon/></div>},
  {location: 'Westerville',  approved: '0', pending: '0', denied: "0", goal: 15, icon: <div className="w-5"><PencilIcon/></div>},
    {location: 'Springfield',  approved: '0', pending: '0', denied: "0", goal: 15, icon: <div className="w-5"><PencilIcon/></div>},
]

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

  const handleSearch = () => {
    // const query = event.target.value;
    // fetchUsers(userState.accessToken, 0, query, productRef.current);
    // setKeyword(query);
    return 1;
  };

  const debouncedHandleSearch = useCallback(debounce(handleSearch, 500), []);

  const apps = [];

  return (
    <>
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
   <div className="mb-5 mt-7">
    <h1 className="mb-5 font-bold text-3xl">
    Scholarship Overview
    </h1>
    <p className="font-bold text-2xl">
    Columbus
    </p>
   </div>
   <div className="max-w-7xl">
    <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          defaultValue={tabs.find((tab) => tab.current).name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="isolate flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
          {tabs.map((tab, tabIdx) => (
            <a
              key={tab.name}
              href={tab.href}
              className={classNames(
                tab.current ? "text-gray-900" : "text-gray-500 hover:text-gray-700",
                tabIdx === 0 ? "rounded-l-lg" : "",
                tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
              )}
              aria-current={tab.current ? "page" : undefined}
            >
              <span>{tab.name}</span>
              <span
                aria-hidden="true"
                className={classNames(
                  tab.current ? "bg-gray-50" : "bg-transparent",
                  "absolute inset-x-0 bottom-0 h-0.5"
                )}
              />
            </a>
          ))}
        </nav>
      </div>
      </div>

     
        <div className="widgets gap-x-20 p-20 flex-1 flex">

					<div className="widget h-36 bg-white rounded-lg shadow-md shadow-grey-600 flex flex-1 p-2 justify-between">
			    <div className="left flex flex-col ">
          <span className="title text-4xl font-bold mb-3">$105k</span>
          <span className="counter text-gray-500 text-xl">Scholarship Dollars Awarded</span>
          </div>
          </div>

        	<div className="widget h-36 bg-white rounded-lg shadow-md shadow-grey-600 flex flex-1 p-2 justify-between">
			    <div className="left flex flex-col ">
          <span className="title text-4xl font-bold mb-3">0</span>
          <span className="counter text-gray-500 text-xl">Active Scholars </span>
          </div>
          </div>

        	<div className="widget h-36 bg-white rounded-lg shadow-md shadow-grey-600 flex flex-1 p-2 justify-between">
			    <div className="left flex flex-col ">
          <span className="title text-4xl font-bold mb-3">0</span>
          <span className="counter text-gray-500 text-xl">Pending Scholars </span>
          </div>
          </div>

        	<div className="widget h-36 bg-white rounded-lg shadow-md shadow-grey-600 flex flex-1 p-2 justify-between">
			    <div className="left flex flex-col ">
          <span className="title text-4xl font-bold mb-3">0</span>
          <span className="counter text-gray-500 text-xl">Denied Scholars </span>
          </div>
          </div>
			</div>
        
            
 {/* <div className="w-52 h-52 ">
  
      <CircularProgressbarWithChildren
              value={75}
              strokeWidth={8}
              styles={buildStyles({
                pathColor: "#f00",
                trailColor: "transparent",
                textColor: "black"
              })}
            
              text={"110k"}
            >
              
              <div style={{ width: "84%" }}>
                <CircularProgressbar
                  value={70}
                  styles={buildStyles({
                  trailColor: "transparent"
                  })}
                />
              </div>
            </CircularProgressbarWithChildren>
            </div>

            <div>
              <h1 className="pl-5 text-2xl semi-bold">Scholars by Cause</h1>
            <PieChart width={300} height={300}>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={120}
                fill="#8884d8"
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
          </PieChart>
          </div>
  */}

          
   
     
   
   
      

    <div className="w-full flex justify-between">
    <input
      type="text"
      name="search"
      onInput={debouncedHandleSearch}
      placeholder="Search Location"
      className="block border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 max-w-md sm:text-base"
    />

     <Select
      className="border-0 w-64"
      placeholder="Filter by..."
      isDisabled={false}
      isClearable={true}
      isLoading={false}
      isRtl={false}
      isSearchable={true}
      name="filter"
      options={Object.keys(PLANS).map(plan => ({
        value: plan,
        label: `${PLANS[plan]} PLAN`,
      }))}
      onChange={event => handleFilterBy(event ? event.value : "")}
      theme={theme => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: "#ecf8ef",
          primary: "#56c271",
        },
      })}
        /> 
    </div>

  
    <Table
        isLoading={false}
        columns={[
          {
            name: "Location",
            value: "location",
          },
          {
            name: "Approved",
            value: "approved",
          },
          {
            name: "Pending",
            value: "pending",
          },
          {
            name: "Denied",
            value: "denied",
          },
          {
            name: "Goal",
            value: "goal",
          },
          {
            name: "Edit",
            value: "icon",
          }
          
        ]}
        data={users}
      />

    </div>
    </>
  );


}

export default LocationDashboard;