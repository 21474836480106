/* eslint-disable */
import { Outlet } from "react-router-dom";
import Title from "../../../components/title/Title";
import Breadcrumb from "../../../components/breadcrumb/Breadcrumb";
import LinkTab from "../../../components/link_tab/LinkTab";

function TechnicalSupport() {
  return (
    <>
      <Title name="Stories" />
      <Breadcrumb routes={["Dashboard", "Stories"]} />
      <LinkTab
        items={[
           {
            link: "/impact/technical-support",
            title: "Stories",
          },
          {
            link: "/impact/technical-support/featured_stories",
            title: "Featured Stories",
          },
          // {
          //   link: "/impact/technical-support/events",
          //   title: "Events",
          // },
          
        ]}
      />

      <div className="mt-3">
        <Outlet />
      </div>
    </>
  );
}

export default TechnicalSupport;