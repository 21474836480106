/* eslint-disable*/
import React, { useState, useEffect } from "react";
import useUserContext from "../../../hooks/useUserContext";
import getApplications from "../../scholarship_applications/requests/getApplications";
import ButtonSecondary from "../../../components/button/ButtonSecondary";
import Pagination from "../../../components/pagination/Pagination";

function Leaderboard() {
	const { userState } = useUserContext();

	const [apps, setApps] = useState([]);
	const [pagination, setPagination] = React.useState({
		total: 0,
		page: 0,
		remaining: 0,
		limit: 10,
	});

	const fetchApplications = async (accessToken) => {
		try {
			//setIsAppsLoading(true);
			const response = await getApplications(accessToken);
			if (response.status === "success") {
				console.log(response);
				//const { limit, page, remaining, total, results } = response.data;
				//setPagination({ limit, page: page, remaining, total });

				setApps(response.data);
			} else if (response.status === "fail") {
				let displayMessage = "";
				if (Array.isArray(response.message)) {
					displayMessage = response.message[0].message;
				} else {
					displayMessage = response.message;
					if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
						displayMessage =
							"An error has occurred. Please try again later. If this error persists please contact support";
					}
				}
				notify(displayMessage, "error");
			}
		} catch (error) {
			//	notify("something went wrong");
		} finally {
			//setIsAppsLoading(false);
		}
	};

	const groupApplicationsByCause = (applications) => {
		const groupedData = {};

		applications.forEach((application) => {
			const { causes, appStatus, annualAward } = application;

			causes?.forEach((cause) => {
				if (!groupedData[cause]) {
					groupedData[cause] = {
						total: 0,
						pending: 0,
						approved: 0,
						rejected: 0,
						totalAnnualAward: 0,
					};
				}

				groupedData[cause].total += 1;
				groupedData[cause].totalAnnualAward += annualAward;

				switch (appStatus) {
					case "pending":
						groupedData[cause].pending += 1;
						break;
					case "approved":
						groupedData[cause].approved += 1;
						break;
					case "rejected":
						groupedData[cause].rejected += 1;
						break;
					default:
						break;
				}
			});
		});

		return groupedData;
	};

	const groupApplicationsByCategory = (applications) => {
		const groupedData = {};

		applications.forEach((application) => {
			const { category, appStatus, annualAward, appType } = application;

			if (category !== null) {
				if (!groupedData[category]) {
					groupedData[category] = {
						total: 0,
						pending: 0,
						approved: 0,
						rejected: 0,
						totalAnnualAward: 0,
					};
				}

				groupedData[category].total += 1;
				groupedData[category].totalAnnualAward += annualAward;

				switch (appStatus) {
					case "pending":
						groupedData[category].pending += 1;
						break;
					case "approved":
						groupedData[category].approved += 1;
						break;
					case "rejected":
						groupedData[category].rejected += 1;
						break;
					default:
						break;
				}
			}
		});

		return groupedData;
	};

	const groupedCatData = groupApplicationsByCategory(apps);
	const groupedData = groupApplicationsByCause(apps);

	const itemsPerPage = 10;

	const [currentPage, setCurrentPage] = useState(1);

	const totalPages = Math.ceil(Object.keys(groupedData).length / itemsPerPage);

	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;

	const currentData = Object.keys(groupedData).slice(startIndex, endIndex);

	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};

	const handlePrevFetch = async () => {
		await fetchApplications(
			userState.accessToken,
			pagination.page - 1,
			keyword,
			" "
		);
	};

	const handleNextFetch = async () => {
		await fetchApplications(
			userState.accessToken,
			pagination.page + 1,
			keyword,
			" "
		);
	};

	useEffect(() => {
		fetchApplications(userState.accessToken, 0, "", "");
	}, []);

	useEffect(() => {
		groupApplicationsByCause(apps);
		groupApplicationsByCategory(apps);
	}, [apps]);

	return (
		<div className="mb-5 mt-7">
			<h1 className="mb-5 font-bold text-2xl">Leaderboard</h1>

			<div className="max-w-5xl">
				<div className="">
					<div className="max-w-7xl bg-white rounded-lg shadow-md shadow-slate-600 p-3 m-4">
						<div className="my-5">
							<h1 className="inline">
								<span className="text-xl font-bold text-gray-900">
									Give Scholars
								</span>
							</h1>
						</div>
						<div className="overflow-auto">
							<table className="table table-responsive w-full divide-y divide-gray-300 ">
								<thead className="rounded mb-3 bg-sky-100 shadow ring-1 ring-black ring-opacity-5">
									<tr>
										<th
											scope=""
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500"
										>
											Cause Supported
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500"
										>
											Total Applications
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500"
										>
											Total Pending
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500"
										>
											Total Approved
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500"
										>
											Total Denied
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500"
										>
											Total Award $
										</th>
									</tr>
								</thead>
								<tbody className="divide-y divide-gray-200 bg-white">
									{currentData.map((cause) => (
										<tr key={cause}>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
												<div>
													<div className="capitalize float-left ml-3 font-bold uppercase">
														<h1>{cause}</h1>
													</div>
												</div>
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
												{groupedData[cause].total}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
												{groupedData[cause].pending}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
												{groupedData[cause].approved}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
												{groupedData[cause].rejected}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
												${groupedData[cause].totalAnnualAward.toLocaleString()}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
						<div className="mt-1 pagination flex-1 flex justify-between sm:justify-end">
							<div className="flex gap-2">
								<ButtonSecondary
									action={() => handlePageChange(currentPage - 1)}
									disabled={currentPage === 1}
									text="Previous"
									size="md"
								/>
								{/* <span>{`Page ${currentPage} of ${totalPages}`}</span> */}
								<ButtonSecondary
									action={() => handlePageChange(currentPage + 1)}
									disabled={currentPage === totalPages}
									text="Next"
									size="md"
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="">
					<div className="max-w-7xl bg-white rounded-lg shadow-md shadow-slate-600 p-3 m-4">
						<div className="my-5">
							<h1 className="inline">
								<span className="text-xl font-bold text-gray-900">
									Boost Scholars
								</span>
							</h1>
						</div>
						<div className="overflow-auto ">
							<table className="table table-responsive w-full divide-y divide-gray-300">
								<thead className="rounded mb-3 bg-green-200 shadow ring-1 ring-black ring-opacity-5">
									<tr>
										<th
											scope=""
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500"
										>
											Category
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500"
										>
											Total Applications
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500"
										>
											Total Pending
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500"
										>
											Total Approved
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500"
										>
											Total Denied
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500"
										>
											Total Award $
										</th>
									</tr>
								</thead>
								<tbody className="divide-y divide-gray-200 bg-white">
									{Object.keys(groupedCatData).map((category) => (
										<tr key={category}>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
												<div>
													<div className="capitalize float-left ml-3 font-bold uppercase">
														<h1>
															{category === "minority_woman"
																? "Woman Owned"
																: category === "minority"
																? "Minority Owned"
																: category}
														</h1>
													</div>
												</div>
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
												{groupedCatData[category].total}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
												{groupedCatData[category].pending}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
												{groupedCatData[category].approved}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
												{groupedCatData[category].rejected}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
												$
												{groupedCatData[
													category
												].totalAnnualAward.toLocaleString()}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
						<Pagination
							pagination={pagination}
							next={handleNextFetch}
							prev={handlePrevFetch}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Leaderboard;
