/*eslint-disable*/
import React, {useState} from "react";
import { useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import 'chartjs-plugin-datalabels';
import { MAGIC_NUMBERS } from "../../../constant";
import getApplications from "../../scholarship_applications/requests/getApplications";
import useUserContext from "../../../hooks/useUserContext";

function DoughnutChart({apps}){

const {userState} = useUserContext();
//const [apps, setApps] = useState();
const filteredData = apps?.filter((app) => app.type === "give");
const Data = [
  { id: 1, name: 'John Doe', age: 25, city: 'New York', hobbies: ['Reading', 'Coding'] },
  { id: 2, name: 'Jane Smith', age: 30, city: 'San Francisco', hobbies: ['Coding', 'Traveling'] },
  { id: 3, name: 'Bob Johnson', age: 22, city: 'Los Angeles', hobbies: ['Gaming'] },
  { id: 4, name: 'Alice Williams', age: 28, city: 'Chicago', hobbies: ['Reading'] },
];

 const tallyCauses = (data) => {
  const causeCount = {};

  // Iterate through each object in the Data array
  data.forEach((app) => {
    // Iterate through the hobbies of each user
    app.causes.forEach((cause) => {
      // Increment the count for the current hobby
      causeCount[cause] = (causeCount[cause] || 0) + 1;
    });
  });

  return causeCount;
};

// Assuming Data is the array you provided earlier
const causesCount = tallyCauses(filteredData);

  const labels = Object.keys(causesCount);
  const data = Object.values(causesCount);

  const totalHobbies = data.reduce((acc, count) => acc + count, 0);
const options = {
    plugins: {
      legend: {
        display: true, // Hide the legend
      },
      tooltip: {
        enabled: true, // Disable tooltips
      },
      datalabels: {
        formatter: (value, context) => {
           const label = context.dataset.labels[context.dataIndex];
          const count = context.dataset.data[context.dataIndex];
          return `${label}: ${count} (${((count / totalHobbies) * 100).toFixed(1)}%)`;
        },
        color: '#fff',
        anchor: 'end',
        align: 'start',
        offset: 10,
      },
    },
  };

 
  const backgroundColors = [
    'rgba(255, 99, 132, 0.6)',
    'rgba(54, 162, 235, 0.6)',
    'rgba(255, 206, 86, 0.6)',
    'rgba(75, 192, 192, 0.6)',
    // Add more colors as needed
  ];



   const dataset = {
    labels,
    datasets: [
      {
        data,
        backgroundColor: backgroundColors,
      },
    ],
  };




  useEffect(() => {
   // fetchApplications(userState.accessToken);
   
  }, []);

  return (
    <div>
      <Doughnut 
      data={dataset}
      options={options}
      plugins={['datalabels']}
      />
     
    </div>
  );
}

export default DoughnutChart;