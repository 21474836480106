import config from "../../config";
import makeAPIRequest from "../../services/makeRequest";

const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/admin/referral_codes`;

async function createReferralCode(body, accessToken) {
  const response = await makeAPIRequest(API_ENDPOINT, "POST", body, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default createReferralCode;
