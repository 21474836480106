/* eslint-disable */
import { useEffect, useRef, useState } from "react";
import  {Calendar, DateRangePicker, DateRange}  from "react-date-range";
import {format, addDays, lastDayOfMonth, parseISO} from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import useUserContext from "../../hooks/useUserContext";



const DateRangeCalendar = ({
  setDateRange
}) => {

  const {userState} = useUserContext();
  const [openDate, setOpenDate] = useState(false);
 
  const today = new Date();
  const currentDate = format(today, 'MMMM dd, yyyy');

   const [date, setDate] = useState({
      startDate: new Date(),
      endDate: new Date(),
      key: "selection"
    });

  const handleChange = (ranges) => {
    setDate(ranges.selection);
    setDateRange(ranges.selection);
  };

  const handleClick = () => {
    setOpenDate((prev) => !prev)
  };


  return (
    <div className="container relative z-10">
      <div>
    	<div className=" w-96">
          <div className="w-full ">
            <label  className="text-lg text-gray-700">
              Choose Dates for Report
            </label>
          </div>
        <div  
        onClick={handleClick}
        className="cursor-pointer text-gray-700 w-full px-3 py-2 border border-gray-600 rounded-md">
        { date && 
        `${format(date.startDate, "MMMM dd, yyyy")} - ${format(date.endDate, "MMMM dd, yyyy")}`
        }
          <span className="ml-3 text-black material-icons-outlined">calendar_month</span>
        </div>
      </div>
      </div>
      
      <div>
      { openDate &&  
      <DateRangePicker
        className="dateRange"
        //maxDate={addDays(new Date(), 1)}
        months={2}
        ranges={[date]}
        direction="horizontal"
        preventSnapRefocus={true}
        calendarFocus="backwards"
        onChange={handleChange}
        />}
        
     </div>
    </div>
  );
};

export default DateRangeCalendar;
