/* eslint-disable no-magic-numbers */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React from "react";
import { useNavigate } from "react-router-dom";
import useUserContext from "../../hooks/useUserContext";
import Logo from "../../components/logo/Logo";
import ButtonPrimary from "../../components/button/ButtonPrimary";
import submitLogin from "./submitLogin";
import useTogglePassword from "./../../hooks/useTogglePassword";
import notify from "../../services/toast";
import { Link } from "react-router-dom";

const SERVER_ERROR_CODES = [404, 500];

function Login() {
  const { setUserState } = useUserContext();
  const navigate = useNavigate();
  const emailRef = React.useRef(null);
  const passwordRef = React.useRef(null);
  const passwordVisibilityRef = React.useRef(null);
  const [togglePasswordVisibility] = useTogglePassword(passwordRef, passwordVisibilityRef);

  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState(false);
  const [password, setPassword] = React.useState("");

  const [submitting, setSubmitting] = React.useState(false);

  const generateClassName = valid => {
    if (!valid) {
      return "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md";
    } else {
      return "appearance-none block w-full px-3 py-2 border border-red-300 rounded-md shadow-sm placeholder-red-400 focus:outline-none focus:ring-red-400 focus:border-red-400 sm:text-md";
    }
  };

  React.useEffect(() => {
    setError(false);
  }, [email, password]);

  React.useEffect(() => {
    emailRef.current.focus();
  }, []);

  const handleLogin = async event => {
    event.preventDefault();
    try {
      setSubmitting(true);
      const payload = {
        emailAddress: email,
        password: password,
      };
      const response = await submitLogin(payload);
      if (response.status === "success") {
        const { data, subscription, privacy, emailPreference } = response;
        setUserState(data);
        navigate("/dashboard");
      } else if (response.status === "fail") {
        setError(true);
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support";
          }
        }
        setSubmitting(false);
        notify(displayMessage, "error");
      }
    } catch (error) {
      setSubmitting(false);
      notify("Something went wrong. Please try again later", "error");
    }
  };

  return (
    <div className="h-screen w-full flex flex-col justify-center md:items-center py-12 sm:px-6 lg:px-8 font-roboto">
      <div className="flex justify-center">
        <Logo color="green" />
      </div>

      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-2xl font-extrabold text-black">Administrator Login</h2>
      </div>

      <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-5 px-4 sm:px-10">
          <form>
            <div>
              <input
                onChange={event => setEmail(event.target.value)}
                ref={emailRef}
                id="email"
                name="email"
                type="email"
                placeholder="Email address"
                required
                value={email}
                className={generateClassName(error)}
              />
            </div>

            <div className="relative mt-1">
              <input
                onChange={event => setPassword(event.target.value)}
                id="password"
                name="password"
                ref={passwordRef}
                type="password"
                placeholder="Password"
                required
                value={password}
                className={generateClassName(error)}
              />
              <span ref={passwordVisibilityRef} onClick={togglePasswordVisibility} className="absolute top-3 left-[90%] material-icons-outlined text-gray-400 cursor-pointer">
                visibility
              </span>
            </div>

            <div className="grid justify-items-end mt-3">
            {/* <div className="flex items-center justify-between mt-3"> */}
								{/* <div className="flex items-center">
									<input
										id="remember-me"
										name="remember-me"
										onChange={(event) => console.log(event.target.checked)}
										type="checkbox"
										className="h-4 w-4 text-primary-400 focus:ring-primary-400 border-gray-300 rounded"
									/>
									<label
										htmlFor="remember-me"
										className="ml-2 block text-sm text-gray-900"
									>
										Remember me
									</label>
								</div> */}

								<div className="text-sm">
									<Link
										to="/reset_request"
										className="font-medium text-primary-400 hover:text-primary-400"
									>
										Forgot your password?
									</Link>
								</div>
						</div>

            <div className="mt-5">
              <ButtonPrimary
                text="Login"
                icon="lock"
                action={handleLogin}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
