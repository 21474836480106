/* eslint-disable*/
import { Link } from "react-router-dom";

function StoryPage(story) {

console.log(story, "storypage");
	return (
		<>
			<div className="bg-gradient-to-b from-white to-green-200 rounded-xl from-5%">
				<main className=" pb-20">
					{/* Hero section */}
					<div className="pt-10 mx-auto max-w-2xl text-center">
						<h2 className="text-5xl text-blue-950 font-bold tracking-tight ">
						{story.story.blogTitle}
						</h2>
						<p className="mt-2 text-lg leading-8 text-gray-600">
						by {story.story.firstName} {story.story.lastName}
						</p>
					</div>

					<div className="pt-20 px-6 mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
						<h1 className="max-w-2xl text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl lg:col-span-2 xl:col-auto">
							{/* {location.state.orgName}  */}
						</h1>
						<div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
              <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">How did you use COhatch this year to support your
										organization?</h1>
							<p className="mt-6 text-2xl leading-8 text-gray-600">
								{story.story.prompts?.howWeHelped}
							</p>
						</div>
						<img
							src={story.story.coverImage?.small?.url ? story.story.coverImage.small.url : "https://images.unsplash.com/photo-1605656816944-971cd5c1407f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=768&h=604&q=80"}
							alt=""
							className="mt-10 aspect-[6/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36"
						/>
					</div>

					{/* Prompt  */}
					<div className="mt-32 overflow-hidden sm:mt-40">
						<div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
							<div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
								<div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
									<h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
										How did COhatch help you create connections, recieve funding, or any other related metrics this year?
									</h2>
									<p className="mt-6 text-2xl leading-8 text-gray-600">
								{story.story.prompts?.metrics}
									</p>
								</div>
								<div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
									<div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
										<img
											src={story.story.storyImages?.[0]?.storyImages?.[0]?.small?.url ? story.story.storyImages?.[0]?.storyImages?.[0].small?.url : "https://images.unsplash.com/photo-1670272502246-768d249768ca?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1152&q=80"}
											alt=""
											className="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover"
										/>
									</div>
									<div className="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
										<div className="order-first flex w-64 flex-none justify-end self-end lg:w-auto">
											<img
												src={story.story.storyImages?.[0]?.storyImages?.[1]?.small?.url ? story.story.storyImages?.[0]?.storyImages?.[1].small?.url : "https://images.unsplash.com/photo-1605656816944-971cd5c1407f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=768&h=604&q=80"}
												alt=""
												className="aspect-[4/3] w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
											/>
										</div>

										<div className="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
											<img
												src={story.story.storyImages?.[0]?.storyImages?.[2]?.small?.url ? story.story.storyImages?.[0]?.storyImages?.[2].small?.url : "https://images.unsplash.com/photo-1568992687947-868a62a9f521?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1152&h=842&q=80"}
												alt=""
												className="aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
											/>
										</div>
										<div className="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
											<img
												src={story.story.storyImages?.[0]?.storyImages?.[3]?.small?.url ? story.story.storyImages?.[0]?.storyImages?.[3].small?.url : "https://images.unsplash.com/photo-1612872087720-bb876e2e67d1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=768&h=604&q=80"}
												alt=""
												className="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* Prompt 2 */}
					<div className="mt-32 overflow-hidden sm:mt-40">
						<div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
							<div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
								<div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
									<h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
										How many times did you use the COhatch location?
									</h2>
									<p className="mt-6 text-2xl leading-8 text-gray-600">
										{story.story.prompts?.amountUsed}
									</p>
								</div>
								<div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
									<div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
										<img
											src={story.story.storyImages?.[0]?.storyImages?.[4]?.small?.url ? story.story.storyImages?.[0]?.storyImages?.[4].small?.url : "https://images.unsplash.com/photo-1670272502246-768d249768ca?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1152&q=80"}
											alt=""
											className="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* Stats */}
					<div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
						<div className="mx-auto max-w-2xl lg:mx-0">
							<h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
								What would you like to add about COhatch
							</h2>
							<p className="mt-6 text-xl text-base leading-7 text-gray-600">
								{story.story.prompts?.feedback}
							</p>
						</div>
						
					</div>

					
			
				</main>
			</div>
		</>
	);
}

export default StoryPage;
