/* eslint-disable */
import config from "../../../config";
import makeAPIRequest from "../../../services/makeRequest";



async function getMarketLevelData( accessToken, market) {
 const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/admin/impactdashboard/marketlevel/${market}`;
  const response = await makeAPIRequest(API_ENDPOINT, "GET", null, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default getMarketLevelData;
