/*eslint-disable*/
import React from "react";
// import Title from "../../components/title/Title";
// import Breadcrumb from "../../components/breadcrumb/Breadcrumb";

import { Outlet } from "react-router-dom";

function Scholarships() {
	return (
		<>
   
			{/* <Title name="COhatch Impact Scholarships" />
			<Breadcrumb routes={["Dashboard", "Technical Support"]} /> */}

			<div >
				<Outlet />
			</div>
		</>
	);
}

export default Scholarships;
