/* eslint-disable no-undef */
const config = {};

if (process.env.NODE_ENV === "production") {
  config["API_BASE_ENDPOINT"] = process.env.REACT_APP_PROD_API_BASE_ENDPOINT;
  config["SLACK_WEBHOOK_ENDPOINT"] = process.env.REACT_APP_DEV_SLACK_WEBHOOK_ENDPOINT;
  config["DOMAIN"] = process.env.REACT_APP_PROD_DOMAIN;
} else {
  config["API_BASE_ENDPOINT"] = process.env.REACT_APP_DEV_API_BASE_ENDPOINT;
  config["SLACK_WEBHOOK_ENDPOINT"] = process.env.REACT_APP_DEV_SLACK_WEBHOOK_ENDPOINT;
  config["DOMAIN"] = process.env.REACT_APP_DEV_DOMAIN;
}

config["GOOGLE_API_KEY"] = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
config["SUPPORT_EMAIL"] = "support@cohatchplus.com";

export default config;
