/* eslint-disable react/prop-types */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-console */
import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import ButtonPrimary from "../button/ButtonPrimary";
import { generateClassName } from "../../services/util";
import { validateCode } from "../../services/validation";
import notify from "../../services/toast";
import { LOCATIONS } from "../../constant";

const EditReferralCodeModal = ({
  open,
  referralCode,
  onClose,
  onSave,
}) => {
  const [code, setCode] = useState(referralCode.code || "");
  const [rlocation, setLocation] = useState(referralCode.location || "");

  const [validCode, setValidCode] = useState(false);

  const locationSelectRef = useRef(null);
  useEffect(() => {
    const result = validateCode(code);
    setValidCode(result);
  }, [code]);

  useEffect(() => {
    locationSelectRef.current.setValue(LOCATIONS.find(loc => loc.value === referralCode.location));
  }, [referralCode]);

  const handleSave = () => {
    if (!validCode || !rlocation) {
      notify("One or more fields are invalid. Please check before submitting.", "error");
      return;
    }

    onSave({
      code,
      location: rlocation,
    });
  };

  return (
    <div className={`${open ? "block" : "hidden"} relative z-10`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-gray-100 bg-opacity-60 transition-opacity"></div>

      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <h2 className="text-lg font-bold ">Edit Referral Code</h2>
            <div className="w-full mt-5">
              <div className="w-full flex justify-between items-center">
                <label htmlFor="code" className="block text-sm font-medium text-gray-700">
                  Code
                </label>
              </div>
              <div className="mt-1">
                <input
                  onChange={(event) => setCode(event.target.value)}
                  type="text"
                  name="code"
                  id="code"
                  value={code}
                  className={generateClassName(!validCode && code)}
                  placeholder="Code"
                  aria-describedby="name-optional"
                />
              </div>
            </div>
            <div className="w-full mt-5">
              <div className="w-full flex justify-between items-center">
                <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                  Location
                </label>
              </div>
              <div className="mt-1">
                <Select
                  placeholder="location"
                  isDisabled={false}
                  isClearable={true}
                  isLoading={false}
                  isRtl={false}
                  isSearchable={false}
                  name="location"
                  options={LOCATIONS}
                  onChange={event => setLocation(event ? event.value : "")}
                  ref={locationSelectRef}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#ecf8ef",
                      primary: "#56c271",
                    },
                  })}
                />
              </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <div>
                <button
                  onClick={onClose}
                  type="button"
                  className="ml-3 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                >
                  Close
                </button>
              </div>
              <div>
                <ButtonPrimary action={handleSave} loading={false} size="md" text="Save" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditReferralCodeModal;
