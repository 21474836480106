/* eslint-disable no-console */
import makeAPIRequest from "../../../services/makeRequest";
import config from "../../../config";

async function getAppById(accessToken, applicationId) {
	console.log("trying", applicationId);
	const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/admin/scholarshipapp/${applicationId}`;
	const response = await makeAPIRequest(API_ENDPOINT, "GET", null, accessToken);
	const jsonResponse = await response.json();
	return jsonResponse;
}

export default getAppById;
