/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable capitalized-comments */
import { format } from "date-fns";
import React from "react";
import { Link } from "react-router-dom";
import useUserContext from "../../hooks/useUserContext";
import notify from "../../services/toast";
import featureEvent from "./featureEvent";
import { WORKSHOP_CATEGORY } from "../../constant";
import { timeFormatter } from "../../services/util";

function EventCard({ event }) {
  const { userState } = useUserContext();
  const background = event?.eventImage?.large ? event.eventImage?.large.url : null;
  const logo = event?.eventOwnerImage?.small ? event.eventOwnerImage.small.url : null;
  const businessName = event?.listing?.businessName;
  const category = WORKSHOP_CATEGORY.find((item) => item.value === event.eventCategory)?.label;

  const address = event?.listing?.businessAddress ? event.listing?.businessAddress.address : null;
  const firstName = event?.eventOwnerFirstName ? event.eventOwnerFirstName : null;
  const lastName = event?.eventOwnerLastName ? event?.eventOwnerLastName : null;
  const email = event?.creator?.emailAddress ? event.creator.emailAddress : null;


  const eventName = event?.eventName;
  const approvalStatus = event?.approvalStatus;
  const eventDescription = event?.eventDescription;
  // const eventCode = event?.eventCode;
  // const useAge = event?.useAge;
  const eventDate = event?.eventDate;
  const startTime = event?.startTime;
  const endTime = event?.endTime;

  const [featured, setFeatured] = React.useState(() => (event?.featured ? true : false));
  const [featuring, setFeaturing] = React.useState(false);

  const handleFeature = async () => {
    if (event.approvalStatus !== "approved") {
      notify("This event has not been approved yet.", "error"); 
    } else {
      try {
        setFeaturing(true);
        const payload = {
          eventId: event._id,
          update: { featured: featured === true ? false : true },
        };
        const response = await featureEvent(userState.accessToken, payload);
        if (response.status === "success") {
          setFeatured(response.data.featured);
          notify("event featured status updated successfully", "success");
        } else {
          notify("event featured status was not updated successfully", "error");
        }
      } catch (error) {
        notify("Something went wrong. Please try again later", "error");
      } finally {
        setFeaturing(false);
      }
    }
  };

  return (
    <div className="w-[500px] divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
      <div className="relative">
        <img src={background ? background : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_cover_1.png"} alt="" className="w-full h-[200px] object-cover" />

        <div className="absolute top-[120px] left-[10px] w-full h-[200px] sm:w-[30%] sm:h-[200px]">
          <Link to={`/marketplace/listing/${event?.businessListing}`}>
            <img src={logo ? logo : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_logo_1.png"} alt="" className="w-[100px] h-[100px] rounded-full object-cover" />
          </Link>
        </div>

        <button
          type="button"
          disabled={featuring ? true : false}
          onClick={handleFeature}
          className="absolute top-[150px] gap-2 right-[10px] inline-flex items-center rounded-md border border-transparent bg-primary-400 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
          {featured ? "Featured" : "Feature"}
          {featured ? <span className="material-icons-outlined">favorite</span> : <span className="material-icons-outlined">favorite_border</span>}
        </button>
      </div>
      <div className="px-4 py-5 sm:p-6">
        <div className="mx-auto mt-6 max-w-5xl px-4 sm:px-6 lg:px-8">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            

          <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">Approval Status</dt>
              <dd className="mt-1 text-sm text-gray-900 uppercase">{approvalStatus}</dd>
          </div>
          <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">Event Name</dt>
              <dd className="mt-1 text-sm text-gray-900 uppercase">{eventName}</dd>
          </div>

          <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">Event Description</dt>
              <dd className="mt-1 text-sm text-gray-900">{eventDescription}</dd>
          </div>
            
            {/* <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Business name</dt>
              <dd className="mt-1 text-sm text-gray-900 uppercase">{businessName}</dd>
            </div> */}

            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Category</dt>
              <dd className="mt-1 text-sm text-gray-900 uppercase">{category}</dd>
            </div>

            {/* <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Sub category</dt>
              <dd className="mt-1 text-sm text-gray-900 uppercase">{subCategory}</dd>
            </div> */}

            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Event Owner Name</dt>
              <dd className="mt-1 text-sm text-gray-900 uppercase">
                {firstName} {lastName}
              </dd>
            </div>

            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">Creator Email</dt>
              <dd className="mt-1 text-sm text-gray-900 uppercase">{email}</dd>
            </div>

            {address ? (
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">Location</dt>
                <dd className="mt-1 text-sm text-gray-900">{address}</dd>
              </div>
            ) : null}

            {/* <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">event code</dt>
              <dd className="mt-1 text-sm text-gray-900 uppercase">{eventCode}</dd>
            </div> */}

            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">Event Date</dt>
              <dd className="mt-1 text-sm text-gray-900 uppercase">{format(new Date(eventDate), "MMMM do YYY")}</dd>
            </div>

            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">Event Time</dt>
              <dd className="mt-1 text-sm text-gray-900 uppercase">{timeFormatter(startTime)} - {timeFormatter(endTime)}</dd>
            </div>

            {/* <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">How to</dt>
              <dd className="mt-1 max-w-prose space-y-5 text-sm text-gray-900">
                <p>{useAge}</p>
              </dd>
            </div> */}
          </dl>
        </div>
      </div>
    </div>
  );
}

export default EventCard;
