/*eslint-disable*/
import React, { useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { MdLocationPin } from "react-icons/md";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";
import { format } from "date-fns";

import { Navigate } from "react-router-dom";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

function LeaderboardCard({ application, index }) {
	const navigate = useNavigate();
	const [showApplicantModal, setShowApplicantModal] = useState(false);
	const submissionDate = application.createdAt;
	//const renewalDate = format(new Date(submissionDate), "MM/dd/yy");
	const [editApplicant, setEditApplicant] = React.useState({
		admin: {
			notes: "",
		},
	});

	return (
		<>
			<tr key={index}>
				<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
					<div>
						<button //</div>onClick={() => navigate(`/impact/all_scholarships/${application.}`)}
						>
							<div className="capitalize float-left ml-3 text-gray-500 font-bold">
								<h1>{application.coLocation}</h1>
							</div>
						</button>
					</div>
				</td>

				<td className="">
					<p className=" font-bold uppercase">{application.giveCount}</p>
				</td>
				<td className="">
					<p className=" font-bold uppercase">{application.boostCount}</p>
				</td>
				<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
					${application.totalAward.toLocaleString()}
				</td>
			</tr>
		</>
	);
}

export default LeaderboardCard;
