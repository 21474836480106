/* eslint-disable */
import config from "../../../../config";
import makeAPIRequest from "../../../../services/makeRequest";



async function getImpactReportById(reportId, accessToken) {
  console.log(reportId, "trying")
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/admin/impactreport/${reportId}`;
	const response = await makeAPIRequest(API_ENDPOINT, "GET", null, accessToken);
	const jsonResponse = await response.json();
	return jsonResponse;
}
export default getImpactReportById;
