/* eslint-disable capitalized-comments */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable id-blacklist */

import React, { useState, useEffect, useRef, useCallback } from "react";
import Table from "../../components/table/Table";
import Pagination from "../../components/pagination/Pagination";
import useUserContext from "../../hooks/useUserContext";
import { debounce, showPrompt } from "../../services/util";
import ButtonDanger from "../../components/button/ButtonDanger";
import { LOCATIONS, MAGIC_NUMBERS } from "../../constant";
import notify from "../../services/toast";
import getOfficernd from "./getOfficernd";
import Prompt from "../../components/prompt/Prompt";
import resetPassword from "./resetPassword";
import Select from "react-select";

function CommunityManagerOfficernd() {
  const { userState } = useUserContext();
  const [users, setUsers] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [location, setLocation] = useState("");
  const [status, setStatus] = useState("");
  const [isUsersLoading, setIsUsersLoading] = useState(true);
  const [pagination, setPagination] = React.useState({ limit: 10, total: 0, page: 0, remaining: 0 });
  const [displayPromptMessage, setDisplayPromptMessage] = useState({ heading: "", body: "" });
  const [selectedUser, setSelectedUser] = useState(null);
  const resetPasswordPromptRef = useRef(null);

  const fetchUsers = async (accessToken, page, _keyword, location, status) => {
    setIsUsersLoading(true);
    const response = await getOfficernd(
      {
        page,
        keyword: _keyword,
        location: location,
        status: status,
        limit: pagination.limit,
      },
      accessToken
    );
    if (response.status === "success") {
      const { limit, page, remaining, total, results } = response.data;
      setPagination({ limit, page: page, remaining, total });
      setUsers(results);
    } else if (response.status === "fail") {
      let displayMessage = "";
      if (Array.isArray(response.message)) {
        displayMessage = response.message[0].message;
      } else {
        displayMessage = response.message;
        if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
          displayMessage = "An error has occurred. Please try again later. If this error persists please contact support";
        }
      }
      notify(displayMessage, "error");
    }
    setIsUsersLoading(false);
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    fetchUsers(userState.accessToken, 0, query, status);
    setKeyword(query);
  };

  const handleLocationFilter = (location) => {
    setLocation(location);
    fetchUsers(userState.accessToken, 0, "", location, status);
  };

  const handleStatusFilter = (status) => {
    setStatus(status);
    fetchUsers(userState.accessToken, 0, "", location, status);
  };

  const debouncedHandleSearch = useCallback(debounce(handleSearch, 500), []);

  const handlePrevFetch = async () => {
    await fetchUsers(userState.accessToken, pagination.page - 1, keyword, location, status);
  };

  const handleNextFetch = async () => {
    await fetchUsers(userState.accessToken, pagination.page + 1, keyword, location, status);
  };

  useEffect(() => {
    fetchUsers(userState.accessToken, 0, "", "", "active");
  }, []);

  const handleDeletePrompt = (user) => {
    showPrompt("Reset Password", `Are you sure you want to reset "${user.firstName} ${user.lastName}" password? This action can't be undone.`, resetPasswordPromptRef, "open", setDisplayPromptMessage);
    setSelectedUser(user);
  };

  const handleResetPassword = async () => {
    const payload = {
      userId: selectedUser._id,
    };
    try {
      const response = await resetPassword(userState.accessToken, payload);
      if (response.status === "success") {
        notify("Password successfully reset", "info");
        resetPasswordPromptRef.current.closePrompt();
      } else if (response.status === "fail") {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    }
  };

  return (
    <>
      <div className="w-full flex justify-between">
        <input
          type="text"
          name="search"
          onInput={debouncedHandleSearch}
          placeholder="Search users"
          className="block border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 max-w-md sm:text-base"
        />

        <Select
          className="border-0 w-64"
          placeholder="Filter by location"
          isDisabled={false}
          isClearable={true}
          isLoading={false}
          isRtl={false}
          isSearchable={true}
          name="filter"
          options={LOCATIONS}
          onChange={(event) => handleLocationFilter(event ? event.value : "")}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "#ecf8ef",
              primary: "#56c271",
            },
          })}
        />

        <Select
          className="border-0 w-64"
          placeholder="Filter by status"
          isDisabled={false}
          isClearable={true}
          isLoading={false}
          isRtl={false}
          isSearchable={true}
          name="status"
          options={[
            { value: "active", label: "Active" },
            { value: "inactive", label: "Inactive" },
          ]}
          onChange={(event) => handleStatusFilter(event ? event.value : "")}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "#ecf8ef",
              primary: "#56c271",
            },
          })}
        />
      </div>
      <Table
        isLoading={isUsersLoading}
        columns={[
          {
            name: "First Name",
            value: "firstName",
          },
          {
            name: "Last Name",
            value: "lastName",
          },
          {
            name: "Email address",
            value: "emailAddress",
          },
          {
            name: "Plan",
            value: "subscription",
          },
          {
            name: "Location",
            value: "location",
          },
          {
            name: "Status",
            value: "status",
          },
          {
            name: "Action",
            value: (user) => <ButtonDanger text="Reset password" action={() => handleDeletePrompt(user)} />,
            type: "render",
          },
        ]}
        data={users}
      />
      <Prompt header={displayPromptMessage.heading} body={displayPromptMessage.body} ref={resetPasswordPromptRef} action={handleResetPassword} />
      <Pagination pagination={pagination} next={handleNextFetch} prev={handlePrevFetch} />
    </>
  );
}

export default CommunityManagerOfficernd;
