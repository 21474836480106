/* eslint-disable capitalized-comments */
import { Outlet } from "react-router-dom";
import Title from "../../components/title/Title";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import LinkTab from "../../components/link_tab/LinkTab";


function TechnicalSupport() {
  return (
    <>
     <Title name="Technical Support" />
      <Breadcrumb routes={["Dashboard", "Technical Support"]} />
      <LinkTab
        items={[
          {
            link: "/dashboard/technical-support",
            title: "Support",
          },


          // {
          //   link: "/dashboard/technical-support/groups",
          //   title: "Groups",
          // },


          // {
          //   link: "/dashboard/technical-support/property",
          //   title: "Vacation Home",
          // },
          {
            link: "/dashboard/technical-support/workshop",
            title: "Workshop",
          },
          {
            link: "/dashboard/technical-support/featured_workshop",
            title: "Featured Workshop",
          },
          {
            link: "/dashboard/technical-support/coupon",
            title: "Coupons",
          },
          {
            link: "/dashboard/technical-support/reset_password",
            title: "Reset Password",
          },
          {
            link: "/dashboard/technical-support/email_reminder",
            title: "Email Reminders",
          },
        ]}
      />

      <div className="mt-3">
        <Outlet />
      </div>
    </>
  );
}

export default TechnicalSupport;
