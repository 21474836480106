/* eslint-disable */
import config from "../../../config";
import makeAPIRequest from "../../../services/makeRequest";

const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/admin/scholarshipapp`;

async function getApplications(accessToken) {
	const response = await makeAPIRequest(API_ENDPOINT, "GET", null, accessToken);
	const jsonResponse = await response.json();
	return jsonResponse;
}
export default getApplications;
