/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { sortBy } from "lodash";
import React from "react";
import SelectInput from "../../components/select_input/SelectInput";
import config from "../../config";
import notify from "../../services/toast";
import { REMINDERS, REMINDERS_DUE_DATES } from "../../constant";
import { generateClassName } from "../../services/util";
import getReminder from "./getReminder";
import useUserContext from "../../hooks/useUserContext";
import sendReminder from "./sendReminder";
import { addDays, format } from "date-fns";

function buildDescription(reminder) {
  switch (reminder) {
    case "subscription":
      return "The total number of users with accounts at least 10 days old but have no subscription.";
    case "email_verification":
      return "Total number of user with accounts who have not verified their email and its been at least 10 days";
    case "create_listing":
      return "Total number of users with paid plans but have not created any business listings and its been at least 10 days old";
    case "profile":
      return "Total number of users with active accounts but still haven't completed their profile information yet";
    case "basic_plan":
      return "Total number of users who have been on a basic plan for at least 30 days.";
    default:
      return "";
  }
}

function EmailReminders() {
  const { userState } = useUserContext();
  const [reminder, setReminder] = React.useState(REMINDERS[0]);
  const [emailAddress, setEmailAddress] = React.useState(config["SUPPORT_EMAIL"]);
  const [reminderResults, setReminderResults] = React.useState(null);
  const [gettingReminders, setGettingReminders] = React.useState(false);
  const [sendingReminders, setSendingReminders] = React.useState(false);

  const handleGetReminders = async reminder => {
    try {
      setGettingReminders(true);
      const response = await getReminder(reminder, userState.accessToken);
      if (response.status === "success") {
        setReminderResults(response.data);
      } else {
        notify("An error occurred while getting reminder.", "error");
        setReminderResults(null);
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setGettingReminders(false);
    }
  };

  const handleSendReminder = async reminder => {
    try {
      setSendingReminders(true);
      const response = await sendReminder(reminder, { reminder }, userState.accessToken);
      if (response.status === "success") {
        notify("Successfully sent reminder", "success");
      } else {
        notify("An error occurred while sending reminder.", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setSendingReminders(false);
    }
  };

  React.useEffect(() => {
    const { value } = reminder;
    handleGetReminders(value);
  }, [reminder]);
  return (
    <div className="mx-auto max-w-5xl p-4 sm:px-6 lg:px-8 bg-white rounded-md shadow-md">
      <h1 className="text-xl text-success-800 text-left">Reminders</h1>
      <div className="mt-5 w-full">
        <label htmlFor="reminder" className="block text-sm font-medium text-gray-700">
          Select a Reminder
        </label>
        <div className="mt-1">
          <SelectInput
            options={sortBy(REMINDERS, item => item.value)}
            noOptionsMessage="No reminders found"
            placeholder="Select a reminder"
            defaultValue={reminder}
            isDisabled={false}
            setSelectedValue={event => setReminder(event)}
          />
        </div>
      </div>

      <div className="ml-4 mt-4">
        <div className="w-full flex justify-start gap-2">
          <span className="material-icons-outlined">group</span>
          <h3 className="text-lg font-medium leading-6 text-gray-900">Total number of users</h3>
          {gettingReminders ? (
            <span className="material-icons-outlined text-primary-400">pending</span>
          ) : (
            <h3 className="text-lg font-extrabold leading-6 text-gray-900">{reminderResults ? reminderResults.data.length : 0} users</h3>
          )}
        </div>
        <p className="mt-1 text-sm text-gray-500">{buildDescription(reminder.value)}</p>
      </div>

      <div className="w-full flex flex-col space-y-3 ml-4 mt-4">
        <div className="w-full flex justify-start gap-2">
          <span className="material-icons-outlined">calendar_month</span>
          <h3 className="text-lg font-medium leading-6 text-gray-900">Last sent</h3>
          <h3 className="text-lg font-extrabold leading-6 text-gray-900">{reminderResults && reminderResults.stat ? format(new Date(reminderResults.stat.createdAt), "iii, MM do, hh:mm a") : "__"}</h3>
        </div>

        <div className="w-full flex justify-start gap-2">
          <span className="material-icons-outlined">email</span>
          <h3 className="text-lg font-medium leading-6 text-gray-900">Total reminders sent</h3>
          <h3 className="text-lg font-extrabold leading-6 text-gray-900">{reminderResults && reminderResults.stat ? reminderResults.stat.totalReminders : "__"}</h3>
        </div>

        <div className="w-full flex justify-start gap-2">
          <span className="material-icons-outlined">schedule_send</span>
          <h3 className="text-lg font-medium leading-6 text-gray-900">Next reminder</h3>

          <h3 className="text-lg font-extrabold leading-6 text-gray-900">
            {reminderResults && reminderResults.stat ? format(addDays(new Date(reminderResults.stat.createdAt), REMINDERS_DUE_DATES[reminder.value]), "iii, MM do, hh:mm a") : "__"}
          </h3>
        </div>
      </div>
      <form className="mt-5 space-y-6">
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Support email
          </label>
          <div className="mt-1">
            <input onChange={event => setEmailAddress(event.target.value)} value={emailAddress} id="email" disabled name="email" type="email" autoComplete="off" className={generateClassName(false)} />
          </div>
        </div>
        <div>
          <button
            onClick={() => handleSendReminder(reminder.value)}
            type="submit"
            disabled={sendingReminders ? true : false}
            className="flex w-full justify-center rounded-md border border-transparent bg-primary-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
            {sendingReminders ? "Please wait..." : "Send reminder"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default EmailReminders;
