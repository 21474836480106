/* eslint-disable */
import React, { useState } from "react";
import useUserContext from "../../hooks/useUserContext";
import submitCommunityPartner from "./submitCommunityPartner";
import notify from "../../services/toast";
import SelectInput from "../../components/select_input/SelectInput";
import { COHATCH_MARKETS, APP_LOCATIONS, MAGIC_NUMBERS } from "../../constant";

function CommunityPartnerForm() {
    const { userState } = useUserContext();
    const [orgName, setOrgName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
	const [orgMission, setOrgMission] = useState("");
    const [orgAmount, setOrgAmount] = useState("");
    const [spaceAmount, setSpaceAmount] = useState("");
    const [ourAsks, setOurAsks] = useState("");
    const [extraConditions, setExtraConditions] = useState("");
    const [termLength, setTermLength] = useState("");
    const [coLocation, setCoLocation] = useState("");
    const [market, setMarket] = useState("");
    const [cpCategory, setCpCategory] = useState("");
    const [submitting, setSubmitting] = useState(false);

    const onSubmit = async (event) => {
        event.preventDefault();
        setSubmitting(true);

        try {
            const payload = {
                firstName,
                lastName,
                orgName,
                email,
                phoneNumber,
				orgMission,
                orgAmount,
                spaceAmount,
                ourAsks,
                extraConditions,
                termLength,
                market,
                coLocation,
                cpCategory,
            };

            const response = await submitCommunityPartner(payload, userState.accessToken);

            if (response.status === "success") {
                notify("Submission successful!", "success");
            } else if (response.status === "fail") {
                let displayMessage = "";
                if (Array.isArray(response.message)) {
                    displayMessage = response.message[0].message;
                } else {
                    displayMessage = response.message;
                    if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
                        displayMessage = "An error has occurred. Please try again later. If this error persists please contact support";
                    }
                }
                notify(displayMessage, "error");
            }
        } catch (error) {
            notify("Unable to send Invite. Please try again later.");
        } finally {
            setSubmitting(false);
        }
    };

    const communityPartnerCategories = [
        { value: "local_city_leaders", label: "Local City Leaders" },
        { value: "chamber_of_commerce", label: "Chamber of Commerce" },
        { value: "university_partner", label: "University Partner" },
        { value: "local_influencer", label: "Local Influencer" },
        { value: "networking_groups", label: "Networking Groups" },
        { value: "events_and_advertising", label: "Events & Advertising" },
        { value: "main_street_business", label: "" },
    ];

    const handleMarketChange = (event) => {
        if (event) {
            const { value } = event;
            setMarket(value);
            setCoLocation(null);
        } else {
            setMarket(null);
            setCoLocation(null);
        }
    };

    const generateCohatchMarket = () => {
        const cohatchMarket = [];

        for (const market in COHATCH_MARKETS) {
            cohatchMarket.push(COHATCH_MARKETS[market]);
        }

        return cohatchMarket.flat();
    };

    const generateCohatchLocation = () => {
        if (market) {
            const cohatchLocation = [];
            cohatchLocation.push(APP_LOCATIONS[market]["locations"]);
            return cohatchLocation.flat();
        } else {
            return [];
        }
    };

    const handleLocationsChange = (event) => {
        if (event) {
            const { label } = event;
            setCoLocation(label);
        } else {
            setCoLocation(null);
        }
    };
	return (
		<div className="py-8 px-4 sm:px-6 lg:col-span-3 lg:py-8 lg:px-8 xl:pl-8">
			<form
				onSubmit={onSubmit}
				noValidate
				autoComplete="off"
				className="container"
			>
				<div className="max-w-lg mx-auto lg:max-w-none">
					<div className="w-full flex gap-3 mb-6">
						<div className="w-full">
							<label className="font-bold" htmlFor="orgName">
								Organization Name
							</label>
							<input
								type="text"
								name="orgName"
								id="orgName"
								className="mt-3 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
								label="Organization Name"
								placeholder="Enter Organization Name"
								onChange={(e) => {
									setOrgName(e.target.value);
								}}
							/>
						</div>
					</div>

					<div className="mb-6 w-full flex gap-3">
						<div className=" w-full">
							<label className="font-bold" htmlFor="firstName">
								{" "}
								Community Partner First Name:
							</label>
							<input
								className="mt-3 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
								label="First Name"
								name="firstName"
								type="input"
								id="firstName"
								placeholder="First Name"
								onChange={(e) => {
									setFirstName(e.target.value);
								}}
							/>
						</div>
						<div className="w-full">
							<label className="font-bold" htmlFor="">
								Community Partner Last Name:
							</label>
							<input
								type="text"
								name="lastName"
								id="lastName"
								className="mt-3 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
								label="Last Name"
								placeholder="Last Name"
								onChange={(e) => {
									setLastName(e.target.value);
								}}
							/>
						</div>
					</div>
					<div className="w-full flex gap-3 mb-6">
						<div className="w-full">
							<label className="font-bold" htmlFor="email">
								Community Partner Email:
							</label>
							<input
								type="text"
								name="email"
								id="email"
								className="mt-3 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md bg-gray"
								placeholder="Enter Email"
								onChange={(e) => {
									setEmail(e.target.value);
								}}
							/>
						</div>
					</div>
					<div className="w-full flex gap-3 mb-6">
						<div className="w-full">
							<label className="font-bold" htmlFor="phoneNumber">
								Community Partner Phone Number:
							</label>
							<input
								type="text"
								name="phoneNumber"
								id="phoneNumber"
								className="mt-3 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
								placeholder="Enter Phone Number"
								onChange={(e) => {
									setPhoneNumber(e.target.value);
								}}
							/>
						</div>
					</div>

					<div className="w-full flex gap-3 mb-6">
						<div className="w-full space-y-3 ">
							<label className="font-bold" htmlFor="phoneNumber">
								What category does this organization fall under?
							</label>
							<SelectInput
								name="causes"
								id="causes"
								placeholder="Choose Category"
								options={communityPartnerCategories}
								setSelectedValue={(e) => {
									setCpCategory(e.label);
								}}
							/>
						</div>
					</div>

					<div className="w-full flex gap-3 mb-6">
						<div className="w-full">
							<label className="font-bold" htmlFor="orgMission">
							Description of the organization and mission?:
							</label>
							<textarea
								type="textarea"
								name="orgMission"
								id="orgMission"
								className="mt-3 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
								rows="3"
								placeholder="Examples...Be active in our space, host X amount of events per month/quarter/year, tag us on social media, include in X newsletters, etc."
								onChange={(e) => {
									setOrgMission(e.target.value);
								}}
							></textarea>
						</div>
					</div>

					<div className="w-full flex gap-3 mb-6">
						<div className="w-full">
							<label className="font-bold" htmlFor="">
								How many coworkers will utilize COhatch? (optional):
							</label>
							<input
								type="number"
								name="email"
								id="email"
								className="mt-3 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
								placeholder="Enter Amount"
								onChange={(e) => {
									setOrgAmount(e.target.value);
								}}
							/>
						</div>
					</div>
					<div className="w-full flex gap-3 mb-6">
						<div className="w-full">
							<label className="font-bold" htmlFor="">
								Total dollars awarded in coworking and/or meeting & event space:
							</label>
							<input
								label="Number"
								type="number"
								name="email"
								id="email"
								className="mt-3 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
								placeholder="Enter Dollar Amount"
								onChange={(e) => {
									setSpaceAmount(e.target.value);
								}}
							/>
						</div>
					</div>
					<div className="w-full flex gap-3 mb-6">
						<div className="w-full">
							<label className="font-bold" htmlFor="ourAsks">
								Our asks of the Community Partner:
							</label>
							<textarea
								type="textarea"
								name="ourAsks"
								id="ourAsks"
								className="mt-3 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
								rows="3"
								placeholder="Examples...Be active in our space, host X amount of events per month/quarter/year, tag us on social media, include in X newsletters, etc."
								onChange={(e) => {
									setOurAsks(e.target.value);
								}}
							></textarea>
						</div>
					</div>
					<label className="font-bold" htmlFor="ourAsks">
						COhatch Location:
					</label>
					<div className="w-full mt-3 flex gap-3 mb-6">
						<div className="w-full">
							<SelectInput
								label="COhatch City:"
								name="market"
								id="market"
								options={generateCohatchMarket()}
								noOptionsMessage="No cities found"
								placeholder="Choose City"
								setSelectedValue={handleMarketChange}
							/>
						</div>

						<div className="w-full flex  gap-3">
							<div className="w-full ">
								<SelectInput
									id="coLocation"
									name="coLocation"
									options={generateCohatchLocation()}
									setSelectedValue={handleLocationsChange}
									placeholder="Choose Location"
								/>
							</div>
						</div>
					</div>

					{/* <div className="w-full flex gap-3 mb-6">
						<div className="w-full">
							<label className="font-bold" htmlFor="extraConditions">
								Anything Else Your Adding to this Community Partner Term?:
							</label>
							<textarea
								type="textarea"
								name="extraConditions"
								id="extraConditions"
								className="mt-3 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
								rows="3"
								placeholder="Examples...Be active in our space, host X amount of events per month/quarter/year, tag us on social media, include in X newsletters, etc."
								onChange={(e) => {
									setExtraConditions(e.target.value);
								}}
							></textarea>
						</div>
					</div> */}
					<div className="w-full flex gap-3 mb-6">
						{/* Business Info */}
						<div className="w-full">
							<label className="font-bold" htmlFor="termLength">
								Community Partnership Term (12 months is standard):
							</label>
							<input
								className="mt-3  appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
								label="Tell us about your business. Do you have a business plan you can share?"
								multiline={true}
								rows="3"
								type="number"
								name="termLength"
								placeholder="Enter Term in Months"
								id="termLength"
								onChange={(e) => {
									setTermLength(e.target.value);
								}}
							/>
						</div>
					</div>
					<div className=" mt-4 w-full flex justify-between items-center">
						<div>
							<button
								className="cursor-pointer inline-flex items-center justify-center gap-2 px-3 py-2 border border-transparent text-md font-medium rounded shadow-sm text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition ease-linear duration-500"
								type="submit"
							>
								Submit
							</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
}

export default CommunityPartnerForm;
