/* eslint-disable */
import React from "react";
import ButtonPrimary from "../../components/button/ButtonPrimary";
import { PhotoIcon } from "@heroicons/react/24/solid";
import createImpactStory from "./createImpactStory";
import notify from "../../services/toast";
import useUserContext from "../../hooks/useUserContext";
import { MAGIC_NUMBERS } from "../../constant";

function ImpactSurvey() {
	const { userState } = useUserContext();
	const [firstName, setFirstName] = React.useState("");
	const [lastName, setLastName] = React.useState("");
	const [email, setEmail] = React.useState("");
	const [orgName, setOrgName] = React.useState("");
	const [support, setSupport] = React.useState("");
	const [amountUsed, setAmountUsed] = React.useState("");
	const [howWeHelped, setHowWeHelped] = React.useState("");
	const [metrics, setMetrics] = React.useState("");
	const [feedback, setFeedback] = React.useState("");
	const [submitting, setSubmitting] = React.useState(false);

	//testing form data
	const testingFillForm = () => {
		setFirstName("Phil");
		setLastName("Conners");
		setEmail("phil@helping.com");
		setOrgName("Bend Union");
		setSupport("123");
		setAmountUsed("Cleveland");
		setHowWeHelped("Ohio");
		setMetrics("44113");
		setFeedback(
			"COhatch has helped my organization in a way I couldn't believe. Everytime I..."
		);
	};

	const handleSurveySubmit = async (event) => {
		event.preventDefault();
		try {
			setSubmitting(true);
			const payload = {
				firstName,
				lastName,
				email,
				orgName,
				prompts: {
					support: support,
					amountUsed: amountUsed,
					howWeHelped: howWeHelped,
					metrics: metrics,
					feedback: feedback,
				},
			};
			const response = await createImpactStory(payload, userState.accessToken);
			if (response.status === "success") {
				setSubmitting(false);
				setFirstName(""), setLastName(""), setEmail("");
				setOrgName("");
				setSupport("");
				setAmountUsed("");
				setHowWeHelped("");
				setMetrics("");
				setFeedback("");
				notify("Story submitted successfully");
			} else if (response.status === "fail") {
				setSubmitting(false);
				let displayMessage = "";
				if (Array.isArray(response.message)) {
					displayMessage = response.message[0].message;
				} else {
					displayMessage = response.message;
					if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
						displayMessage =
							"An error has occurred. Please try again later. If this error persists please contact support";
					}
				}
				notify(displayMessage, "error");
			}
		} catch (error) {
			setSubmitting(false);
			notify("Something went wrong. Please try again later");
		}
	};

	return (
		<>
			<div className="bg-white rounded-md mt-5 pt-5 pb-10 text-center">
				<h1 className="mb-5 text-orange-700 font-bebas text-3xl">Story Time</h1>
				<h1 className="mb-5 font-bold text-3xl">We'd love to hear from you!</h1>
				<p>
					Every year, we want to know how we helped you achieve your mission.
					take this quick survey and drop a story to move onto the next step of
					the scholarship process. For those renewing your scholarship, this is
					a required step in order to be renewed for the next scholarship year.
				</p>

				<div>
					<div className="mx-6 lg:grid lg:grid-cols-4">
						<div className="py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-8">
							<div className="text-left max-w-lg mx-auto lg:max-w-none">
								<div className="w-full grid grid-cols-2 text-left gap-4 gap-y-6">
									<div className="w-full flex ">
										<div className="w-full">
											<label
												htmlFor="firstName"
												className="block text-lg font-medium text-black-700"
											>
												First name
											</label>
											<p className="text-sm text-red-400 text-right">*</p>
											<input
												className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
												type="text"
												name="firstName"
												id="firstName"
												value={firstName}
												onChange={(event) => setFirstName(event.target.value)}
											/>
										</div>
									</div>

									<div className="w-full flex gap-3">
										<div className="w-full">
											<label
												htmlFor="lastName"
												className="block text-lg font-medium text-black-700"
											>
												Last name
											</label>
											<p className="text-sm text-red-400 text-right">*</p>
											<input
												className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
												type="text"
												name="lastName"
												id="lastName"
												value={lastName}
												onChange={(event) => setLastName(event.target.value)}
											/>
										</div>
									</div>

									<div className="w-full flex gap-3">
										<div className="w-full">
											<label
												htmlFor="email"
												className="block text-lg font-medium text-black-700"
											>
												Email
											</label>
											<p className="text-sm text-red-400 text-right">*</p>
											<input
												className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
												type="text"
												name="email"
												id="email"
												value={email}
												onChange={(event) => setEmail(event.target.value)}
											/>
										</div>
									</div>

									<div className="w-full flex gap-3">
										<div className="w-full">
											<label
												htmlFor="orgName"
												className="block text-lg font-medium text-black-700"
											>
												Your Organization Name
											</label>
											<p className="text-sm text-red-400 text-right">*</p>
											<input
												className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
												type="text"
												name="orgName"
												id="orgName"
												value={orgName}
												onChange={(event) => setOrgName(event.target.value)}
											/>
										</div>
									</div>
								</div>

								<div className="w-full flex gap-3 mt-5">
									<div className="w-full">
										<label
											htmlFor="support"
											className="block text-lg font-medium text-black-700"
										>
											How did you use COhatch this year to support your
											organization?
										</label>
										<p className="text-sm text-red-400 text-right">*</p>
										<input
											className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
											type="text"
											name="support"
											id="support"
											value={support}
											onChange={(event) => setSupport(event.target.value)}
										/>
									</div>
								</div>
								<div className="w-full flex gap-3">
									<div className="w-full">
										<label
											htmlFor="amountUsed"
											className="block text-lg font-medium text-black-700"
										>
											How many times did you use a COhatch location this year?
										</label>
										<p className="text-sm text-red-400 text-right">*</p>
										<input
											className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
											type="text"
											name="amountUsed"
											id="amountUsed"
											value={amountUsed}
											onChange={(event) => setAmountUsed(event.target.value)}
										/>
									</div>
								</div>
								<div className="w-full flex gap-3">
									<div className="w-full">
										<label
											htmlFor="metrics"
											className="block text-lg font-medium text-black-700"
										>
											Did COhatch help you create connections, receive funding,
											or any other related metrics this year?
										</label>
										<p className="text-sm text-red-400 text-right">*</p>
										<input
											className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
											type="text"
											name="metrics"
											id="metrics"
											value={metrics}
											onChange={(event) => setMetrics(event.target.value)}
										/>
									</div>
								</div>
								<div className="w-full flex gap-3">
									<div className="w-full">
										<label
											htmlFor="howWeHelped"
											className="block text-lg font-medium text-black-700"
										>
											Please add details related to how we helped with your
											network, funding/investment, etc. here at COhatch.
										</label>
										<p className="text-sm text-red-400 text-right">*</p>
										<textarea
											className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
											type="text"
											name="howWeHelped"
											id="howWeHelped"
											value={howWeHelped}
											onChange={(event) => setHowWeHelped(event.target.value)}
										/>
									</div>
								</div>
								<div className="col-span-full">
									<div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
										<div className="text-center">
											<PhotoIcon
												className="mx-auto h-6 w-12 text-gray-300"
												aria-hidden="true"
											/>
											<div className="mt-4 flex text-sm leading-6 text-gray-600">
												<label
													htmlFor="file-upload"
													className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
												>
													<span>Upload a file</span>
													<input
														id="file-upload"
														name="file-upload"
														type="file"
														className="sr-only"
													/>
												</label>
												<p className="pl-1">or drag and drop</p>
											</div>
											<p className="text-xs leading-5 text-gray-600">
												PNG, JPG, GIF up to 10MB
											</p>
										</div>
									</div>
								</div>

								<div className="w-full flex gap-3">
									<div className="w-full">
										<label
											htmlFor="feedback"
											className="block text-lg font-medium text-black-700"
										>
											What would you say about COhatch and this scholarship?
										</label>
										<p className="text-sm text-red-400 text-right">*</p>
										<textarea
											className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
											type="text"
											name="feedback"
											id="feedback"
											value={feedback}
											onChange={(event) => setFeedback(event.target.value)}
										/>
									</div>
								</div>

								<div className="mt-4 h-20 w-40">
									<ButtonPrimary
										text={"Submit"}
										size="md"
										action={handleSurveySubmit}
									/>
								</div>
								<div className=" h-20 w-40">
									<ButtonPrimary
										text={"test"}
										size="md"
										action={testingFillForm}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default ImpactSurvey;
