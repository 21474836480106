import config from "./../config";
import useUserContext from "../hooks/useUserContext";
import makeAPIRequest from "../services/makeRequest";
import { useNavigate } from "react-router-dom";
import notify from "./../services/toast";

function useLogout(setLoggingOut) {
  const { setUserState } = useUserContext();
  const navigate = useNavigate();
  const handleLogout = async (event) => {
    event.preventDefault();
    try {
      setLoggingOut(true);
      const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/admin/logout`;
      const response = await makeAPIRequest(API_ENDPOINT, "GET");
      if (response.ok) {
        setUserState({});
        navigate("/");
      } else {
        setLoggingOut(false);
        notify("Unable to log you out. Please try again. If this error persists contact support", "error");
      }
    } catch (error) {
      setLoggingOut(false);
      notify("Something went wrong. Please try again later", "error");
    }
  };

  return [handleLogout];
}

export default useLogout;
