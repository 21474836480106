/* eslint-disable id-blacklist */

import React from "react";
import PropTypes from "prop-types";

function Breadcrumb({
  routes,
}) {
  return (
    <div className="mt-2 text-gray-400 text-xs">
      {routes.map((route, index) => <span key={`route-${index}`} className="">{index !== 0 && <span className="mx-2">&gt;</span>}{route}</span>)}
    </div>
  );
}

Breadcrumb.propTypes = {
  routes: PropTypes.array,
};

export default Breadcrumb;
