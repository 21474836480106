import config from "../../../config";
import makeAPIRequest from "../../../services/makeRequest";

async function featureStory(accessToken, payload) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/admin/impactstory`;
  const response = await makeAPIRequest(API_ENDPOINT, "PUT", payload, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default featureStory;
