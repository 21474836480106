/*eslint-disable*/

import React from "react";
import { useNavigate } from "react-router-dom";

function StoryCard({ story, index }) {
	const navigate = useNavigate();
	function handleClick() {
		console.log(story.id);
		navigate(`${story.id}`, {
			state: {
				firstName: story.firstName,
				lastName: story.lastName,
			},
		});
	}

	return (
		<article
			key={index}
			className="flex shadow-xl rounded bg-white p-4 flex-col items-start justify-between"
		>
			<button type="button" onClick={handleClick}>
				{" "}
				<div className="relative w-full">
					<img
						src="https://www.springfieldnewssun.com/resizer/xMKPpmeJtOcM6HfVD-repNtSpU0=/arc-anglerfish-arc2-prod-coxohio/public/CL5GKI47BMOUEOPGAWCVJ4HCHE.jpg"
						alt=""
						className="aspect-[16/9] w-full rounded bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
					/>
					<div className="absolute inset-0 rounded ring-1 ring-inset ring-gray-900/10" />
				</div>
				<div className="max-w-xl">
					<div className="mt-8 flex items-center gap-x-4 text-xs">
						<time dateTime={story.lastName} className="text-gray-500">
							{story.email}
						</time>
						<div className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100">
							{story.orgName}
						</div>
					</div>
					<div className="group relative">
						<h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
							<span className="absolute inset-0" />
							{story.orgName}
						</h3>
						<p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">
							{story.prompts.feedback}
						</p>
					</div>
					<div className="relative mt-8 flex items-center gap-x-4">
						<img
							src="https://media.istockphoto.com/id/1386479313/photo/happy-millennial-afro-american-business-woman-posing-isolated-on-white.jpg?b=1&s=170667a&w=0&k=20&c=ahypUC_KTc95VOsBkzLFZiCQ0VJwewfrSV43BOrLETM="
							alt=""
							className="h-10 w-10 rounded-full bg-gray-100"
						/>
						<div className="text-sm leading-6">
							<p className="font-semibold text-gray-900">
								<span className="absolute inset-0" />
								{story.email}
							</p>
							<p className="text-gray-600"> {story.email}</p>
						</div>
					</div>
				</div>
			</button>
		</article>
	);
}

export default StoryCard;
