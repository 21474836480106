import config from "../../config";
import makeAPIRequest from "../../services/makeRequest";

const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/admin/contact`;

async function updateContactMessage(id, body, accessToken) {
  const response = await makeAPIRequest(`${API_ENDPOINT}/${id}`, "PUT", body, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default updateContactMessage;
