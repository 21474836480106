import config from "../../config";
import makeAPIRequest from "../../services/makeRequest";

const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/admin/group`;

async function updateGroup(id, body, accessToken) {
  const response = await makeAPIRequest(`${API_ENDPOINT}/${id}`, "PUT", {
    update: body
  }, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default updateGroup;
