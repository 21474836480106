/* eslint-disable no-console */
import makeAPIRequest from "../../../services/makeRequest";
import config from "../../../config";

async function getAppsById(accessToken, userId) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/admin/scholarshipapp/appsByUser/${userId}`;
  const response = await makeAPIRequest(API_ENDPOINT, "GET", null, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default getAppsById;