/* eslint-disable id-blacklist */

import React from "react";
import PropTypes from "prop-types";

function Title({
  name,
}) {
  return (
    <h1 className="text-success-800 text-2xl font-extrabold">{name}</h1>
  );
}

Title.propTypes = {
  name: PropTypes.string,
};

export default Title;
