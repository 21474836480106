/* eslint-disable */
import React from "react";
import updateNotification from "../components/notification_type/updateNotification";
import useNotificationContext from "./useNotificationContext";

function useNotificationUpdate(accessToken, notificationId, payload, read, setRead) {
  const { setUnreadNotifications } = useNotificationContext();
  const handleNotificationUpdate = async () => {
    try {
      const response = await updateNotification(accessToken, notificationId, payload);
      if (response.status === "success") {
        const { read } = response.data;
        setRead(read);
        setUnreadNotifications((prevState) => prevState - 1);
      } else {
       // console.error("An error occurred while updating notification");
      }
    } catch (error) {
     // console.error(error);
    }
  };

  React.useEffect(() => {
    if (!read) {
      const timer = setTimeout(() => {
        handleNotificationUpdate();
        clearTimeout(timer);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, []);
}

export default useNotificationUpdate;
