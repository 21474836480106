/*eslint-disable*/
import React, {useEffect} from "react";
import { PaperClipIcon} from "@heroicons/react/20/solid";
import { useLocation, useParams } from "react-router-dom";
import { useState } from "react";
import html2canvas from "html2canvas";
import JsPDF from "jspdf";
import EditApplicationModal from "./EditApplicantModal";
import useUserContext from "../../hooks/useUserContext";
import updateApplication from "./updateApplication";
import notify from "../../services/toast";
import { MAGIC_NUMBERS } from "../../constant";
import getAppById from "./requests/getAppById";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/spinner/Spinner";




function ApplicationFile() {

	const location = useLocation();
  const {userState} = useUserContext;
	//const app = location.state.appInfo;
  const [loader, setLoader] = useState(false);
  const [showApplicantModal, setShowApplicantModal] = useState(false);
  const navigate = useNavigate();
    const {
    userState: { accessToken },
  } = useUserContext();
  //const [submitting, setSubmitting] = useState(false);
  const params = useParams();
  //const { applicantId } = params;
  const [fetching, setFetching] = React.useState(false);
  const [application, setApplication] = React.useState("");
  const [submitting, setSubmitting] = useState(false);
  const downloadPDF = () => {
  const capture = document.querySelector(".full-application");
  setLoader(true);
  html2canvas(capture).then((canvas) => {
    const imgData = canvas.toDataURL("img/png");
    const doc = new JsPDF("p", "mm", "a4");
    const componentWidth = doc.internal.pageSize.getWidth();
    const componentHeight = doc.internal.pageSize.getHeight();
    doc.addImage(imgData, "PNG", 0, 0, componentWidth, componentHeight);
    setLoader(false);
    doc.save(`${application.orgName}-501c3.pdf`);
  });
  };

  const applicationDocument = application.idNumber?.idFile;

   const fetchApplication = async () => {
    try {
      const response = await getAppById(accessToken, params.applicationId);
      if (response.status === "success") {
        const { data } = response;
        setApplication(data);
      } else {
 
      }
    } catch (error) {
    }
  };

    useEffect(() => {
    fetchApplication();
   
  }, []);

	return (

		<div className="m-5 ">
        
			<div className="mt-6 border-t border-gray-100">
       {fetching ? (<Spinner displayText="Fetching..." />) : (
        <>
        <div>
            <img
            src={applicationDocument?.large?.url ? applicationDocument.large.url : ""}
            alt=""
            className=""
        />
        </div>
          <dl className="divide-y divide-gray-100">
					<div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
						<dt className="text-sm font-medium leading-6 text-gray-900">
							Attachments
						</dt>
						<dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
							<ul
								role="list"
								className="divide-y divide-gray-100 rounded-md border border-gray-200"
							>
								<li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
									<div className="flex w-0 flex-1 items-center">
										<PaperClipIcon
											className="h-5 w-5 flex-shrink-0 text-gray-400"
											aria-hidden="true"
										/>
										<div className="ml-4 flex min-w-0 flex-1 gap-2">
											<span className="truncate font-medium">
												{application.orgName}_501c3.pdf
											</span>
											<span className="flex-shrink-0 text-gray-400">4.5mb</span>
										</div>
									</div>
              
									<div className="float-right">
										<div className="">
											<button className="w-full bg-success-800 text-white text-center rounded shadow-sm px-2.5 py-2 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800 transition ease-linear duration-500"
                      onClick={downloadPDF}
                      disabled={!(loader===false)}>
												{loader ? ( <span>Downloading</span>) : (<span>Download</span>)}
											</button>
										</div>
									</div>
								</li>
							</ul>
						</dd>
					</div>
				</dl>
        </>
       )}
			</div>
      </div>
    
   
    
	);
}

export default ApplicationFile;