/* eslint-disable no-console */
import React from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import useUserContext from "../../hooks/useUserContext";
import { BLOCKED_PAGES } from "../../constant";

function AuthUser() {
  const { userState } = useUserContext();
  const location = useLocation();

  if (!userState?.accessToken) {
    return <Navigate to="/" state={{ from: location }} replace />;
  } else {
    const authorized = userState.role.find(role => !(BLOCKED_PAGES[role].find(path => location.pathname.indexOf(path) > -1)));
    if (!authorized) {
      return <Navigate to="/invalid" state={{ from: location }} replace />;
    }
    return <Outlet />;
  }
}

export default AuthUser;
