/* eslint-disable capitalized-comments */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable prefer-template */

export const debounce = (callback, delay) => {
	let timerId;
	return (...args) => {
		if (timerId) {
			clearTimeout(timerId);
		}
		timerId = setTimeout(() => {
			callback(...args);
		}, delay);
	};
};
export function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export const formatStripeMoney = (amount) => {
	const amountAsString = amount.toString();
	let amountValue = "0";
	if (amountAsString.length > 1) {
		amountValue = amountAsString.substring(0, amountAsString.length - 2);
	}

	return `${amountValue}.00`;
};

export const showMessage = (
	header,
	body,
	type,
	notificationRef,
	mode,
	setErrorMessage
) => {
	setErrorMessage({ heading: header, body: body, type: type });
	if (mode === "open") {
		notificationRef.current.showNotification();
	} else if (mode === "close") {
		notificationRef.current.hideNotification();
	}
};

export const showPrompt = (
	header,
	body,
	promptRef,
	mode,
	setErrorMessage,
	id
) => {
	setErrorMessage({ heading: header, body: body });
	if (mode === "open") {
		promptRef.current.showPrompt();
	} else if (mode === "close") {
		promptRef.current.closePrompt();
	}
};

export const greetings = () => {
	const myDate = new Date();
	const hours = myDate.getHours();

	let greetings = "Hi";

	if (hours < 12) {
		greetings = "Good Morning";
	} else if (hours >= 12 && hours <= 17) {
		greetings = "Good Afternoon";
	} else if (hours >= 17 && hours <= 24) {
		greetings = "Good Evening";
	}

	return greetings;
};

export const generateClassName = (valid) => {
	if (!valid) {
		return "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md";
	} else {
		return "appearance-none block w-full px-3 py-2 border border-red-300 rounded-md shadow-sm placeholder-red-400 focus:outline-none focus:ring-red-400 focus:border-red-400 sm:text-md";
	}
};

export const timeFormatter = (time) => {
	const hour = Number(time.slice(0, time.indexOf(":")));
	const minutes = time.slice(time.indexOf(":"));

	if (hour === 12) {
		return hour + minutes + " PM";
	} else if (hour === 0) {
		return hour + 12 + minutes + " AM";
	}

	if (hour > 12) {
		return hour - 12 + minutes + " PM";
	} else {
		return hour + minutes + " AM";
	}
};

export const inArray = (array, item) => {
	for (const value of array) {
		if (
			value.toString().trim().toLowerCase() ===
			item.toString().trim().toLowerCase()
		) {
			return true;
		}
	}
	return false;
};

export function findInputError(errors, name) {
	const filtered = Object.keys(errors)
		.filter((key) => key.includes(name))
		.reduce((cur, key) => {
			return Object.assign(cur, { error: errors[key] });
		}, {});
	return filtered;
}

export const isFormInvalid = (err) => {
	if (Object.keys(err).length > 0) {
		return true;
	} else {
		return false;
	}
};
