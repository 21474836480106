import config from "../../config";
import makeAPIRequest from "../../services/makeRequest";

const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/admin/dashboard`;

async function getStats(accessToken) {
  const url = API_ENDPOINT;
  const response = await makeAPIRequest(url, "GET", null, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default getStats;
