/* eslint-disable id-blacklist */
/* eslint-disable no-console */

import React, { useState, useEffect } from "react";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Select from "react-select";
import PageTitle from "../../components/title/Title";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import Stats from "../../components/stats/Stats";
import Spinner from "./../../components/spinner/Spinner";
import useUserContext from "../../hooks/useUserContext";
import { greetings } from "../../services/util";
import { MAGIC_NUMBERS, BUSINESS_CATEGORY } from "../../constant";
import notify from "../../services/toast";
import getStats from "./getStats";
import getMarketData from "./getMarketData";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

const membersOptions = {
	indexAxis: "y",
	elements: {
		bar: {
			borderWidth: 2,
		},
	},
	responsive: true,
	plugins: {
		legend: {
			position: "right",
		},
		title: {
			display: true,
			text: "Members By Market",
		},
	},
};

const listingCategoryOptions = {
	indexAxis: "y",
	elements: {
		bar: {
			borderWidth: 2,
		},
	},
	responsive: true,
	plugins: {
		legend: null,
		title: {
			display: true,
			text: "Total Listings By Market By Category",
		},
	},
};

const listingByMarketOptions = {
	indexAxis: "y",
	elements: {
		bar: {
			borderWidth: 2,
		},
	},
	responsive: true,
	plugins: {
		legend: null,
		title: {
			display: true,
			text: "Total Listings By Market",
		},
	},
};

const couponOptions = {
	indexAxis: "y",
	elements: {
		bar: {
			borderWidth: 2,
		},
	},
	responsive: true,
	plugins: {
		legend: null,
		title: {
			display: true,
			text: "Total Coupons By Market",
		},
	},
};

const markets = [
	"Cincinnati",
	"Cleveland",
	"Columbus",
	"Indianapolis",
	"Tampa",
	"Pittsburgh",
];

function DashboardHome() {
	const { userState } = useUserContext();
	const { firstName, lastName, photo } = userState;
	const [isStatsLoading, setIsStatsLoading] = useState(true);
	const [isMarketDataLoading, setIsMarketDataLoading] = useState(true);
	const [isListingCategoryLoading, setIsListingCategoryLoading] =
		useState(false);
	const [stats, setStats] = useState({});
	const [marketData, setMarketData] = useState({});

	const membersData = {
		labels: markets,
		datasets: [
			{
				label: "Physical Members",
				data: markets.map((market) => {
					const item = (marketData.physicalMembers || []).find(
						(mem) => mem._id === market.toLowerCase()
					);
					return item ? item.total : 0;
				}),
				borderColor: "rgb(255, 99, 132)",
				backgroundColor: "rgba(255, 99, 132, 0.5)",
			},
			{
				label: "COhatch+ Members",
				data: markets.map((market) => {
					const item = (marketData.plusMembers || []).find(
						(mem) => mem._id === market.toLowerCase()
					);
					return item ? item.total : 0;
				}),
				borderColor: "rgb(53, 162, 235)",
				backgroundColor: "rgba(53, 162, 235, 0.5)",
			},
		],
	};

	const listingByCategoryData = {
		labels: BUSINESS_CATEGORY.map((category) => category.label),
		datasets: [
			{
				label: "Listing By Market",
				data: BUSINESS_CATEGORY.map((category) => {
					const item = (marketData.listingsByCategory || []).find(
						(mem) => mem._id === category.value
					);
					return item ? item.total : 0;
				}),
				borderColor: "rgb(53, 162, 235)",
				backgroundColor: "rgba(53, 162, 235, 0.5)",
			},
		],
	};

	const listingByMarketData = {
		labels: markets,
		datasets: [
			{
				label: "Total Listings by Market",
				data: markets.map((market) => {
					const item = (marketData.listings || []).find(
						(mem) => mem._id === market.toLowerCase()
					);
					return item ? item.total : 0;
				}),
				borderColor: "rgb(53, 162, 235)",
				backgroundColor: "rgba(53, 162, 235, 0.5)",
			},
		],
	};

	const couponsByMarketData = {
		labels: markets,
		datasets: [
			{
				label: "Total Coupons by Market",
				data: markets.map((market) => {
					const item = (marketData.listings || []).find(
						(mem) => mem._id === market.toLowerCase()
					);
					return item ? item.totalCoupons : 0;
				}),
				borderColor: "rgb(53, 162, 235)",
				backgroundColor: "rgba(53, 162, 235, 0.5)",
			},
		],
	};

	const fetchStats = async () => {
		const response = await getStats(userState.accessToken);
		if (response.status === "success") {
			setStats(response.data);
		} else if (response.status === "fail") {
			let displayMessage = "";
			if (Array.isArray(response.message)) {
				displayMessage = response.message[0].message;
			} else {
				displayMessage = response.message;
				if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
					displayMessage =
						"An error has occurred. Please try again later. If this error persists please contact support";
				}
			}
			notify(displayMessage, "error");
		}
		setIsStatsLoading(false);
	};

	const fetchMarketData = async (query) => {
		const response = await getMarketData(query, userState.accessToken);
		if (response.status === "success") {
			setMarketData(response.data);
			console.log(response.data);
		} else if (response.status === "fail") {
			let displayMessage = "";
			if (Array.isArray(response.message)) {
				displayMessage = response.message[0].message;
			} else {
				displayMessage = response.message;
				if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
					displayMessage =
						"An error has occurred. Please try again later. If this error persists please contact support";
				}
			}
			notify(displayMessage, "error");
		}
		setIsMarketDataLoading(false);
		setIsListingCategoryLoading(false);
	};

	const handleChangeMarket = (value) => {
		setIsListingCategoryLoading(true);
		fetchMarketData({
			markets: value ? value.value : "",
		});
	};

	useEffect(() => {
		fetchStats();
		fetchMarketData({});
	}, []);

	console.log(marketData);

	return (
		<>
			<PageTitle name="Dashboard" />
			<Breadcrumb routes={["Dashboard", "Home"]} />
			<div className="flex items-center mt-5">
				<img
					src={
						photo?.small?.url
							? photo.small.url
							: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_profile.png"
					}
					alt={photo?.small?.filename ? photo.small.filename : "Profile Photo"}
					className="inline-block rounded-full border border-3 object-cover h-[48px] w-[48px]"
				/>
				<h1 className="ml-3 text-xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate capitalize">
					{greetings()}, {firstName} {lastName}
				</h1>
			</div>
			<div className="mx-auto max-w-7xl py-4 px-4 sm:py-8 sm:px-6 lg:px-8 lg:py-8">
				{!isStatsLoading ? (
					<Stats stats={stats} />
				) : (
					<Spinner displayText="Loading..." />
				)}
			</div>
			<h1 className="my-5 text-xl font-bold leading-7 text-gray-900 text-center">
				Market Level Data
			</h1>
			{!isMarketDataLoading ? (
				<div>
					<Bar options={membersOptions} data={membersData} />
					<div className="flex mt-12">
						<div className="w-full">
							<Bar options={couponOptions} data={couponsByMarketData} />
						</div>
						<div className="w-full">
							<Bar
								options={listingByMarketOptions}
								data={listingByMarketData}
							/>
						</div>
					</div>
					<div className="w-full mt-12 flex flex-col sm:flex-row justify-between">
						<Select
							className="border-0 mt-2 w-1/2 sm:mt-0"
							placeholder="Select market"
							isDisabled={false}
							isClearable={true}
							isLoading={false}
							isRtl={false}
							isSearchable={true}
							name="filter"
							options={markets.map((market) => ({
								value: market.toLowerCase(),
								label: market,
							}))}
							onChange={handleChangeMarket}
							theme={(theme) => ({
								...theme,
								colors: {
									...theme.colors,
									primary25: "#ecf8ef",
									primary: "#56c271",
								},
							})}
						/>
					</div>
					{!isListingCategoryLoading ? (
						<div className="flex mt-4 mb-8">
							<div className="w-full">
								<Bar
									options={listingCategoryOptions}
									data={listingByCategoryData}
								/>
							</div>
						</div>
					) : (
						<Spinner displayText="Loading..." />
					)}
				</div>
			) : (
				<Spinner displayText="Loading..." />
			)}
		</>
	);
}

export default DashboardHome;
