/* eslint-disable no-console */
/* eslint-disable capitalized-comments */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useCallback } from "react";
import Table from "../../components/table/Table";
import Pagination from "../../components/pagination/Pagination";
import useUserContext from "../../hooks/useUserContext";
import { MAGIC_NUMBERS, PLANS } from "../../constant";
import notify from "../../services/toast";
import getReferredUser from "./getReferredUser";
import DefaultState from "./../../components/default_state/DefaultState";

const cleanData = rawData => {
  const parsedData = [];

  if (Array.isArray(rawData)) {
    for (const data of rawData) {
      parsedData.push({ ...data["subscription"], plan: PLANS[data["product"]] });
    }
  }
  return parsedData;
};

function CommunityManagersReferrals() {
  const { userState } = useUserContext();
  const [users, setUsers] = useState([]);
  const [isUsersLoading, setIsUsersLoading] = useState(true);
  const [pagination, setPagination] = React.useState({ limit: 10, total: 0, page: 0, remaining: 0 });

  const fetchUsers = async (location, accessToken, page, _keyword) => {
    setIsUsersLoading(true);
    const response = await getReferredUser(location, accessToken, page, _keyword);
    if (response.status === "success") {
      const { limit, page, remaining, total, results } = response.data;
      setPagination({ limit, page: page, remaining, total });
      const formatted = cleanData(results);
      setUsers(formatted);
    } else if (response.status === "fail") {
      let displayMessage = "";
      if (Array.isArray(response.message)) {
        displayMessage = response.message[0].message;
      } else {
        displayMessage = response.message;
        if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
          displayMessage = "An error has occurred. Please try again later. If this error persists please contact support";
        }
      }
      notify(displayMessage, "error");
    }
    setIsUsersLoading(false);
  };

  const handlePrevFetch = async () => {
    await fetchUsers(userState.location, userState.accessToken, pagination.page - 1, "");
  };

  const handleNextFetch = async () => {
    await fetchUsers(userState.location, userState.accessToken, pagination.page + 1, "");
  };

  useEffect(() => {
    fetchUsers(userState.location, userState.accessToken, 0, "");
  }, []);

  return (
    <>
      {!users.length ? (
        <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
          <DefaultState icon="user" heading="No users found" description="No referral code as been added to your location." />
        </div>
      ) : (
        <>
          <div className="mt-5">
            <p className="w-full text-success text-lg uppercase font-bold">Location: {userState.location}</p>
          </div>

          <Table
            isLoading={isUsersLoading}
            columns={[
              {
                name: "First Name",
                value: "firstName",
              },
              {
                name: "Last Name",
                value: "lastName",
              },
              {
                name: "Email address",
                value: "emailAddress",
              },
              {
                name: "Plan",
                value: "plan",
              },
              {
                name: "Status",
                value: "status",
              },
              {
                name: "Date",
                value: "createdAt",
              },
            ]}
            data={users}
          />
          <Pagination pagination={pagination} next={handleNextFetch} prev={handlePrevFetch} />
        </>
      )}
    </>
  );
}

export default CommunityManagersReferrals;
