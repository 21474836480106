/* eslint-disable */
import makeAPIRequest from "../../../services/makeRequest";
import config from "../../../config";

async function sendCpInvite(email) {
	const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/admin/communitypartner/sendinvite`;
	const response = await makeAPIRequest(API_ENDPOINT, "POST", email);
	const jsonResponse = await response.json();
	return jsonResponse;
}

export default sendCpInvite;
