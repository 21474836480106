import React from "react";
import { MAGIC_NUMBERS } from "../../constant";
import useTogglePassword from "../../hooks/useTogglePassword";
import useUserContext from "../../hooks/useUserContext";
import notify from "../../services/toast";
import { generateClassName } from "../../services/util";
import { validateEmail, validatePassword } from "../../services/validation";
import changePassword from "./changePassword";

function ResetPassword() {
  const { userState } = useUserContext();
  const passwordRef = React.useRef(null);
  const passwordVisibilityRef = React.useRef(null);
  const [togglePasswordVisibility] = useTogglePassword(passwordRef, passwordVisibilityRef);
  //Change Password
  const [emailAddress, setEmailAddress] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [submittingPassword, setSubmittingPassword] = React.useState(false);

  const [validPassword, setValidPassword] = React.useState(false);
  const [validEmailAddress, setValidEmailAddress] = React.useState(false);

  React.useEffect(() => {
    const result = validateEmail(emailAddress);
    setValidEmailAddress(result);
  }, [emailAddress]);

  React.useEffect(() => {
    const result = validatePassword(newPassword);
    setValidPassword(result);
  }, [newPassword]);

  const handlePasswordChange = async event => {
    event.preventDefault();
    setSubmittingPassword(true);
    try {
      const payload = {
        emailAddress: emailAddress,
        password: newPassword,
      };
      const response = await changePassword(payload, userState.accessToken);
      if (response.status === "success") {
        setEmailAddress("");
        setNewPassword("");
        notify("Password successfully changed. You will be logged out automatically.", "success");
      } else if (response.status === "fail") {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setSubmittingPassword(false);
    }
  };
  return (
    <div className="mx-auto max-w-5xl p-4 sm:px-6 lg:px-8 bg-white rounded-md shadow-md">
      <h1 className="text-lg text-success-800 text-left">Reset user's password</h1>
      <form onSubmit={handlePasswordChange} className="mt-5 space-y-6">
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Email
          </label>
          <div className="mt-1">
            <input
              onChange={event => setEmailAddress(event.target.value)}
              value={emailAddress}
              id="email"
              name="email"
              type="email"
              autoComplete="off"
              className={generateClassName(!validEmailAddress && emailAddress)}
            />
          </div>
        </div>

        <div>
          <label htmlFor="lastname" className="block text-sm font-medium text-gray-700">
            New password
          </label>
          <div className="relative mt-1">
            <input
              ref={passwordRef}
              onChange={event => setNewPassword(event.target.value)}
              value={newPassword}
              id="newPassword"
              name="newPassword"
              type="password"
              autoComplete="off"
              className={generateClassName(!validPassword && newPassword)}
            />
            <span ref={passwordVisibilityRef} onClick={togglePasswordVisibility} className="absolute top-3 left-[96%] material-icons-outlined text-gray-400 cursor-pointer">
              visibility
            </span>
          </div>
        </div>
        <div>
          <button
            type="submit"
            disabled={!validPassword || !validEmailAddress || submittingPassword ? true : false}
            className="flex w-full justify-center rounded-md border border-transparent bg-primary-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
            {submittingPassword ? "Please wait..." : "Reset password"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default ResetPassword;
