import React from "react";
import PropTypes from "prop-types";
import PrimaryButton from "./../button/ButtonPrimary";

function DefaultState({ icon, heading, description, action, actionText }) {
  return (
    <div className="h-full w-full flex justify-center items-center">
      <div className="text-center">
        <span className="material-icons-outlined mx-auto text-5xl text-gray-400">{icon}</span>
        <h3 className="mt-2 text-sm font-medium text-gray-900">{heading}</h3>
        <p className="mt-1 text-sm text-gray-500">{description}</p>
        {action ? (
          <div className="mt-6">
            <PrimaryButton text={actionText} size="md" action={action} icon="add" />
          </div>
        ) : null}
      </div>
    </div>
  );
}

DefaultState.propTypes = {
  icon: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.func,
  actionText: PropTypes.string,
};

export default DefaultState;
