/*eslint-disable*/
import config from "../../../../config";
import makeAPIRequest from "../../../../services/makeRequest";

async function getApplicationsForReport(query, accessToken) {
	const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/admin/scholarshipapp/generateReport`;
	let url = `${API_ENDPOINT}?true=true`;
	Object.keys(query).forEach((key) => {
		url += `&${key}=${query[key]}`;
	});

	const response = await makeAPIRequest(url, "GET", null, accessToken);
	const jsonResponse = await response.json();
	return jsonResponse;
}

export default getApplicationsForReport;
