/* eslint-disable react/prop-types */
/* eslint-disable no-magic-numbers */
/* eslint-disable  */
import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import ButtonPrimary from "../button/ButtonPrimary";
import { generateClassName } from "../../services/util";
import { validateName } from "../../services/validation";
import notify from "../../services/toast";
import { ADMINTYPE, APP_LOCATIONS, COHATCH_LOCATIONS, COHATCH_MARKETS, LOCATIONS } from "../../constant";

const EditRoleModal = ({ open, admin, onClose, onSave }) => {
  const [firstName, setFirstName] = useState(admin.firstName || "");
  const [lastName, setLastName] = useState(admin.lastName || "");
  const [location, setLocation] = useState(admin.location || "");
  const [role, setRole] = useState(admin.role || []);
  const [validFirstName, setValidFirstName] = useState(false);
  const [validLastName, setValidLastName] = useState(false);
  const roleSelectRef = useRef(null);
  const locationSelectRef = useRef(null);
  const [market, setMarket] = React.useState(admin.market || "");
  const [coLocation, setCOLocation] = React.useState("");

  const generateCohatchMarket = () => {
  const cohatchMarket = [];

  for (const market in COHATCH_MARKETS) {
    cohatchMarket.push(COHATCH_MARKETS[market]);
  }
  return cohatchMarket.flat();
};

  const handleMarketChange = (event) => {
    console.log(event)
  if (event) {
    const { value } = event;
   console.log(value, "event");
    setMarket(value);
    setLocation(null);
  
  } else {
    //setMarket(null);
    setLocation(null);
 
  }
  };

  const generateCohatchLocation = () => {
    if (market) {
      const cohatchLocation = [];
      cohatchLocation.push(APP_LOCATIONS[market]["locations"]);
      return cohatchLocation.flat();
    } else {
      return [];
    }
  };

  const handleLocationsChange = (event) => {
    if (event) {
      const { value } = event;
      setLocation(value);
    } else {
      setLocation(null);
      // setloadLocations(COHATCH_LOCATIONS[value]["locations"]);
    }
  };


  useEffect(() => {
    const result = validateName(firstName);
    setValidFirstName(result);
  }, [firstName]);

  useEffect(() => {
    const result = validateName(lastName);
    setValidLastName(result);
  }, [lastName]);

  useEffect(() => {
    roleSelectRef.current.setValue(
      admin.role.map((item) => ({
        label: item,
        value: item,
      }))
    );
   
  }, [admin]);

  const handleSave = () => {
    if (!validFirstName || !validLastName || !location || !role.length) {
      notify("One or more fields are invalid. Please check before submitting.", "error");
      return;
    }

    onSave({
      firstName,
      lastName,
      market,
      location,
      role: role.map((item) => item.value),
    });
  };
console.log(market, "hello");
  return (
    <div className={`${open ? "block" : "hidden"} relative z-10`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-gray-100 bg-opacity-60 transition-opacity"></div>

      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <h2 className="text-lg font-bold ">Edit Role</h2>
            <div className="w-full mt-5">
              <div className="w-full flex justify-between items-center">
                <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                  First Name
                </label>
              </div>
              <div className="mt-1">
                <input
                  onChange={(event) => setFirstName(event.target.value)}
                  type="text"
                  name="first_name"
                  id="first_name"
                  value={firstName}
                  className={generateClassName(!validFirstName && firstName)}
                  placeholder="First Name"
                  aria-describedby="name-optional"
                />
              </div>
            </div>
            <div className="w-full mt-5">
              <div className="w-full flex justify-between items-center">
                <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
                  Last Name
                </label>
              </div>
              <div className="mt-1">
                <input
                  onChange={(event) => setLastName(event.target.value)}
                  type="text"
                  name="last_name"
                  id="last_name"
                  value={lastName}
                  className={generateClassName(!validLastName && lastName)}
                  placeholder="Last Name"
                  aria-describedby="name-optional"
                />
              </div>
            </div>
            <div className="w-full mt-5">
              <div className="w-full flex justify-between items-center">
                <label htmlFor="role" className="block text-sm font-medium text-gray-700">
                  Market
                </label>
              </div>
              <div className="mt-1">
                <Select
                  placeholder={admin.market}
                  isDisabled={false}
                  isClearable={true}
                  isLoading={false}
                  isRtl={false}
                  isSearchable={false}
                  name="role"
                  options={generateCohatchMarket()}
                  onChange={(event) => handleMarketChange(event)}
                  //setSelectedValue={handleMarketChange}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#ecf8ef",
                      primary: "#56c271",
                    },
                  })}
                />
              </div>
            </div>

            <div className="w-full mt-5">
              <div className="w-full flex justify-between items-center">
                <label htmlFor="role" className="block text-sm font-medium text-gray-700">
                  Location
                </label>
              </div>
              <div className="mt-1">
                <Select
                  placeholder={admin.location}
                  isDisabled={false}
                  isClearable={true}
                  isLoading={false}
                  isRtl={false}
                  isSearchable={false}
                  name="role"
                  options={generateCohatchLocation()}
                  onChange={(event) => handleLocationsChange(event)}
                  
                  //setSelectedValue={handleLocationsChange}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#ecf8ef",
                      primary: "#56c271",
                    },
                  })}
                />
              </div>
            </div>
            <div className="w-full mt-5">
              <div className="w-full flex justify-between items-center">
                <label htmlFor="role" className="block text-sm font-medium text-gray-700">
                  Role
                </label>
              </div>
              <div className="mt-1">
                <Select
                  placeholder="Role"
                  isDisabled={false}
                  isClearable={true}
                  isLoading={false}
                  isRtl={false}
                  isSearchable={false}
                  isMulti={true}
                  name="role"
                  options={Object.keys(ADMINTYPE).map((role) => ({
                    value: role,
                    label: ADMINTYPE[role],
                  }))}
                  onChange={(event) => setRole(event)}
                  ref={roleSelectRef}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#ecf8ef",
                      primary: "#56c271",
                    },
                  })}
                />
              </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <div>
                <button
                  onClick={onClose}
                  type="button"
                  className="ml-3 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                >
                  Close
                </button>
              </div>
              <div>
                <ButtonPrimary action={handleSave} loading={false} size="md" text="Save" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditRoleModal;
