import React from "react";
import PropTypes from "prop-types";

function Spinner({ displayText }) {
  return (
    <div className="min-w-full text-center mt-3">
      <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status"></div>
      <span className="block text-gray-500 text-sm capitalize italic">{displayText}</span>
    </div>
  );
}

Spinner.propTypes = { displayText: PropTypes.string.isRequired };

export default Spinner;
