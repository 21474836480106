/* eslint-disable no-unused-vars */
import React from "react";
import { Outlet } from "react-router-dom";

const UserContext = React.createContext({});

function UserProvider() {
  const [userState, setUserState] = React.useState({});
  return (
    <UserContext.Provider value={{ userState, setUserState }}>
      <Outlet />
    </UserContext.Provider>
  );
}

export { UserProvider, UserContext };
