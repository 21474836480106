/* eslint-disable react/prop-types */
/* eslint-disable no-magic-numbers */
import React, { useState, useEffect } from "react";
import ButtonPrimary from "../button/ButtonPrimary";
import notify from "../../services/toast";
import getAdmins from "../../pages/administrator/getAdmins";
import useUserContext from "../../hooks/useUserContext";
import { MAGIC_NUMBERS } from "../../constant";

const AdminListModal = ({
	open,
	loading,
	defaultValue,
	types,
	onClose,
	onAssign,
}) => {
	const [selectedAdmin, setSelectedAdmin] = useState("");
	const [admins, setAdmins] = useState([]);
	const { userState } = useUserContext();

	useEffect(async () => {
		const response = await getAdmins(
			{
				page: 0,
				limit: 80,
			},
			userState.accessToken
		);
		if (response.status === "success") {
			setAdmins(
				response.data.results.filter(
					(admin) =>
						types.filter((type) => admin.role.includes(type)).length > 0
				)
			);
		} else if (response.status === "fail") {
			let displayMessage = "";
			if (Array.isArray(response.message)) {
				displayMessage = response.message[0].message;
			} else {
				displayMessage = response.message;
				if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
					displayMessage =
						"An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com";
				}
			}
			notify(displayMessage, "error");
		}
	}, []);

	useEffect(() => {
		if (defaultValue) {
			setSelectedAdmin(defaultValue);
		}
	}, [defaultValue]);

	const handleAssign = () => {
		if (!selectedAdmin) {
			notify("Please pick admin to assign", "error");
			return;
		}

		onAssign(selectedAdmin);
	};

	return (
		<div className="modal-dialog modal-dialog-scrollable">
			<div className={`${open ? "block" : "hidden"} relative z-10`}>
				<div className="fixed inset-0 bg-gray-100 bg-opacity-60 transition-opacity"></div>

				<div className="fixed z-10 inset-0 overflow-y-auto">
					<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
						<span
							className="hidden sm:inline-block sm:align-middle sm:h-screen"
							aria-hidden="true"
						>
							&#8203;
						</span>

						<div
							className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
							style={{
								maxHeight: "calc(100vh - 210px)",
								overflowY: "auto",
							}}
						>
							<h2 className="text-lg font-bold ">Assign Admin</h2>
							<ul className="mt-5 p-2 divide-y divide-slate-200">
								{admins.map((admin) => (
									<li
										key={admin._id}
										className="flex justify-between items-center p-2 first:pt-0 last:pb-0"
									>
										<div className="flex items-center">
											<img
												className="h-10 w-10 rounded-full"
												src={
													admin?.photo?.small
														? admin.photo.small.url
														: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_profile.png"
												}
											/>
											<div className="ml-3 overflow-hidden">
												<p className="text-sm font-medium capitalize">
													{admin.firstName} {admin.lastName}
												</p>
											</div>
										</div>
										<input
											type="checkbox"
											checked={selectedAdmin === admin._id}
											onChange={() =>
												setSelectedAdmin(
													selectedAdmin === admin._id ? "" : admin._id
												)
											}
											className="focus:ring-primary-500 h-4 w-4 text-primary-400 border-gray-300"
										/>
									</li>
								))}
							</ul>
							<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
								<div>
									<button
										onClick={onClose}
										type="button"
										className="ml-3 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
									>
										Close
									</button>
								</div>
								<div>
									<ButtonPrimary
										action={handleAssign}
										loading={!selectedAdmin || loading}
										size="md"
										text="Assign"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AdminListModal;
