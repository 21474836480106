import config from "../../config";
import makeAPIRequest from "../../services/makeRequest";

async function changePassword(payload, accessToken) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/admin/user/reset-password`;
  const response = await makeAPIRequest(API_ENDPOINT, "PUT", payload, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default changePassword;
