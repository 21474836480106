import React from "react";
import notify from "../../services/toast";
import useUserContext from "../../hooks/useUserContext";
import changePassword from "./changePassword";
import { validatePassword } from "../../services/validation";
import { generateClassName } from "../../services/util";
import Title from "../../components/title/Title";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import { MAGIC_NUMBERS } from "../../constant";
import useTogglePassword from "./../../hooks/useTogglePassword";

function Settings() {
  const { userState } = useUserContext();
  const passwordRef = React.useRef(null);
  const passwordVisibilityRef = React.useRef(null);
  const [togglePasswordVisibility] = useTogglePassword(passwordRef, passwordVisibilityRef);
  //Change Password
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [submittingPassword, setSubmittingPassword] = React.useState(false);

  const [validPassword, setValidPassword] = React.useState(false);

  React.useEffect(() => {
    const result = validatePassword(newPassword);
    setValidPassword(result);
  }, [newPassword]);

  const handlePasswordChange = async event => {
    event.preventDefault();
    setSubmittingPassword(true);
    try {
      const payload = {
        oldPassword: oldPassword,
        newPassword: newPassword,
      };
      const response = await changePassword(payload, userState.accessToken);
      if (response.status === "success") {
        setOldPassword("");
        setNewPassword("");
        notify("Password successfully changed. You will be logged out automatically.", "success");
        window.setTimeout(() => {
          window.location.replace("/");
        }, 3000);
      } else if (response.status === "fail") {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setSubmittingPassword(false);
    }
  };
  return (
    <>
      <Title name="Settings" />
      <Breadcrumb routes={["Dashboard", "Settings"]} />
      <div className="w-full space-y-5">
        <div className="mx-auto max-w-5xl p-4 sm:px-6 lg:px-8 bg-white rounded-md shadow-md">
          <h1 className="text-lg text-success-800 text-left">Change Admin Account Password</h1>
          <form onSubmit={handlePasswordChange} className="mt-5 space-y-6">
            <div>
              <label htmlFor="oldPassword" className="block text-sm font-medium text-gray-700">
                Old Password
              </label>
              <div className="mt-1">
                <input
                  onChange={event => setOldPassword(event.target.value)}
                  value={oldPassword}
                  id="oldPassword"
                  name="oldPassword"
                  type="password"
                  autoComplete="off"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
                />
              </div>
            </div>

            <div>
              <label htmlFor="lastname" className="block text-sm font-medium text-gray-700">
                New Password
              </label>
              <div className="relative mt-1">
                <input
                  ref={passwordRef}
                  onChange={event => setNewPassword(event.target.value)}
                  value={newPassword}
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  autoComplete="off"
                  className={generateClassName(!validPassword && newPassword)}
                />
                <span ref={passwordVisibilityRef} onClick={togglePasswordVisibility} className="absolute top-3 left-[96%] material-icons-outlined text-gray-400 cursor-pointer">
                  visibility
                </span>
              </div>
            </div>
            <div>
              <button
                type="submit"
                disabled={!validPassword || !oldPassword || submittingPassword ? true : false}
                className="flex w-full justify-center rounded-md border border-transparent bg-primary-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                {submittingPassword ? "Please wait..." : "Change Password"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Settings;
