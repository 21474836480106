import config from "../../config";
import makeAPIRequest from "../../services/makeRequest";

async function resetPassword(accessToken, payload) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/admin/member/reset_password`;
  const response = await makeAPIRequest(API_ENDPOINT, "PUT", payload, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default resetPassword;
