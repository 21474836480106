/* eslint-disable capitalized-comments */
import config from "../../config";
import makeAPIRequest from "../../services/makeRequest";

const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/admin/event`;

async function updateEvent(eventId, body, accessToken) {
  const response = await makeAPIRequest(`${API_ENDPOINT}/${eventId}`, "PUT", body, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default updateEvent;


