import config from "../../config";
import makeAPIRequest from "../../services/makeRequest";

const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/admin/administrator/users`;

async function deleteUser(id, accessToken) {
  const response = await makeAPIRequest(`${API_ENDPOINT}/${id}`, "DELETE", null, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default deleteUser;
