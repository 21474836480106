
/*eslint-disable*/
import React from "react";
import { Bar } from "react-chartjs-2";
import { format, parseISO } from 'date-fns';



const createDoubleBarGraphData = (data) => {
  const countsPerMonthGive = {};
  const countsPerMonthBoost = {};

  data.forEach((app) => {
    const formattedDate = format(parseISO(app.createdAt), 'MMMM yyyy');

    if (app.appStatus === "approved") {
      if (app.type === 'give') {
        countsPerMonthGive[formattedDate] = (countsPerMonthGive[formattedDate] || 0) + 1;
      } else if (app.type === 'boost') {
        countsPerMonthBoost[formattedDate] = (countsPerMonthBoost[formattedDate] || 0) + 1;
      }
    }
  });

  // Combine unique months from both "give" and "boost" applications
  const allMonthsSet = new Set([...Object.keys(countsPerMonthGive), ...Object.keys(countsPerMonthBoost)]);
  const allMonths = Array.from(allMonthsSet).sort((a, b) => new Date(a) - new Date(b));

  // Initialize counts for both "give" and "boost" for all months
  const countsGive = allMonths.map(month => countsPerMonthGive[month] || 0);
  const countsBoost = allMonths.map(month => countsPerMonthBoost[month] || 0);

  return {
    labels: allMonths,
    datasets: [
      {
        label: 'Give',
        data: countsGive,
        backgroundColor: '#50C878',
        borderColor: '#50C878',
      },
      {
        label: 'Boost',
        data: countsBoost,
        backgroundColor: '#00008B',
        borderColor: '#00008B',
      }
    ]
  };
};

const BarChart = ({applications}) => {
const doubleBarGraphData = createDoubleBarGraphData(applications);


  return (
    <>
    <div className="rounded bg-white p-4 chart-container">
      <h2 style={{ textAlign: "center" }}>Scholars Accepted By Month</h2>
      <Bar
        data={doubleBarGraphData}
      />
    </div>

    </>
  );
}
export default BarChart;
