import config from "../../../config";
import makeAPIRequest from "../../../services/makeRequest";


async function getImpactStats( accessToken) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/admin/impactdashboard`;
  const response = await makeAPIRequest(API_ENDPOINT, "GET", null, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default getImpactStats;