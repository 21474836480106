/* eslint-disable */
import React, { useState, useCallback, useEffect, useRef } from "react";
import useUserContext from "../../../hooks/useUserContext";
import { Outlet, useNavigate, NavLink } from "react-router-dom";
import { debounce } from "../../../services/util";
import {
	PLANS,
	COHATCH_LOCATIONS,
	MAGIC_NUMBERS,
	COHATCH_MARKETS,
	APP_STATUS,
} from "../../../constant";
import Select from "react-select";
import getApplications from "../../scholarship_applications/requests/getApplications";
import CPLogo from "../../../assets/CommunityPartnerLogo.png";
import { Total } from "./AppLocationCard";
import getImpactMarketData from "./getImpactMarketData";
import getAllImpactStats from "../dashboard/getAllImpactStats";
import notify from "../../../services/toast";
import { capitalizeFirstLetter } from "../../../services/util";
import { Calendar, DateRangePicker, DateRange } from "react-date-range";
import { format, addDays, lastDayOfMonth, parseISO } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../TestAdmin/Themes";
import useTitle from "../../../hooks/useTitle";
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarFilterButton,
	GridToolbarDensitySelector,
	GridToolbarColumnsButton,
	GridToolbarExport,
} from "@mui/x-data-grid";
import { usePartialTheme } from "@nivo/core";
import { MdLocationPin } from "react-icons/md";
import Logo from "../../../assets/NonProfit.png";
import SULogo from "../../../assets/StartUp.png";
import { PieChart, Pie, Cell, Label, Tooltip } from "recharts";
import getMarketLevelData from "./getMarketLevelData";
import Prompt from "../../../components/prompt/Prompt";
import { showPrompt } from "../../../services/util";
import deleteApplication from "../../scholarship_applications/requests/deleteApplication";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

function DateRangeCalendar({ setDateRange }) {
	const { userState } = useUserContext();
	const [openDate, setOpenDate] = useState(false);

	const today = new Date();
	const currentDate = format(today, "MMMM dd, yyyy");

	const [date, setDate] = useState({
		startDate: new Date(),
		endDate: new Date(),
		key: "selection",
	});

	const handleChange = (ranges) => {
		setDate(ranges.selection);
		setDateRange(ranges.selection);
	};

	const handleClick = () => {
		setOpenDate((prev) => !prev);
	};

	return (
		<div className="mt-5 container relative z-10">
			<div>
				<div className="bg-white w-72">
					<div
						onClick={handleClick}
						className="cursor-pointer text-white w-full px-3 py-2 border border-gray-600 rounded-md"
					>
						<span className="ml-3 text-black material-icons-outlined">
							calendar_month
						</span>
					</div>
				</div>
			</div>

			<div>
				{openDate && (
					<DateRangePicker
						className="dateRange"
						//maxDate={addDays(new Date(), 1)}
						months={2}
						ranges={[date]}
						direction="horizontal"
						preventSnapRefocus={true}
						calendarFocus="backwards"
						onChange={handleChange}
					/>
				)}
			</div>
		</div>
	);
}
function CustomToolbar() {
	return (
		<GridToolbarContainer>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<Box sx={{ flexGrow: 1 }} />
			<GridToolbarExport
				slotprops={{
					tooltip: { title: "Export data" },
					button: { variant: "outlined" },
				}}
			/>
		</GridToolbarContainer>
	);
}

function MarketLevel() {
	useTitle("Market Level");
	const [dashstats, setStats] = useState({});
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(window.location.search);
	const type = queryParams.get("type") || "";
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const { userState } = useUserContext();
	const [apps, setApps] = useState([]);
	const [marketData, setMarketData] = useState([]);
	const [filteredApps, setFilteredApps] = useState();
	const [market, setMarket] = useState("");
	const [isMarketDataLoading, setIsMarketDataLoading] = useState(true);
	const [isAppsLoading, setIsAppsLoading] = useState(true);
	const [isListingCategoryLoading, setIsListingCategoryLoading] =
		useState(false);
	const [pagination, setPagination] = React.useState({
		total: 0,
		page: 0,
		remaining: 0,
		limit: 11,
	});

	const award = dashstats.annualAward;
	const giveAward = dashstats.annualGiveAward;
	const boostAward = dashstats.annualBoostAward;
	const renewal = dashstats.totalRenewals;
	const nonRenewal = dashstats.totalNonRenewals;
	const giveRenewal = dashstats.totalGiveRenewals;
	const giveNonRenewal = dashstats.totalGiveNonRenewals;
	const boostRenewal = dashstats.totalBoostRenewals;
	const boostNonRenewal = dashstats.totalBoostNonRenewals;

	//Pie graph
	const data = [
		{ name: "Renewing Scholars", value: renewal, color: "#ffbb28" },
		{ name: "New Scholars", value: nonRenewal, color: "#00C49F" },
	];

	const dataGive = [
		{ name: "Renewing Scholars", value: giveRenewal, color: "#ffbb28" },
		{ name: "New Scholars", value: giveNonRenewal, color: "#00C49F" },
	];
	const dataBoost = [
		{ name: "Renewing Scholars", value: boostRenewal, color: "#ffbb28" },
		{ name: "New Scholars", value: boostNonRenewal, color: "#00C49F" },
	];

	const [activeTab, setActiveTab] = useState("All Applications");

	const changeTab = (location) => {
		setActiveTab(location);
	};

	// Function to filter applications based on the active tab
	const filteredApplications =
		activeTab === "All Applications"
			? marketData
			: marketData.filter(
					(application) => application.coLocation === activeTab
			  );

	// Get unique locations for tabs
	const locations = [
		"All Applications",
		...new Set(marketData.map((application) => application.coLocation)),
	];

	const columns = [
		{
			field: "orgName",
			headerName: "Organization",
			flex: 1,
			renderCell: ({
				row: { logoImage, orgName, firstName, lastName, _id },
			}) => {
				return (

					<a
						href={`/dashboard/profile/${_id}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						<div className="float-left">
							<div className="text-center ">
								{logoImage?.small?.url ? (
									<img
										className="h-12 w-12 rounded-full ring-4 ring-white"
										src={logoImage.small.url}
									/>
								) : logoImage?.logoImage?.url ? (
									<img
										className="h-12 w-12 rounded-full ring-4 ring-white"
										src={logoImage.logoImage.url}
									/>
								) : (
									type === "Community Partner" && (
										<img
											className="h-12 w-12 rounded-full ring-4 ring-white"
											src={CPLogo}
										/>
									)
								)}
							</div>
						</div>
						<div className="float-left ml-3 font-bold">
							<h1>{orgName}</h1>
							<p className="text-gray-500">
								{firstName} {lastName}
							</p>
						</div>
					</a>
				);
			},
		},
		{
			field: "type",
			headerName: "Scholarship Type",
			flex: 1,
			renderCell: ({ row: { type, _id } }) => {
				return (
					<a
						href={`/dashboard/profile/${_id}`}
						target="_blank"
						rel="noopener noreferrer"
						className="whitespace-nowrap py-4 pl-4 pr-3  text-gray-900 sm:pl-6 capitalize font-bold"
					>
						{type}
					</a>
				);
			},
		},
		{
			field: "appStatus",
			headerName: "App Status",
			flex: 1,
			renderCell: ({ row: { appStatus, _id } }) => {
				return (
					<a
						href={`/dashboard/profile/${_id}`}
						target="_blank"
						rel="noopener noreferrer"
						className={`capitalize font-bold ${
							appStatus === "approved"
								? "text-primary-500"
								: appStatus === "rejected"
								? "text-red-700"
								: "text-gray-500"
						}`}
					>
						{appStatus}
					</a>
				);
			},
		},
		{
			field: "cmStatus",
			headerName: "CM Status",
			flex: 1,
			renderCell: ({ row: { cmStatus, _id } }) => {
				return (
					<a
						href={`/dashboard/profile/${_id}`}
						target="_blank"
						rel="noopener noreferrer"
						className={`capitalize font-semibold ${
							cmStatus === "approved"
								? "text-primary-500"
								: cmStatus === "rejected"
								? "text-red-700"
								: "text-gray-500"
						}`}
					>
						{cmStatus}
					</a>
				);
			},
		},
		{
			field: "coLocation",
			headerName: "Location",
			flex: 1,
			renderCell: ({ row: { coLocation, _id } }) => {
				return (
					<a
						href={`/dashboard/profile/${_id}`}
						target="_blank"
						rel="noopener noreferrer"
						className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
					>
						<MdLocationPin className="float-left text-2xl " />
						{coLocation}
					</a>
				);
			},
		},
		{
			field: "createdAt",
			headerName: "Submission Date",
			flex: 1,
			valueGetter: ({ value }) => value && format(new Date(value), "MM/dd/yyy"),
		},
		{
			field: "marketData",
			headerName: "",
			flex: 1,
			renderCell: ({ row: { orgName, _id } }) => {
				return (
					<div
						onClick={() => handleDeletePrompt(orgName, _id)}
						className="cursor-pointer material-icons-outlined text-red-700"
					>
						delete
					</div>
				);
			},
		},
	];
	const columns2 = [
		{
			field: "orgName",
			headerName: "Organization",
			flex: 1,
			renderCell: ({ row: { orgName } }) => {
				return (
					<p className="whitespace-nowrap py-4 pl-4 pr-3  text-gray-900 sm:pl-6 capitalize font-bold">
						{orgName}
					</p>
				);
			},
		},
		{
			field: "type",
			headerName: "Type",
			flex: 1,
			renderCell: ({ row: { type } }) => {
				return (
					<p className="whitespace-nowrap py-4 pl-4 pr-3  text-gray-900 sm:pl-6 capitalize font-bold">
						{type}
					</p>
				);
			},
		},
		{
			field: "coLocation",
			headerName: "Location",
			flex: 1,
			renderCell: ({ row: { coLocation } }) => {
				return (
					<p className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
						<MdLocationPin className="float-left text-2xl " />
						{coLocation}
					</p>
				);
			},
		},
	];

	const cities = COHATCH_LOCATIONS.cities;

	const tabs = [
		{ name: "All", value: "", end: true },
		{ name: "Give", value: "give" },
		{ name: "Boost", value: "boost" },
		{ name: "Community Partner (coming soon)", value: "communityPartner" },
	];

	const markets = [
		"All",
		"Cincinnati",
		"Cleveland",
		"Columbus",
		"Indianapolis",
		"Tampa",
		"Pittsburgh",
		"Charlotte",
	];

	const [isStatsLoading, setIsStatsLoading] = useState(false);

	const [dateRange, setDateRange] = useState("");

	const applicationsByCategoryData = {
		labels: APP_STATUS.map((category) => category.label),
		datasets: [
			{
				label: "Listing By Market",
				data: APP_STATUS.map((category) => {
					const item = (marketData.applicationsByCategory || []).find(
						(mem) => mem._id === category.value
					);
					return item ? item.total : 0;
				}),
				borderColor: "rgb(53, 162, 235)",
				backgroundColor: "rgba(53, 162, 235, 0.5)",
			},
		],
	};

	const handleSearch = () => {
		// const query = event.target.value;
		// fetchUsers(userState.accessToken, 0, query, productRef.current);
		// setKeyword(query);
		return 1;
	};

	const debouncedHandleSearch = useCallback(debounce(handleSearch, 500), []);

	const [displayPromptMessage, setDisplayPromptMessage] = useState({
		heading: "",
		body: "",
	});
	const [currentId, setCurrentId] = useState();
	const deletePromptRef = useRef(null);

	const handleDeletePrompt = (orgName, id) => {
		showPrompt(
			"Delete Application",
			`Are you sure you want to delete "${orgName}'s" application? This action cannot be undone.`,
			deletePromptRef,
			"open",
			setDisplayPromptMessage
		);
		setCurrentId(id);
	};

	const handleDelete = async () => {
		const response = await deleteApplication(currentId, userState.accessToken);
		if (response.status === "success") {
			notify("Application successfully deleted", "info");
			deletePromptRef.current.closePrompt();
			window.location.reload(true);
		} else if (response.status === "fail") {
			let displayMessage = "";
			if (Array.isArray(response.message)) {
				displayMessage = response.message[0].message;
			} else {
				displayMessage = response.message;
				if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
					displayMessage =
						"An error has occurred. Please try again later. If this error persists please contact support";
				}
			}
			notify(displayMessage, "error");
		}
	};

	function renderLocationApps(app, index) {
		return <AppLocationCard key={index} application={app} />;
	}

	const handleNavigate = (type) => {
		navigate(`/dashboard/marketlevel/${userState.market}?type=${type}`);
	};

	const [filteredPendingApps, setFilteredPendingApps] = useState("");
	function filterPendingApps(apps) {
		const pendingApps = apps.filter(
			(apps) => apps.appStatus === "pending" && apps.market === userState.market
		);
		//console.log(pendingApps);
		setFilteredPendingApps(pendingApps);
	}
	function filterLocationApps(apps) {
		const pendingApps = apps.filter((apps) => apps.market === userState.market);
		//console.log(pendingApps);
		setApps(pendingApps);
	}

	const fetchMarketData = async () => {
		const response = await getMarketLevelData(
			userState.accessToken,
			userState.market
		);
		//const response = await getImpactMarketData(userState.accessToken);
		if (response.status === "success") {
			setMarketData(response.data.appsByMarket);
			//setStats(response.data);

			filterAppsByLocation(response.data.appsByMarket);
		} else if (response.status === "fail") {
			let displayMessage = "";
			if (Array.isArray(response.message)) {
				displayMessage = response.message[0].message;
			} else {
				displayMessage = response.message;
				if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
					displayMessage =
						"An error has occurred. Please try again later. If this error persists please contact support";
				}
			}
			notify(displayMessage, "error");
		}
		setIsMarketDataLoading(false);
		//setIsListingCategoryLoading(false);
	};

	const filterAppsByLocation = (data) => {
		const result = data.reduce((accumulator, application) => {
			const { coLocation, type, appStatus } = application;

			if (!accumulator[coLocation]) {
				accumulator[coLocation] = {
					total: 0,
					pending: 0,
					approved: 0,
					rejected: 0,
					coLocation: coLocation,
				};
			}
			accumulator[coLocation].total += 1;
			if (type === "give") {
				accumulator[coLocation].giveCount++;
			} else if (type === "boost") {
				accumulator[coLocation].boostCount++;
			}

			switch (appStatus) {
				case "pending":
					accumulator[coLocation].pending += 1;
					break;
				case "approved":
					accumulator[coLocation].approved += 1;
					break;
				case "rejected":
					accumulator[coLocation].rejected += 1;
					break;
				default:
					break;
			}

			return accumulator;
		}, {});

		const resultArray = Object.values(result);
		const sortedResult = resultArray.sort(
			(a, b) => b.totalAward - a.totalAward
		);

		setFilteredApps(sortedResult);
		return sortedResult;
	};

	// Controller function to sort applications by location and group them
	const sortApplicationsByLocation = (marketData) => {
		const groupedApplications = {};

		marketData.forEach((application) => {
			if (!groupedApplications[application.coLocation]) {
				groupedApplications[application.coLocation] = [];
			}
			groupedApplications[application.coLocation].push(application);
		});

		return groupedApplications;
	};

	// Example usage
	const groupedApplications = sortApplicationsByLocation(marketData);

	const fetchApplications = async () => {
		const response = await getApplications(userState.accessToken);
		if (response.status === "success") {
			filterLocationApps(response.data);
			filterPendingApps(response.data);
		} else if (response.status === "fail") {
			let displayMessage = "";
			if (Array.isArray(response.message)) {
				displayMessage = response.message[0].message;
			} else {
				displayMessage = response.message[0].message;
				if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
					displayMessage =
						"An error has occurred. Please try again later. If this error persists please contact support";
				}
			}
		}
	};

	const fetchStats = async () => {
		const response = await getAllImpactStats(userState.accessToken);
		if (response.status === "success") {
			setStats(response.data);
		} else if (response.status === "fail") {
			let displayMessage = "";
			if (Array.isArray(response.message)) {
				displayMessage = response.message[0].message;
			} else {
				displayMessage = response.message;
				if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
					displayMessage =
						"An error has occurred. Please try again later. If this error persists please contact support";
				}
			}
			notify(displayMessage, "error");
		}
		setIsStatsLoading(false);
	};

	const handleChangeMarket = (value) => {
		const city = value.value;
		navigate(`/dashboard/locations/${city}`);
	};

	function getRowId(row) {
		return row._id;
	}

	function handleClick(id) {
		window.open(
			`/dashboard/profile/${id}`,
			"_blank",
			"rel=noopener noreferrer"
		);
	}

	function CustomLabel({ viewBox, value1, value2 }) {
		const { cx, cy } = viewBox;
		return (
			<svg
				// width="500"
				// height="200"
				className="recharts-text recharts-label"
				textAnchor="middle"
				dominantBaseline="central"
			>
				<text x={cx} y={cy} fill="#3d405c">
					<tspan x={cx} dy="0em" alignmentBaseline="middle" fontSize="14">
						{`${value1} Renewing `}
					</tspan>
					<tspan x={cx} dy="1em" fontSize="14">
						{`${value2} New`}
					</tspan>
				</text>
			</svg>
			//  <text x={cx} y={cy} fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
			//     <tspan alignmentBaseline="middle" fontSize="26">{value1}</tspan>
			//     <tspan fontSize="14">{value2}</tspan>
			//  </text>
		);
	}

	useEffect(() => {
		fetchMarketData();
		fetchStats();
		fetchApplications();
	}, [type]);

	return (
		<>
			{userState.market ? (
				<>
					<div className="ml-20 max-w-6xl ">
						<div className="ml-10 mx-auto px-4 sm:px-6 lg:px-8 mb-20">
							<div className="mt-20 mb-5">
								<h1 className=" mb-5 font-bold text-4xl">
									{capitalizeFirstLetter(userState.market)} Location
									Scholarships
								</h1>
							</div>
						</div>
						<div className="ml-10 max-w-7xl">
							{/* Navigation */}
							{/* <div className="w-full">
							<div className="sm:hidden">
								<label htmlFor="tabs" className="sr-only">
									Select a tab
								</label>
								<select
									id="tabs"
									name="tabs"
									className="block w-full focus:ring-primary-400 focus:border-primary-400 border-primary-400 rounded-md"
									defaultValue={`/dashboard/marketlevel/${userState.market}${
										tabs.value ? `?type=${tabs.value}` : ""
									}`}
									onChange={(evt) => handleNavigate(evt.target.value)}
								>
									{tabs.map((tab) => (
										<option
											key={tab.name}
											value={tab.value}
											defaultValue={`/dashboard/marketlevel/${
												userState.market
											}${tab.value ? `?type=${tab.value}` : ""}`}
										>
											{tab.name}
										</option>
									))}
								</select>
							</div>
							<div className="hidden sm:block">
								<nav
									className="isolate flex divide-x divide-gray-200 rounded-lg shadow"
									aria-label="Tabs"
								>
									{tabs.map((tab, tabIdx) => (
										<NavLink
											key={tab.name}
											to={`/dashboard/marketlevel/${userState.market}${
												tab.value ? `?type=${tab.value}` : ""
											}`}
											end={tab?.end}
											className={classNames(
												tab.value === type
													? "border-primary-400 text-primary-400"
													: "border-transparent text-success-800 hover:text-primary-400 hover:border-primary-400",
												tabIdx === 0 ? "rounded-l-lg" : "",
												tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
												"group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
											)}
											aria-hidden="true"
										>
											<span>{tab.name}</span>
										</NavLink>
									))}
								</nav>
							</div>
						</div> */}
							<div>
								<div className="grid grid-cols-6">
									<div className="mt-10 col-span-4">
										<div className="widgets gap-x-4 flex-1 flex">
											<div>
												<div className="widget h-36 bg-white rounded-lg shadow-md shadow-grey-600 flex flex-1 p-2 justify-between">
													<div className="left flex flex-col ">
														<span className="title text-4xl font-bold mb-3">
															$
															{award?.[0]?.annualAward
																? award[0].annualAward.toLocaleString()
																: 0}
														</span>
														<span className="counter text-gray-500 text-xl">
															Scholarship Dollars Awarded
														</span>
													</div>
												</div>

												<div className="widget h-36 mt-4 bg-white rounded-lg shadow-md shadow-grey-600 flex flex-1 p-2 justify-between">
													<div className="left flex flex-col ">
														<span className="title text-4xl font-bold mb-3">
															{dashstats.totalApprovedApplications}
														</span>
														<span className="counter text-gray-500 text-xl">
															Active Scholars
														</span>
													</div>
												</div>
											</div>

											<div className="widget h-36 bg-white rounded-lg shadow-md shadow-grey-600 flex flex-1 p-2 justify-between">
												<div className="left flex flex-col ">
													<span className="title text-4xl font-bold mb-3">
														{dashstats.totalPendingApplications}
													</span>
													<span className="counter text-gray-500 text-xl">
														Pending Scholars{" "}
													</span>
												</div>
											</div>

											<div className="widget h-36 bg-white rounded-lg shadow-md shadow-grey-600 flex flex-1 p-2 justify-between">
												<div className="left flex flex-col ">
													<span className="title text-4xl font-bold mb-3">
														{dashstats.totalDeniedApplications}
													</span>
													<span className="counter text-gray-500 text-xl">
														Denied Scholars{" "}
													</span>
												</div>
											</div>
										</div>
									</div>
									<div className="col-span-2">
										<div className="m-7">
											<h1 className="ml-5 text-2xl semi-bold">
												Renewal VS. New Scholars
											</h1>
											<PieChart width={300} height={300}>
												<Pie
													data={data}
													cx={100}
													cy={100}
													innerRadius={60}
													outerRadius={100}
													fill="#8884d8"
													dataKey="value"
													paddingAngle={5}
												>
													<Label
														content={
															<CustomLabel
																value1={renewal}
																value2={nonRenewal}
															/>
														}
													></Label>
													{data.map((entry, index) => (
														<Cell key={`cell-${index}`} fill={entry.color} />
													))}
												</Pie>
												<Tooltip />
											</PieChart>
										</div>
									</div>
								</div>
								<div>Market Locations</div>
								<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
									<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
										<div className="overflow-hidden bg-white shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
											<table className="min-w-full divide-y divide-gray-300">
												<thead className="mb-3  shadow ring-1 ring-black ring-opacity-5">
													<tr>
														<th
															scope="col"
															className="px-3 py-3.5 text-left text-sm font-semibold"
														>
															Location
														</th>
														<th
															scope="col"
															className="px-3 py-3.5 text-left text-sm font-semibold"
														>
															Total
														</th>
														<th
															scope="col"
															className="px-3 py-3.5 text-left text-sm font-semibold"
														>
															Pending
														</th>
														<th
															scope="col"
															className="px-3 py-3.5 text-left text-sm font-semibold"
														>
															Approved
														</th>
														<th
															scope="col"
															className="px-3 py-3.5 text-left text-sm font-semibold "
														>
															Denied
														</th>
													</tr>
												</thead>
												<tbody className="divide-y divide-gray-200 bg-white">
													{filteredApps?.length > 0 &&
														filteredApps.map((app) => (
															<tr className="cursor-pointer">
																<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
																	{app.coLocation}
																</td>
																<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
																	{app.total}
																</td>
																<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
																	{app.pending}
																</td>
																<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
																	{app.approved}
																</td>
																<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
																	{app.rejected}
																</td>
															</tr>
														))}
													{/* <tr className="cursor-pointer" onClick={() => {navigate("/impact/all_scholarships/cincinnati");}}>

      
      <td className="pl-5 text-md text-gray-900">{marketData.totalCincinnati}</td>
      <td className="pl-5 text-md text-gray-900">{marketData.totalPendingCincinnati}</td>
      <td className="pl-5 text-md text-gray-900">{marketData.totalApprovedCincinnati}</td>
      <td className="pl-5 text-md text-gray-900">{marketData.totalDeniedCincinnati}</td>
    </tr>
    <tr className="cursor-pointer" onClick={() => {navigate("/impact/all_scholarships/cleveland");}}>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
        Ohio City
      </td>
      <td className="pl-5 text-md text-gray-900">{marketData.totalCleveland}</td>
      <td className="pl-5 text-md text-gray-900">{marketData.totalPendingCleveland}</td>
      <td className="pl-5 text-md text-gray-900">{marketData.totalApprovedCleveland}</td>
      <td className="pl-5 text-md text-gray-900">{marketData.totalDeniedCleveland}</td>
    </tr> */}
												</tbody>
											</table>
										</div>
									</div>
								</div>

								<div className="">
									<div className=" mt-5">
										<div className="sm:hidden">
											<label htmlFor="tabs" className="sr-only">
												Select a tab
											</label>
											{/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
											<select
												id="tabs"
												name="tabs"
												className="mt-4 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-purple-500 focus:outline-none focus:ring-purple-500 sm:text-sm"
												defaultValue={activeTab}
											>
												{locations.map((location) => (
													<option
														key={location}
														onClick={() => {
															changeTab(location);
														}}
													>
														{location}
													</option>
												))}
											</select>
										</div>
										<div className="hidden sm:block">
											<div className="border-b border-gray-200">
												<nav
													className="mt-2 -mb-px flex space-x-8"
													aria-label="Tabs"
												>
													{locations.map((location) => (
														<div
															key={location}
															onClick={() => {
																changeTab(location);
															}}
															className={classNames(
																activeTab === location
																	? "border-purple-500 text-purple-600"
																	: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
																"whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer"
															)}
														>
															{location}
														</div>
													))}
												</nav>
											</div>
										</div>
										<div></div>
										<Box m="5px">
											<Box
												height="75vh"
												width="full"
												sx={{
													boxShadow: 1,
													// borderColor: colors.grey[400],
													backgroundColor: "#FFFFFF",
													"& .MuiDataGrid-columnHeaders": {
														fontWeight: "bold",
													},
												}}
											>
												<DataGrid
													rows={filteredApplications}
													rowHeight={100}
													columns={columns}
													slots={{ toolbar: CustomToolbar }}
													getRowId={getRowId}
												/>
											</Box>
										</Box>
									</div>
								</div>
								<div className=" mt-10">
									<Box m="5px">
										<div className="mb-5">Pending Applications</div>
										<Box
											height="75vh"
											width="full"
											sx={{
												boxShadow: 1,
												// borderColor: colors.grey[400],
												backgroundColor: "#FFFFFF",
												"& .MuiDataGrid-columnHeaders": {
													fontWeight: "bold",
												},
											}}
										>
											<DataGrid
												rows={filteredPendingApps}
												rowHeight={100}
												columns={columns2}
												getRowId={getRowId}
											/>
										</Box>
									</Box>
									<Prompt
										header={displayPromptMessage.heading}
										body={displayPromptMessage.body}
										ref={deletePromptRef}
										action={handleDelete}
									/>
								</div>
							</div>
						</div>
						<Outlet />
					</div>
				</>
			) : (
				<div>Request Market Assignment from Admin</div>
			)}
		</>
	);
}

export default MarketLevel;
