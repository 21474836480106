/* eslint-disable */
import React, { useEffect, useState } from "react";
import useUserContext from "../../../hooks/useUserContext";
import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../TestAdmin/Themes";
import notify from "../../../services/toast";
import getImpactReports from "./requests/getImpactReports";
import ImpactReportModal from "./ImpactReportModal";
import { capitalizeFirstLetter } from "../../../services/util";
import format from "date-fns/format";

function ImpactReports() {
	const [showReportModal, setShowReportModal] = useState(false);
	const { userState } = useUserContext();
	const theme = useTheme();

	const colors = tokens(theme.palette.mode);
	const [reports, setReports] = useState();
	const columns = [
		{
			field: "month",
			headerName: "Month",
			flex: 1,
		},
		{
			field: "market",
			headerName: "Market",
			flex: 1,
			renderCell: ({ row: { market } }) => {
				return `${capitalizeFirstLetter(market)}`;
			},
		},
		{
			field: "createdAt",
			headerName: "Date Created",
			flex: 1,
			valueGetter: ({ value }) => value && format(new Date(value), "MM/dd/yyy"),
		},
		{
			field: "createdBy",
			headerName: "Created By",
			flex: 1,
		},
	];

	function getRowId(row) {
		return row._id;
	}

	function handleClick(row) {
		console.log(row, "row");
		window.open(`reports/file/${row.id}`, "_blank", "rel=noopener noreferrer");
	}

	const fetchImpactReports = async (accessToken) => {
		try {
			const response = await getImpactReports(accessToken);
			if (response.status === "success") {
				console.log(response);
				setReports(response.data);
				//  window.location.reload();
				//notify("Successfully Generated!")
			} else if (response.status === "fail") {
				let displayMessage = "";
				if (Array.isArray(response.message)) {
					displayMessage = response.message[0].message;
				} else {
					displayMessage = response.message;
					if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
						displayMessage =
							"An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
					}
				}
				notify(displayMessage, "error");
			}
		} catch (error) {
			notify("Something went wrong. Please try again later.", "error");
		}
	};

	useEffect(() => {
		fetchImpactReports(userState.accessToken);
	}, []);

	return (
		<>
			<div className="max-w-7xl mx-auto sm:px-6 lg:px-8 ">
				<h1 className="font-bold text-3xl">Impact Reports</h1>
				<div className="flex justify-end ">
					<div>
						<button
							onClick={() => setShowReportModal(true)}
							className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
						>
							+ Create Report
						</button>
					</div>
					<ImpactReportModal
						open={showReportModal}
						onClose={() => setShowReportModal(false)}
					/>
				</div>
			</div>

			<Box m="20px">
				<Box
					m="40px 0 0 0"
					height="75vh"
					sx={{
						boxShadow: 1,
						// borderColor: colors.grey[400],
						backgroundColor: "#FFFFFF",
						"& .MuiDataGrid-columnHeaders": {
							fontWeight: "bold",
						},
					}}
				>
					{reports && (
						<DataGrid
							onRowClick={handleClick}
							rows={reports}
							columns={columns}
							components={{ Toolbar: GridToolbar }}
							getRowId={(row) => row._id}
						/>
					)}
				</Box>
			</Box>
		</>
	);
}

export default ImpactReports;
