import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

function LinkTab({ items }) {
  return (
    <div className="mt-5">
      <div className="border-b border-gray-200">
        <nav className="-mb-px flex" aria-label="Tabs">
          {items.map((item, index) => (<NavLink
            to={item.link}
            key={item.link}
            end
            className={({ isActive }) => {
              return isActive
                ? `border-primary-400 text-success-800 whitespace-nowrap py-2 ${index === 0 ? "pl-2" : "pl-5"} pr-5 border-b-2 font-medium text-md`
                : `border-transparent text-success-800 hover:text-gray-700 hover:border-primary-400 whitespace-nowrap py-2 ${index === 0 ? "pl-2" : "pl-5"} pr-5 border-b-2 font-medium text-md`;
            }}
          >
            {item.title}
          </NavLink>))}
        </nav>
      </div>
    </div>
  );
}

LinkTab.propTypes = {
  items: PropTypes.array.isRequired
};

export default LinkTab;
