/* eslint-disable react/prop-types */
/* eslint-disable no-magic-numbers */

import React, { useState, useEffect } from "react";
import ButtonPrimary from "../button/ButtonPrimary";
import { generateClassName } from "../../services/util";
import { validateReason } from "../../services/validation";
import notify from "../../services/toast";

const GroupRejectModal = ({
  open,
  onClose,
  onSave,
}) => {
  const [reason, setReason] = useState("");

  const [validReason, setValidReason] = useState(false);

  useEffect(() => {
    const result = validateReason(reason);
    setValidReason(result);
  }, [reason]);

  const handleSave = () => {
    if (!validReason) {
      notify("Reason field is invalid. Please check before submitting.", "error");
      return;
    }

    onSave(reason);
  };

  return (
    <div className={`${open ? "block" : "hidden"} relative z-10`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-gray-100 bg-opacity-60 transition-opacity"></div>

      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <h2 className="text-lg font-bold ">Reject Reason</h2>
            <div className="w-full mt-5">
              <div className="w-full flex justify-between items-center">
                <label htmlFor="reason" className="block text-sm font-medium text-gray-700">
                  Reason - Shared with Member
                </label>
              </div>
              <div className="mt-1">
                <textarea
                  rows="8"
                  onChange={(event) => setReason(event.target.value)}
                  value={reason}
                  name="reason"
                  id="reason"
                  className={generateClassName(!validReason && reason)}
                ></textarea>
              </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <div>
                <button
                  onClick={onClose}
                  type="button"
                  className="ml-3 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                >
                  Close
                </button>
              </div>
              <div>
                <ButtonPrimary action={handleSave} loading={false} size="md" text="Save" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupRejectModal;
