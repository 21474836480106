/*eslint-disable*/
import makeAPIRequest from "../../services/makeRequest";
import config from "../../config";

async function updateApplication(accessToken, body, applicationId) {
 
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/admin/scholarshipapp/${applicationId}`;
  const response = await makeAPIRequest(API_ENDPOINT, "POST", body, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default updateApplication;
