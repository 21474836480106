/* eslint-disable capitalized-comments */
import { Outlet } from "react-router-dom";
import Title from "../../components/title/Title";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import LinkTab from "../../components/link_tab/LinkTab";

function CommunityManagers() {
  return (
    <>
      <Title name="Community Managers" />
      <Breadcrumb routes={["Dashboard", "Community Managers"]} />
      <LinkTab
        items={[
          {
            link: "/dashboard/community-managers",
            title: "COhatch Members",
          },
          {
            link: "/dashboard/community-managers/referrals",
            title: "Referrals",
          },
          {
            link: "/dashboard/community-managers/create-member",
            title: "Send Invite",
          },
          {
            link: "/dashboard/community-managers/member-plan",
            title: "Change Plan",
          },
          // {
          //   link: "/dashboard/community-managers/bookings",
          //   title: "Bookings",
          // },
        ]}
      />

      <div className="mt-3">
        <Outlet />
      </div>
    </>
  );
}

export default CommunityManagers;
