import { Outlet } from "react-router-dom";
import Title from "../../components/title/Title";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import LinkTab from "../../components/link_tab/LinkTab";

function Administrator() {
  return (
    <>
      <Title name="Administrator" />
      <Breadcrumb routes={["Dashboard", "Administrator"]} />
      <LinkTab
        items={[{
          link: "/dashboard/administrator",
          title: "All Users",
        }, {
          link: "/dashboard/administrator/roles",
          title: "Roles",
        }, {
          link: "/dashboard/administrator/referral-codes",
          title: "Referral Codes",
        }]}
      />

      <div className="mt-3">
        <Outlet />
      </div>
    </>
  );
}

export default Administrator;
