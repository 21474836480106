
/* eslint-disable  */

import React, { useState, useEffect, useCallback } from "react";
import { format } from "date-fns";

import List from "../../../components/list/List";
import ButtonDanger from "../../../components/button/ButtonDanger";
import ButtonPrimary from "../../../components/button/ButtonPrimary";
import GroupRejectModal from "../../../components/group_reject_modal/GroupRejectModal";
import useUserContext from "../../../hooks/useUserContext";
import { debounce } from "../../../services/util";
import { MAGIC_NUMBERS } from "../../../constant";
import notify from "../../../services/toast";

//import FeaturesModal from "../../components/property_features/PropertyFeaturesModal";
import getImpactEvents from "./getImpactEvents";
import updateImpactEvent from "./udateImpactEvent";



function Volunteer() {
  const { userState } = useUserContext();
  const [events, setEvents] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [isEventsLoading, setIsEventsLoading] = useState(true);
  const [isUpdatingEvent, setIsUpdatingEvent] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [pagination, setPagination] = React.useState({
     total: 0, 
     page: 0, 
     remaining: 0, 
     limit: 11
    });
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const colors = [
    {
      iconForeground: "text-teal-700",
      iconBackground: "bg-teal-50",
    },
    {
      iconForeground: "text-purple-700",
      iconBackground: "bg-purple-50",
    },
    {
      iconForeground: "text-sky-700",
      iconBackground: "bg-sky-50",
    },
    {
      iconForeground: "text-yellow-700",
      iconBackground: "bg-yellow-50",
    },
    {
      iconForeground: "text-rose-700",
      iconBackground: "bg-rose-50",
    },
    {
      iconForeground: "text-indigo-700",
      iconBackground: "bg-indigo-50",
    },
    {
      iconForeground: "text-teal-700",
      iconBackground: "bg-teal-50",
    },
    {
      iconForeground: "text-purple-700",
      iconBackground: "bg-purple-50",
    },
    {
      iconForeground: "text-sky-700",
      iconBackground: "bg-sky-50",
    },
    {
      iconForeground: "text-yellow-700",
      iconBackground: "bg-yellow-50",
    },
    {
      iconForeground: "text-rose-700",
      iconBackground: "bg-rose-50",
    },
    {
      iconForeground: "text-indigo-700",
      iconBackground: "bg-indigo-50",
    },
  ];
// setPagination({ limit, page: page, remaining, total });
// 				setEvents(results);

  const fetchEvents = async (accessToken, offset, limit) => {
		try {
			setIsEventsLoading(true);
			const response = await getImpactEvents(
				{
					offset,
					limit,
				},
				accessToken
			);
			if (response.status === "success") {
				const { limit, page, remaining, total, results } = response.data;
				setPagination({ limit, page: page, remaining, total });
				setEvents(results);
			} else if (response.status === "fail") {
				let displayMessage = "";
				if (Array.isArray(response.message)) {
					displayMessage = response.message[0].message;
				} else {
					displayMessage = response.message;
					if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
						displayMessage =
							"An error has occurred. Please try again later. If this error persists please contact support";
					}
				}
				notify(displayMessage, "error");
			}
		} catch (error) {
			notify("something went wrong");
		} finally {
			setIsEventsLoading(false);
		}
	};


  
  const handleUpdateStatus = async (newStatus) => {
    // console.log(selectedEvent);
    if (selectedEvent.approvalStatus !== newStatus) {
      setIsUpdatingEvent(true);
      try {
        const body = {
          approvalStatus: newStatus,
          
        };
       
        const response = await updateImpactEvent(selectedEvent.id, body, userState.accessToken);
        if (response.status === "success") {
          fetchEvents(userState.accessToken, offset, limit);
          
          setSelectedEvent({
            ...selectedEvent,
            approvalStatus: newStatus,
          });
          notify("Event status successfully updated", "info");
        } else if (response.status === "fail") {
          let displayMessage = "";
          if (Array.isArray(response.message)) {
            displayMessage = response.message[0].message;
          } else {
            displayMessage = response.message;
            if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
              displayMessage = "An error has occurred. Please try again later. If this error persists please contact support";
            }
          }
          notify(displayMessage, "error");
        }
        setIsUpdatingEvent(false);
      } catch (error) {
        
        setIsUpdatingEvent(false);
      }
      if (showRejectModal) {
        setShowRejectModal(false);
      }
    }
  };

  const handleSearch = (event) => {
    // console.log("search needs to be fixed");
    // const query = event.target.value;
    // fetchEvents(userState.accessToken, 0, query);
    // setKeyword(query);
  };

  const debouncedHandleSearch = useCallback(debounce(handleSearch, 500), []);

 

  const handleRowClick = (item) => {
    setSelectedEvent(item);
    setSelectedEvents([]);
  };

  useEffect(() => {
   fetchEvents(userState.accessToken, 0, pagination.limit);
  }, []);

  return (
    <>

    
      {!selectedEvent && (
        <>
         
          <List
            isLoading={isEventsLoading}
            hideCheckbox={true}
            columns={[
              {
                name: "creator",
                width: "w-60",
                value: (event) => (
                  <div className="py-2 capitalize">
                    {event.eventOwnerFirstName ? event.eventOwnerFirstName : "NA"} {event.eventOwnerLastName ? event.eventOwnerLastName : "NA"}
                  </div>
                ),
                type: "render",
              },
              {
                name: "type",
                width: "w-40",
                value: (event) => <div className="w-40 bg-success-800 text-white text-center rounded-md p-2">
                  {event.eventCategory ?  event.eventCategory: "NA"}
                  </div>,
                type: "render",
              },
              {
                name: "name",
                value: (event) => <div className="p-2 uppercase">
                  {event.eventName}
                  </div>,
                type: "render",
              },
              {
                name: "Date",
                width: "w-20",
                value: (event) => <div className="py-2">
                  {format(new Date(event.createdAt), "MMM dd")}
                  </div>,
                type: "render",
              },
              {
                name: "status",
                width: "w-6",
                value: (event) => (
                  <div className="py-3">
                    <div className={`w-3 h-3 rounded-md ${event.approvalStatus === "approved" ? "bg-primary-400" : event.approvalStatus === "rejected" ? "bg-red-600" : "bg-yellow-400"}`}></div>
                    
                  </div>
                ),
                type: "render",
              },
            ]}
            data={events}
            selectedItems={selectedEvents}
            setSelectedItems={setSelectedEvents}
            onRowClick={handleRowClick}
            keyItem="_id"
          />

        </>
      )}
      {selectedEvent && (
        <div className="mt-8">
          <div className="flex justify-between bg-white mb-3 py-2 items-center shadow ring-1 ring-black ring-opacity-5">
            
            <div className="flex items-center" onClick={() => setSelectedEvent(null)}>
              <div className="flex cursor-pointer px-3">
                <span className="material-icons-outlined text-md">chevron_left</span>
              </div>
              <div>
                <div className="text-success-800 text-sm capitalize">
                  {/* {selectedEvent.creator?.firstName} {selectedEvent.creator?.lastName} */}
                  {`Current Status: ${selectedEvent.approvalStatus}`}
                  
                </div>
                <div className="text-gray-400 text-sm">
                  {/* {selectedEvent.creator?.emailAddress} */}
                  
                </div>
              </div>
            </div>

            <div className="flex px-3">
              {selectedEvent.approvalStatus !== "approved" && (
                <div className="text-right w-28 mr-3">
                  <ButtonPrimary text="Approve" loading={isUpdatingEvent} action={() => handleUpdateStatus("approved")} />
                </div>
              )}
              {selectedEvent.approvalStatus !== "rejected" && <ButtonDanger text="Reject" loading={isUpdatingEvent} action={() => handleUpdateStatus("rejected")} />}
            </div>

          </div>

          <div className="flex px-3">
            {/* Details from Event */}
            
          {/* Feature section with screenshot */}
         
            <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
              <div className="mt-12 -mb-10 sm:-mb-24 lg:-mb-80">
                <img
                  className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
                  src= "{Events}"
                  alt=""
                />
              </div>
            </div>
         
          {/* Feature section with grid */}
          <div className="relative bg-white py-16 sm:py-24 lg:py-32">
            <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {/* COhatch Columbus Member Appreciation at COhatch Dublin */}
              {selectedEvent.eventName}
              </p>
              <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
              {/* Join us for snack, beer tokens, cash bar, and family friendly fun! All COhatch Columbus members welcome. */}
              {selectedEvent.eventDescription}
              </p>
              <div className="mt-12">
                <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                <div className="pt-6">
          <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
            <div className="-mt-6">
              <div>
                <span className="inline-flex items-center justify-center rounded-xl bg-success-800 p-3 shadow-lg">
                  
                  <svg className="h-8 w-8 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
                  </svg>
                </span>
              </div>
              <h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">Event Date & Time</h3>
              <p className="mt-5 text-base leading-7 text-gray-600">
                {format(new Date(selectedEvent.eventDate), "PPPPpppp")}
                {/* Formatting option found here: https://date-fns.org/v2.29.3/docs/format */}
              </p>
            </div>
          </div>
        </div>

        <div className="pt-6">
          <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
            <div className="-mt-6">
              <div>
                <span className="inline-flex items-center justify-center rounded-xl bg-success-800 p-3 shadow-lg">
                  
                  <svg className="h-8 w-8 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                  </svg>
                </span>
              </div>
              <h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">Event Address</h3>
              <p className="mt-5 text-base leading-7 text-gray-600">
                {/* COhatch Dublin 25 North Street Dublin, OH 43017 */}
                {selectedEvent.eventLocation.streetAddress} <br />
                {selectedEvent.eventLocation.city}, {selectedEvent.eventLocation.state} {selectedEvent.eventLocation.zip}
              </p>
            </div>
          </div>
        </div>

        <div className="pt-6">
          <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
            <div className="-mt-6">
              <div>
                <span className="inline-flex items-center justify-center rounded-xl bg-success-800 p-3 shadow-lg">
                  <svg className="h-8 w-8 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12c0-1.232.046-2.453.138-3.662a4.006 4.006 0 013.7-3.7 48.678 48.678 0 017.324 0 4.006 4.006 0 013.7 3.7c.017.22.032.441.046.662M4.5 12l-3-3m3 3l3-3m12 3c0 1.232-.046 2.453-.138 3.662a4.006 4.006 0 01-3.7 3.7 48.657 48.657 0 01-7.324 0 4.006 4.006 0 01-3.7-3.7c-.017-.22-.032-.441-.046-.662M19.5 12l-3 3m3-3l3 3" />
                  </svg>
                </span>
              </div>
              <h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">RSVP</h3>
              <p className="mt-5 text-base leading-7 text-gray-600 pb-2">*This event is for COhatch members only.</p>
            </div>
            
          </div>
        </div>
                </div>
              </div>
            </div>
          </div>

          {/* Testimonial section */}
          {/* <div className="bg-gradient-to-r from-success-800 to-primary-400 pb-16 lg:relative lg:z-10 lg:pb-0">
            <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-8 lg:px-8">
              <div className="relative lg:-my-8">
                <div aria-hidden="true" className="absolute inset-x-0 top-0 h-1/2 bg-white lg:hidden" />
                <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:h-full lg:p-0">
                  <div className="aspect-w-10 aspect-h-6 overflow-hidden rounded-xl shadow-xl sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full">
                    <img
                      className="object-cover lg:h-full lg:w-full"
                      src=""
                      alt=""
                    />
                  </div>
                </div>
              </div>
              
              <div className="mt-12 lg:col-span-2 lg:m-0 lg:pl-8">
                <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 lg:py-20">
                  <blockquote>
                    <div>
                      <p className="mt-6 text-2xl font-medium text-white">
                       About the Event Organiser
                      </p>
                    </div>
                    <footer className="mt-6">
                      <p className="text-base font-medium text-white">COhatch</p>
                      <p className="text-base font-medium text-cyan-100">COhatch is community town hall 2.0, a space where individuals, start-ups, small businesses, large corporations, and non-profits thrive TOGETHER—in the communities in which they live and love. We are in the business of creating environments where people and communities connect with their neighbors, clients, coworkers, and families. COhatch is designed to be a place to Work, Meet, and Live, and our vision is to replicate this "whole-life" approach to hundreds of local communities, impacting the world one person and community at a time!</p>
                    </footer>
                  </blockquote>
                </div>
              </div>

            </div>
          </div> */}

          {/* Blog section */}
          {/* <div className="relative bg-gray-50 py-16 sm:py-24 lg:py-32">
            <div className="relative">
              <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                <h2 className="text-lg font-semibold text-cyan-600">Learn</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Helpful Resources</p>
                
                <div className="mt-8 flex justify-center space-x-6">
      
                <div className="flex space-x-6">
                  {footerNavigation.social.map((item) => (
                    <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
                      <span className="sr-only">{item.name}</span>
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </a>
                  ))}
                </div>
      

      
    </div>
              </div>
              
            </div>
          </div> */}
          </div>
          
      </div>
  
  )}
   
      {showRejectModal && (
        <GroupRejectModal
          open={true}
          onClose={() => {
            setShowRejectModal(false);
          }}
          onSave={(reason) => handleUpdateStatus("rejected", reason)}
        />
      )}
     
     
    
    </>
  );
}

export default Volunteer;




