export const validateName = userName => {
  const regex = /^[A-Za-z]{2,64}$/gi;
  if (!userName) {
    return false;
  } else {
    return regex.test(userName);
  }
};

export const validatePassword = userPassword => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,24}$/;
  if (!userPassword) {
    return false;
  } else {
    return regex.test(userPassword);
  }
};

export const validateEmail = userEmail => {
  const regex = /^[a-zA-Z0-9.!#$%&*+/=?^_{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/gi;
  if (!userEmail) {
    return false;
  } else {
    return regex.test(userEmail);
  }
};

export const validateReason = reason => {
  if (!reason) {
    return false;
  } else {
    return reason.length <= 1500;
  }
};

export const validateCode = code => {
  if (!code) {
    return false;
  } else {
    return true;
  }
};
