/* eslint-disable */
import config from "../../config";
import makeAPIRequest from "../../services/makeRequest";

const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/admin/impactstory`;

async function createImpactStory(payload, accessToken) {
	console.log(payload, accessToken, "creating..");
	const response = await makeAPIRequest(
		API_ENDPOINT,
		"POST",
		payload,
		accessToken
	);
	const jsonResponse = await response.json();
	return jsonResponse;
}

export default createImpactStory;
