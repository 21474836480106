/* eslint-disable no-unused-vars */
/* eslint-disable id-blacklist */
/* eslint-disable no-console */
import React, { useState, useEffect, useRef, useCallback } from "react";
import Select from "react-select";
import Table from "../../components/table/Table";
import Pagination from "../../components/pagination/Pagination";
import Prompt from "../../components/prompt/Prompt";
import ButtonDanger from "../../components/button/ButtonDanger";
import ButtonPrimary from "../../components/button/ButtonPrimary";
import EditReferralCodeModal from "../../components/edit_referral_code_modal/EditReferralCodeModal";
import useUserContext from "../../hooks/useUserContext";
import { showPrompt, generateClassName } from "../../services/util";
import { validateCode } from "../../services/validation";
import { MAGIC_NUMBERS, LOCATIONS } from "../../constant";
import notify from "../../services/toast";
import getReferralCodes from "./getReferralCodes";
import deleteReferralCode from "./deleteReferralCode";
import createReferralCode from "./createReferralCode";
import updateReferralCode from "./updateReferralCode";

function AdministratorReferralCodes() {
	const { userState } = useUserContext();
	const [referralCodes, setReferralCodes] = useState([]);
	const [isReferralCodesLoading, setIsReferralCodesLoading] = useState(true);
	const [pagination, setPagination] = useState({
		limit: 10,
		total: 0,
		page: 0,
		remaining: 0,
	});
	const [displayPromptMessage, setDisplayPromptMessage] = useState({
		heading: "",
		body: "",
	});
	const [selectedReferralCode, setSelectedReferralCode] = useState(null);

	const [newCode, setNewCode] = useState("");
	const [newLocation, setNewLocation] = useState("");

	const [newCodeValid, setNewCodeValid] = useState(false);

	const [isCreatingReferralCode, setIsCreatingReferralCode] = useState(false);
	const [isUpdatingReferralCode, setIsUpdatingReferralCode] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);

	const deletePromptRef = useRef(null);
	const locationSelectRef = useRef(null);

	useEffect(() => {
		const result = validateCode(newCode);
		setNewCodeValid(result);
	}, [newCode]);

	const fetchReferralCodes = async (accessToken, page) => {
		setIsReferralCodesLoading(true);
		const response = await getReferralCodes(
			{
				page,
				limit: pagination.limit,
			},
			accessToken
		);
		if (response.status === "success") {
			const { limit, page, remaining, total, results } = response.data;
			setPagination({ limit, page: page, remaining, total });
			setReferralCodes(results);
		} else if (response.status === "fail") {
			let displayMessage = "";
			if (Array.isArray(response.message)) {
				displayMessage = response.message[0].message;
			} else {
				displayMessage = response.message;
				if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
					displayMessage =
						"An error has occurred. Please try again later. If this error persists please contact support";
				}
			}
			notify(displayMessage, "error");
		}
		setIsReferralCodesLoading(false);
	};

	const handleCreate = async (event) => {
		event.preventDefault();
		setIsCreatingReferralCode(true);
		try {
			if (!newCode || !newLocation) {
				notify("One or more fields are invalid. Check and try again", "error");
				return;
			}
			const payload = {
				code: newCode,
				location: newLocation,
			};
			const response = await createReferralCode(payload, userState.accessToken);
			if (response.status === "success") {
				setNewCode("");
				setNewLocation("");
				locationSelectRef.current.clearValue();
				fetchReferralCodes(userState.accessToken, 0);
				notify("Referral code created successfully", "info");
			} else if (response.status === "fail") {
				let displayMessage = "";
				if (Array.isArray(response.message)) {
					displayMessage = response.message[0].message;
				} else {
					displayMessage = response.message;
					if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
						displayMessage =
							"An error has occurred. Please try again later. If this error persists please contact support";
					}
				}
				notify(displayMessage, "error");
			}
		} catch (error) {
			notify("Something went wrong. Please try again later", "error");
		} finally {
			setIsCreatingReferralCode(false);
		}
	};

	const handlePrevFetch = async () => {
		await fetchReferralCodes(userState.accessToken, pagination.page - 1);
	};

	const handleNextFetch = async () => {
		await fetchReferralCodes(userState.accessToken, pagination.page + 1);
	};

	const handleDeletePrompt = (referralCode) => {
		showPrompt(
			"Delete referral code",
			`Are you sure you want to delete "${referralCode.code}"? This action can't be undone.`,
			deletePromptRef,
			"open",
			setDisplayPromptMessage
		);
		setSelectedReferralCode(referralCode);
	};

	const handleDeleteReferralCode = async () => {
		const response = await deleteReferralCode(
			selectedReferralCode._id,
			userState.accessToken
		);
		if (response.status === "success") {
			notify("Referral code successfully deleted", "info");
			deletePromptRef.current.closePrompt();
			fetchReferralCodes(userState.accessToken, 0);
		} else if (response.status === "fail") {
			let displayMessage = "";
			if (Array.isArray(response.message)) {
				displayMessage = response.message[0].message;
			} else {
				displayMessage = response.message;
				if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
					displayMessage =
						"An error has occurred. Please try again later. If this error persists please contact support";
				}
			}
			notify(displayMessage, "error");
		}
	};

	const handleOpenEditModal = (referralCode) => {
		setSelectedReferralCode(referralCode);
		setShowEditModal(true);
	};

	const handleSave = async (payload) => {
		setIsUpdatingReferralCode(true);
		try {
			const response = await updateReferralCode(
				selectedReferralCode._id,
				payload,
				userState.accessToken
			);
			if (response.status === "success") {
				fetchReferralCodes(userState.accessToken, pagination.page);
				setShowEditModal(false);
				notify("Referral code updated successfully", "info");
			} else if (response.status === "fail") {
				let displayMessage = "";
				if (Array.isArray(response.message)) {
					displayMessage = response.message[0].message;
				} else {
					displayMessage = response.message;
					if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
						displayMessage =
							"An error has occurred. Please try again later. If this error persists please contact support";
					}
				}
				notify(displayMessage, "error");
			}
		} catch (error) {
			notify("Something went wrong. Please try again later", "error");
		} finally {
			setIsUpdatingReferralCode(false);
		}
	};

	useEffect(() => {
		fetchReferralCodes(userState.accessToken, 0);
	}, []);
	console.log("new location");
	return (
		<>
			<h2 className="text-md text-gray-400 font-bold mt-5">
				Create a Referral Code
			</h2>
			<form className="mt-2 w-full md:w-4/5 space-y-3">
				<div className="w-full flex gap-5">
					<div className="w-full">
						<div className="mt-1">
							<input
								onChange={(event) => setNewCode(event.target.value)}
								type="text"
								name="code"
								id="code"
								value={newCode}
								autoComplete="off"
								className={generateClassName(!newCodeValid && newCode)}
								placeholder="Referral Codes"
							/>
						</div>
					</div>
					<div className="w-full">
						<div className="mt-1">
							<Select
								placeholder="Location"
								isDisabled={false}
								isClearable={true}
								isLoading={false}
								isRtl={false}
								isSearchable={false}
								name="location"
								options={LOCATIONS}
								onChange={(event) => setNewLocation(event ? event.value : "")}
								ref={locationSelectRef}
								theme={(theme) => ({
									...theme,
									colors: {
										...theme.colors,
										primary25: "#ecf8ef",
										primary: "#56c271",
									},
								})}
							/>
						</div>
					</div>
				</div>
				<div className="text-right">
					<div className="w-32 ml-auto">
						<ButtonPrimary
							text={isCreatingReferralCode ? "Please wait..." : "Create"}
							size="md"
							action={handleCreate}
							loading={
								!newCodeValid ||
								!newLocation ||
								isCreatingReferralCode ||
								isUpdatingReferralCode
									? true
									: false
							}
						/>
					</div>
				</div>
			</form>
			<Table
				isLoading={isReferralCodesLoading}
				columns={[
					{
						name: "Location",
						value: (rc) => {
							const selectedLocation = LOCATIONS.find(
								(loc) => loc.value === rc.location
							);
							return (
								<div>{selectedLocation ? selectedLocation.label : "-"}</div>
							);
						},
						type: "render",
					},
					{
						name: "Referral Code",
						value: "code",
					},
					{
						name: "Date",
						value: "createdAt",
					},
					{
						name: "Action",
						value: (rc) => (
							<div className="flex justify-between space-x-2">
								<ButtonDanger
									text="Delete"
									action={() => handleDeletePrompt(rc)}
								/>
								<ButtonPrimary
									text="Update"
									action={() => handleOpenEditModal(rc)}
								/>
							</div>
						),
						type: "render",
					},
				]}
				data={referralCodes}
			/>
			<Pagination
				pagination={pagination}
				next={handleNextFetch}
				prev={handlePrevFetch}
			/>
			<Prompt
				header={displayPromptMessage.heading}
				body={displayPromptMessage.body}
				ref={deletePromptRef}
				action={handleDeleteReferralCode}
			/>
			{showEditModal && (
				<EditReferralCodeModal
					open={showEditModal}
					referralCode={selectedReferralCode}
					onClose={() => {
						setSelectedReferralCode(null);
						setShowEditModal(false);
					}}
					onSave={handleSave}
				/>
			)}
		</>
	);
}

export default AdministratorReferralCodes;
