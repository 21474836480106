/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { capitalize } from "lodash";

function Table({ columns, data, isLoading }) {
  // eslint-disable-next-line no-console
  const newData = data.map(user => {
    if (user.officerndId) {
      if (!user.location) {
        user["status"] = "INACTIVE";
      }
    } else {
      if (!user.state && !user.city) {
        user["status"] = "INACTIVE";
      }
    }

    return user;
  });
  return (
    <div className="mt-8 flex flex-col">
      <div className="overflow-x-auto">
        <div className="inline-block min-w-full py-2 align-middle md:px-1 lg:px-1">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  {columns.map((column, index) => (
                    <th
                      scope="col"
                      key={column.value}
                      className={index === columns.length - 1 ? "relative py-3.5 pl-3 pr-4 sm:pr-6" : "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"}>
                      <span className={index === columns.length - 1 ? "sr-only" : ""}>{column.name}</span>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {isLoading && (
                  <tr>
                    <td colSpan={columns.length} className="text-center p-3">
                      <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status"></div>
                      <span className="block text-gray-500 text-sm capitalize italic">Loading...</span>
                    </td>
                  </tr>
                )}
                {!isLoading &&
                  newData.map((item, index) => (
                    <tr key={`row-${index}`}>
                      {columns.map((column, jndex) => (
                        <td
                          key={`column-${index}-${jndex}`}
                          className={
                            jndex === columns.length - 1
                              ? "relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6"
                              : "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                          }>
                          {column.type === "render" && column.value(item)}
                          {column.type !== "render" && (item[column.value] ? item[column.value] : "-")}
                        </td>
                      ))}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default Table;
