/* eslint-disable capitalized-comments */
import config from "../../config";
import makeAPIRequest from "../../services/makeRequest";

const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/admin/impactstory`;

async function updateStory(storyId, body, accessToken) {
  const response = await makeAPIRequest(`${API_ENDPOINT}/${storyId}`, "PUT", body, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default updateStory;


