/* eslint-disable capitalized-comments */
import config from "../../../config";
import makeAPIRequest from "../../../services/makeRequest";

const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/admin/impactevent`;

async function updateEvent(eventId, payload, accessToken) {
  const response = await makeAPIRequest(`${API_ENDPOINT}/${eventId}`, "PUT", payload, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default updateEvent;


