import makeAPIRequest from "../../../services/makeRequest";
import config from "../../../config";

async function deleteApplication(appId, accessToken) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/admin/scholarshipapp/${appId}`;

  const response = await makeAPIRequest(API_ENDPOINT, "DELETE", null, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default deleteApplication;
