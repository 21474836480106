/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { format } from "date-fns";
import React from "react";
import { Link } from "react-router-dom";
import useUserContext from "../../hooks/useUserContext";
import notify from "../../services/toast";
import feature from "./feature";

function CouponCard({ coupon }) {
  const { userState } = useUserContext();
  const background = coupon?.background?.large ? coupon.background?.large.url : null;
  const logo = coupon?.listing?.businessPhotos?.logo?.small ? coupon.listing.businessPhotos.logo.small.url : null;
  const businessName = coupon?.listing?.businessName;
  const category = coupon?.listing?.businessCategory;
  const subCategory = coupon?.listing?.businessSubCategory;
  const address = coupon?.listing?.businessAddress ? coupon.listing?.businessAddress.address : null;
  const firstName = coupon?.creator?.firstName ? coupon.creator.firstName : null;
  const lastName = coupon?.creator?.lastName ? coupon.creator.lastName : null;
  const email = coupon?.creator?.emailAddress ? coupon.creator.emailAddress : null;

  const couponName = coupon?.couponName;
  const couponCode = coupon?.couponCode;
  const useAge = coupon?.useAge;
  const expiration = coupon?.expiration;

  const [featured, setFeatured] = React.useState(() => (coupon?.featured ? true : false));
  const [featuring, setFeaturing] = React.useState(false);

  const handleFeature = async () => {
    try {
      setFeaturing(true);
      const payload = {
        couponId: coupon._id,
        update: { featured: featured === true ? false : true },
      };
      const response = await feature(userState.accessToken, payload);
      if (response.status === "success") {
        setFeatured(response.data.featured);
        notify("Coupon featured status updated successfully", "success");
      } else {
        notify("Coupon featured status was not updated successfully", "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setFeaturing(false);
    }
  };

  return (
    <div className="w-[500px] divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
      <div className="relative">
        <img src={background ? background : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_cover_1.png"} alt="" className="w-full h-[200px] object-cover" />

        <div className="absolute top-[120px] left-[10px] w-full h-[200px] sm:w-[30%] sm:h-[200px]">
          <Link to={`/marketplace/listing/${coupon?.businessListing}`}>
            <img src={logo ? logo : "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_logo_1.png"} alt="" className="w-[100px] h-[100px] rounded-full object-cover" />
          </Link>
        </div>

        <button
          type="button"
          disabled={featuring ? true : false}
          onClick={handleFeature}
          className="absolute top-[150px] gap-2 right-[10px] inline-flex items-center rounded-md border border-transparent bg-primary-400 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
          {featured ? "Featured" : "Feature"}
          {featured ? <span className="material-icons-outlined">favorite</span> : <span className="material-icons-outlined">favorite_border</span>}
        </button>
      </div>
      <div className="px-4 py-5 sm:p-6">
        <div className="mx-auto mt-6 max-w-5xl px-4 sm:px-6 lg:px-8">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Business name</dt>
              <dd className="mt-1 text-sm text-gray-900 uppercase">{businessName}</dd>
            </div>

            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Category</dt>
              <dd className="mt-1 text-sm text-gray-900 uppercase">{category}</dd>
            </div>

            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Sub category</dt>
              <dd className="mt-1 text-sm text-gray-900 uppercase">{subCategory}</dd>
            </div>

            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Creator</dt>
              <dd className="mt-1 text-sm text-gray-900 uppercase">
                {firstName} {lastName}
              </dd>
            </div>

            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">Email</dt>
              <dd className="mt-1 text-sm text-gray-900 uppercase">{email}</dd>
            </div>

            {address ? (
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">Location</dt>
                <dd className="mt-1 text-sm text-gray-900">{address}</dd>
              </div>
            ) : null}

            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">Coupon name</dt>
              <dd className="mt-1 text-sm text-gray-900 uppercase">{couponName}</dd>
            </div>

            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">Coupon code</dt>
              <dd className="mt-1 text-sm text-gray-900 uppercase">{couponCode}</dd>
            </div>

            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">Coupon expiration</dt>
              <dd className="mt-1 text-sm text-gray-900 uppercase">{format(new Date(expiration), "MMMM do YYY")}</dd>
            </div>

            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">How to</dt>
              <dd className="mt-1 max-w-prose space-y-5 text-sm text-gray-900">
                <p>{useAge}</p>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}

export default CouponCard;
