/* eslint-disable id-blacklist */
/* eslint-disable no-console */

import React, { useState, useEffect, useCallback } from "react";
import { format } from "date-fns";
import Pagination from "../../components/pagination/Pagination";
import List from "../../components/list/List";
import ButtonDanger from "../../components/button/ButtonDanger";
import ButtonPrimary from "../../components/button/ButtonPrimary";
import GroupRejectModal from "../../components/group_reject_modal/GroupRejectModal";
import useUserContext from "../../hooks/useUserContext";
import { debounce } from "../../services/util";
import { MAGIC_NUMBERS } from "../../constant";
import notify from "../../services/toast";
import getGroups from "./getGroups";
import updateGroup from "./updateGroup";

function TechnicalSupportGroups() {
	const { userState } = useUserContext();
	const [groups, setGroups] = useState([]);
	const [keyword, setKeyword] = useState("");
	const [isGroupsLoading, setIsGroupsLoading] = useState(true);
	const [isUpdatingGroup, setIsUpdatingGroup] = useState(false);
	const [showRejectModal, setShowRejectModal] = useState(false);
	const [pagination, setPagination] = React.useState({
		total: 0,
		page: 0,
		remaining: 0,
		limit: 11,
	});
	const [selectedGroups, setSelectedGroups] = useState([]);
	const [selectedGroup, setSelectedGroup] = useState(null);

	const fetchGroups = async (accessToken, page, _keyword) => {
		setIsGroupsLoading(true);
		const response = await getGroups(
			{
				page,
				keyword: _keyword,
				limit: pagination.limit,
			},
			accessToken
		);
		if (response.status === "success") {
			const { limit, page, remaining, total, results } = response.data;

			setPagination((prevState) => {
				return { ...prevState, limit, page: page, remaining, total };
			});
			setGroups(results);
		} else if (response.status === "fail") {
			let displayMessage = "";
			if (Array.isArray(response.message)) {
				displayMessage = response.message[0].message;
			} else {
				displayMessage = response.message;
				if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
					displayMessage =
						"An error has occurred. Please try again later. If this error persists please contact support";
				}
			}
			notify(displayMessage, "error");
		}
		setIsGroupsLoading(false);
	};

	const handleUpdateStatus = async (newStatus, reason) => {
		if (selectedGroup.status !== newStatus) {
			setIsUpdatingGroup(true);
			try {
				const body = {
					status: newStatus,
				};
				if (reason) {
					body.rejectReason = reason;
				}
				const response = await updateGroup(
					selectedGroup._id,
					body,
					userState.accessToken
				);
				if (response.status === "success") {
					fetchGroups(userState.accessToken, pagination.page, keyword);
					notify("Group status successfully updated", "info");
					setSelectedGroup({
						...selectedGroup,
						status: newStatus,
					});
				} else if (response.status === "fail") {
					let displayMessage = "";
					if (Array.isArray(response.message)) {
						displayMessage = response.message[0].message;
					} else {
						displayMessage = response.message;
						if (
							MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)
						) {
							displayMessage =
								"An error has occurred. Please try again later. If this error persists please contact support";
						}
					}
					notify(displayMessage, "error");
				}
				setIsUpdatingGroup(false);
			} catch (error) {
				notify("Something went wrong. Please try again later", "error");
				setIsUpdatingGroup(false);
			}
			if (showRejectModal) {
				setShowRejectModal(false);
			}
		}
	};

	const handleSearch = (event) => {
		const query = event.target.value;
		fetchGroups(userState.accessToken, 0, query);
		setKeyword(query);
	};

	const debouncedHandleSearch = useCallback(debounce(handleSearch, 500), []);

	const handlePrevFetch = async () => {
		await fetchGroups(userState.accessToken, pagination.page - 1, keyword);
	};

	const handleNextFetch = async () => {
		await fetchGroups(userState.accessToken, pagination.page + 1, keyword);
	};

	const handleRowClick = (item) => {
		setSelectedGroup(item);
		setSelectedGroups([]);
	};

	useEffect(() => {
		fetchGroups(userState.accessToken, 0, "");
	}, []);

	return (
		<>
			{!selectedGroup && (
				<>
					<div className="w-full flex flex-col sm:flex-row justify-between">
						<input
							type="text"
							name="search"
							onInput={debouncedHandleSearch}
							placeholder="Search groups"
							className="block border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 max-full sm:max-w-md sm:text-base"
						/>
					</div>
					<List
						isLoading={isGroupsLoading}
						hideCheckbox={true}
						columns={[
							{
								name: "creator",
								width: "w-72",
								value: (group) => (
									<div className="py-2">
										{group.creator.firstName} {group.creator.lastName}
									</div>
								),
								type: "render",
								title: "Group Owner",
							},
							{
								name: "type",
								width: "w-60",
								value: (group) => (
									<div className="w-full bg-success-800 text-white text-center rounded-md py-2">
										{group.type}
									</div>
								),
								type: "render",
								title: "Public/Private",
							},
							{
								name: "name",
								value: (group) => (
									<div className="p-2 uppercase">{group.name}</div>
								),
								type: "render",
								title: "Group Name",
							},
							{
								name: "Date",
								width: "w-32",
								value: (group) => (
									<div className="py-2">
										{format(new Date(group.createdAt), "MMM dd")}
									</div>
								),
								type: "render",
								title: "Date Added",
							},
							{
								name: "status",
								width: "w-24",
								value: (group) => (
									<div className="py-3">
										<div
											className={`w-3 h-3 rounded-md ${
												group.status === "ACTIVE"
													? "bg-primary-400"
													: group.status === "REJECTED"
													? "bg-red-600"
													: "bg-yellow-400"
											}`}
										></div>
									</div>
								),
								type: "render",
								title: "Status",
							},
						]}
						data={groups}
						selectedItems={selectedGroups}
						setSelectedItems={setSelectedGroups}
						onRowClick={handleRowClick}
						keyItem="_id"
					/>
					<Pagination
						pagination={pagination}
						next={handleNextFetch}
						prev={handlePrevFetch}
					/>
				</>
			)}
			{selectedGroup && (
				<div className="mt-8">
					<div className="flex justify-between bg-white mb-3 py-2 items-center shadow ring-1 ring-black ring-opacity-5">
						<div
							className="flex items-center"
							onClick={() => setSelectedGroup(null)}
						>
							<div className="flex cursor-pointer px-3">
								<span className="material-icons-outlined text-md">
									chevron_left
								</span>
							</div>
							<div>
								<div className="text-success-800 text-sm">
									{selectedGroup.creator.firstName}{" "}
									{selectedGroup.creator.lastName}
								</div>
								<div className="text-gray-400 text-sm">
									{selectedGroup.creator.emailAddress}
								</div>
							</div>
						</div>
						<div className="flex px-3">
							{selectedGroup.status !== "ACTIVE" && (
								<div className="text-right w-28 mr-3">
									<ButtonPrimary
										text="Approve"
										loading={isUpdatingGroup}
										action={() => handleUpdateStatus("ACTIVE", "")}
									/>
								</div>
							)}
							{selectedGroup.status !== "REJECTED" && (
								<ButtonDanger
									text="Reject"
									loading={isUpdatingGroup}
									action={() => setShowRejectModal(true)}
								/>
							)}
						</div>
					</div>
					<div className="flex flex-col w-full sm:w-8/12 mx-auto">
						<div className="relative mx-auto bg-white rounded-t-md mt-3">
							<img
								src={
									selectedGroup && selectedGroup.coverImages?.large
										? selectedGroup.coverImages.large.url
										: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_cover_2.png"
								}
								className="w-full h-full object-cover"
							/>
							<div className="absolute bottom-[10px] left-10 w-[150px] h-[150px] flex items-center justify-center rounded-sm shadow-sm border-solid">
								<img
									src={
										selectedGroup && selectedGroup.logos?.small
											? selectedGroup.logos.small.url
											: "https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/default_logo_1.png"
									}
									className="w-full h-full object-cover"
								/>
							</div>
						</div>
						<div className="mt-5 text-md">Group description</div>
						<div className="text-gray-400 text-sm">
							{selectedGroup.description}
						</div>
						<div className="mt-5 text-md">Group Rules</div>
						<div className="text-gray-400 text-sm">{selectedGroup.rules}</div>
					</div>
				</div>
			)}
			{showRejectModal && (
				<GroupRejectModal
					open={true}
					onClose={() => {
						setShowRejectModal(false);
					}}
					onSave={(reason) => handleUpdateStatus("REJECTED", reason)}
				/>
			)}
		</>
	);
}

export default TechnicalSupportGroups;
