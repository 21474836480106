/* eslint-disable no-unused-vars */
/* eslint-disable  */

import React, { useState, useEffect, useRef, useCallback } from "react";
import Select from "react-select";
import Table from "../../components/table/Table";
import Pagination from "../../components/pagination/Pagination";
import Prompt from "../../components/prompt/Prompt";
import ButtonDanger from "../../components/button/ButtonDanger";
import useUserContext from "../../hooks/useUserContext";
import { debounce, showPrompt } from "../../services/util";
import { MAGIC_NUMBERS, PLANS } from "../../constant";
import notify from "../../services/toast";
import getUsers from "./getUsers";
import deleteUser from "./deleteUser";

const subscriptionMap = {
  "FREE": "Basic",
  "PREMIUM": "Premium",
  "COHATCH": "COhatch Member"
};

function AdministratorUsers() {
  const { userState } = useUserContext();
  const [users, setUsers] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [product, setProduct] = useState("");
  const [isUsersLoading, setIsUsersLoading] = useState(true);
  const [pagination, setPagination] = React.useState({ limit: 10, total: 0, page: 0, remaining: 0 });
  const [displayPromptMessage, setDisplayPromptMessage] = useState({ heading: "", body: "" });
  const [selectedUser, setSelectedUser] = useState(null);
  const productRef = useRef(product);
  productRef.current = product;
  const deletePromptRef = useRef(null);

  const fetchUsers = async (accessToken, page, _keyword, _product) => {
    setIsUsersLoading(true);
    const response = await getUsers(
      {
        page,
        keyword: _keyword,
        product: _product,
        limit: pagination.limit,
      },
      accessToken
    );
    if (response.status === "success") {
      const { limit, page, remaining, total, results } = response.data;
      setPagination({ limit, page: page, remaining, total });
      setUsers(results);
    } else if (response.status === "fail") {
      let displayMessage = "";
      if (Array.isArray(response.message)) {
        displayMessage = response.message[0].message;
      } else {
        displayMessage = response.message;
        if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
          displayMessage = "An error has occurred. Please try again later. If this error persists please contact support";
        }
      }
      notify(displayMessage, "error");
    }
    setIsUsersLoading(false);
  };

  const handleSearch = event => {
    const query = event.target.value;
    fetchUsers(userState.accessToken, 0, query, productRef.current);
    setKeyword(query);
  };

  const debouncedHandleSearch = useCallback(debounce(handleSearch, 500), []);

  const handlePrevFetch = async () => {
    await fetchUsers(userState.accessToken, pagination.page - 1, keyword, product);
  };

  const handleNextFetch = async () => {
    await fetchUsers(userState.accessToken, pagination.page + 1, keyword, product);
  };

  const handleFilterBy = value => {
    fetchUsers(userState.accessToken, 0, keyword, value);
    setProduct(value);
  };

  const handleDeletePrompt = user => {
    showPrompt("Delete User", `Are you sure you want to delete "${user.firstName} ${user.lastName}" user? This action can't be undone.`, deletePromptRef, "open", setDisplayPromptMessage);
    setSelectedUser(user);
  };

  const handleDeleteUser = async () => {
    const response = await deleteUser(selectedUser._id, userState.accessToken);
    if (response.status === "success") {
      notify("User successfully deleted", "info");
      deletePromptRef.current.closePrompt();
      fetchUsers(userState.accessToken, 0, keyword, product);
    } else if (response.status === "fail") {
      let displayMessage = "";
      if (Array.isArray(response.message)) {
        displayMessage = response.message[0].message;
      } else {
        displayMessage = response.message;
        if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
          displayMessage = "An error has occurred. Please try again later. If this error persists please contact support";
        }
      }
      notify(displayMessage, "error");
    }
  };

  useEffect(() => {
    fetchUsers(userState.accessToken, 0, "", "");
  }, []);
console.log(users);
  return (
    <>
      <div className="w-full flex justify-between">
        <input
          type="text"
          name="search"
          onInput={debouncedHandleSearch}
          placeholder="Search Users"
          className="block border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 max-w-md sm:text-base"
        />
        <Select
          className="border-0 w-64"
          placeholder="Filter by..."
          isDisabled={false}
          isClearable={true}
          isLoading={false}
          isRtl={false}
          isSearchable={true}
          name="filter"
          options={Object.keys(PLANS).map(plan => ({
            value: plan,
            label: `${PLANS[plan]} PLAN`,
          }))}
          onChange={event => handleFilterBy(event ? event.value : "")}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "#ecf8ef",
              primary: "#56c271",
            },
          })}
        />
      </div>
      <Table
        isLoading={isUsersLoading}
        columns={[
          {
            name: "First Name",
            value: "firstName",
          },
          {
            name: "Last Name",
            value: "lastName",
          },
          {
            name: "Email address",
            value: "emailAddress",
          },
          {
            name: "Plan",
            value: "subscription",
          },
          {
            name: "Status",
            value: "status",
          },
          {
            name: "Date",
            value: "createdAt",
          },
          {
            name: "Action",
            value: user => <ButtonDanger text="Delete" action={() => handleDeletePrompt(user)} />,
            type: "render",
          },
        ]}
        data={users}
      />
      <Pagination pagination={pagination} next={handleNextFetch} prev={handlePrevFetch} />
      <Prompt header={displayPromptMessage.heading} body={displayPromptMessage.body} ref={deletePromptRef} action={handleDeleteUser} />
    </>
  );
}

export default AdministratorUsers;
