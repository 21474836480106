
/* eslint-disable  */
import React from "react";
import { MAGIC_NUMBERS } from "../../../constant";
import useUserContext from "../../../hooks/useUserContext";
import notify from "../../../services/toast";
import DefaultState from "../../../components/default_state/DefaultState";
import Pagination from "../../../components/pagination/Pagination";
import { debounce } from "../../../services/util";
import { BUSINESS_CATEGORY } from "../../../constant";
import Spinner from "../../../components/spinner/Spinner";
import EventCard from "../../../components/event_card/EventCard";
import getImpactStories from "../../impact_stories/getImpactStories.js";
import StoryCard from "./StoryCard";


function generateCategory() {
  const categories = [];

  for (const category in BUSINESS_CATEGORY) {
    categories.push(BUSINESS_CATEGORY[category]["mainCategory"]);
  }

  return categories;
}

function FeaturedStories() {
  const { userState } = useUserContext();
  const [events, setEvents] = React.useState([]);
  const [keyword, setKeyword] = React.useState("");
  const [isEventsLoading, setIsEventsLoading] = React.useState(true);
  const [pagination, setPagination] = React.useState({
     limit: 30, 
     total: 0, 
     page: 0, 
     remaining: 0
     });

 

  const fetchStories = async (accessToken, offset, limit) => {
    setIsEventsLoading(true);
    const response = await getImpactStories(
      {
      offset,
					limit,
      },
      accessToken
    );
    if (response.status === "success") {
      const { limit, page, remaining, total, results } = response.data;
      setPagination((prevState) => {
        return { ...prevState, limit, page: page, remaining, total };
      });
      console.log(response.data);
      setEvents(results);
    } else if (response.status === "fail") {
      let displayMessage = "";
      if (Array.isArray(response.message)) {
        displayMessage = response.message[0].message;
      } else {
        displayMessage = response.message;
        if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
          displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
        }
      }
      notify(displayMessage, "error");
    }
    setIsEventsLoading(false);
  };

  // const handleSearch = event => {
  //   console.log(event);
  //   //C const query = event.target.value;
  //   //C fetchEvents(userState.accessToken, 0, query);
  //   //C setKeyword(query);
  // };

  // const debouncedHandleSearch = React.useCallback(debounce(handleSearch, 500), []);

  const handlePrevFetch = async () => {
    await fetchStories(userState.accessToken, pagination.page - 1, keyword);
  };

  const handleNextFetch = async () => {
    await fetchStories(userState.accessToken, pagination.page + 1, keyword);
  };

  const handleReload = async () => {
    await fetchStories(userState.accessToken, pagination.page, keyword);
  };

  React.useEffect(() => {
    fetchStories(userState.accessToken, 0, pagination.limit);
  }, []);
 
  return (
    <div className="w-full pt-5">
      {/* <div className="w-full flex justify-between px-5">
        <input
          type="text"
          name="search"
          onInput={debouncedHandleSearch}
          placeholder="Search coupon by business name"
          className="block border w-[50%] border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 max-w-md sm:text-base"
        />
      </div> */}
      {!events.length ? (
        <div className="absolute top-[70%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
          <DefaultState icon="discount" heading="No stories found" description="No stories found in database."/>
        </div>
      ) : (
        <div className="w-full">
          {isEventsLoading ? (
            <Spinner displayText="Loading..." />
          ) : (
            <div className="mt-5 w-full flex gap-4 flex-wrap px-5">
              {events.map((event, index) => (
                <StoryCard key={index} event={event} />
              ))}
            </div>
          )}
        </div>
      )}
      <Pagination pagination={pagination} next={handleNextFetch} prev={handlePrevFetch} />
    </div>
  );
}

export default FeaturedStories;
