/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import html2canvas from "html2canvas";
import JsPDF from "jspdf";
import useUserContext from "../../hooks/useUserContext";
import getAppById from "../scholarship_applications/requests/getAppById";
import { format } from "date-fns";
import { useParams } from "react-router-dom";

function CommunityPartnerAgreementFile({ applicationId }) {
    const [loader, setLoader] = useState(false);
    const { userState: { accessToken } } = useUserContext();
    const params = useParams();

    const [application, setApplication] = useState({});
    const [fetching, setFetching] = useState(false);

    const downloadPDF = () => {
        const capture = document.querySelector(".full-application");
        setLoader(true);
        html2canvas(capture).then((canvas) => {
            const imgData = canvas.toDataURL("img/png");
            const doc = new JsPDF("p", "mm", "a4");
            const componentWidth = doc.internal.pageSize.getWidth();
            const componentHeight = doc.internal.pageSize.getHeight();
            doc.addImage(imgData, "PNG", 0, 0, componentWidth, componentHeight);
            setLoader(false);
            doc.save(`${application.orgName}-Agreement.pdf`);
        });
    };

    function addYears(date, years) {
        date.setFullYear(date.getFullYear() + years);
        return date;
    }

    const createdAt = application?.createdAt ? application.createdAt : null;
    const submissionDate = new Date(createdAt);
    const newDate = addYears(submissionDate, 1);

    const fetchApplication = async () => {
        try {
            const response = await getAppById(accessToken, applicationId);
            if (response.status === "success") {
                setApplication(response.data);
            }
        } catch (error) {
            console.error("Error fetching application:", error);
        }
    };

    useEffect(() => {
        fetchApplication();
    }, [applicationId, accessToken]);

    return (
        <div className="p-6 bg-gray-50 min-h-screen">
            <div className="bg-white shadow-lg rounded-lg p-6 border border-gray-200">
                <div className="full-application">
                    <h3 className="text-2xl font-bold text-gray-900 mb-4">
                        Partner Information
                    </h3>
                    <dl className="divide-y divide-gray-200">
                        {[
                            { label: "Organization Name", value: application.orgName },
                            { label: "First Name & Last Name", value: `${application.firstName} ${application.lastName}` },
                            { label: "Submission Date", value: format(new Date(createdAt), "MMMM do, yyyy") },
                            { label: "Term Length", value: `${application.termLength} Months` },
                            { label: "Phone Number", value: application.phoneNumber },
                            { label: "Email address", value: application.email },
                            { label: "COhatch City & Location", value: `${application.market}, ${application.coLocation}` },
                            { label: "Number of Coworkers (if available)", value: application.orgAmount },
                            { label: "Type & Cause", value: `${application.type} Scholarship | ${application.cpCategory}` },
                            { label: "Our Asks of the Community Partner:", value: application.ourAsks },
                            { label: "Any other conditions of this partnership?", value: application.extraConditions },
                            { label: "Total Dollars Awarded in Coworking and/or Meeting & Event Space", value: application.spaceAmount },
                        ].map((item, index) => (
                            <div key={index} className={`bg-${index % 2 === 0 ? 'white' : 'gray-50'} px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 rounded-lg shadow-sm`}>
                                <dt className="text-sm font-medium text-gray-900">{item.label}</dt>
                                <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">{item.value}</dd>
                            </div>
                        ))}
                    </dl>
                </div>

                {application.type === "boost" && (
                    <div className="full-application mt-6">
                        <h3 className="text-2xl font-bold text-gray-900 mb-4">
                            Applicant Information
                        </h3>
                        <dl className="divide-y divide-gray-200">
                            {[
                                { label: "Organization Name", value: application.orgName },
                                { label: "First Name and Last Name", value: `${application.firstName} ${application.lastName}` },
                                { label: "Application Date", value: format(new Date(createdAt), "MMMM do, yyyy") },
                                { label: "Reapplication Date", value: application.reapplicant === "no" ? format(new Date(newDate), "MMMM do, yyyy") : "January 1, 2025" },
                                { label: "Phone Number", value: application.phoneNumber },
                                { label: "Email address", value: application.email },
                                { label: "Application Type & Category", value: `${application.type} Scholarship | ${application.category}` },
                                { label: "Organization Address", value: application.businessAddress?.address || application.businessAddress },
                                { label: "COhatch City & Location", value: `${application.market}, ${application.coLocation}` },
                                { label: "Date Founded", value: application.dateFounded },
                                { label: "Number of Employees", value: application.orgAmount },
                                { label: "Description of Your Organization and Mission?", value: application.orgMission },
                                { label: "How will using COhatch's locations and resources help you reach your goals?", value: application.orgUses },
                                { label: "What social & economic impact does your company have on the local community?", value: application.orgAlign },
                                { label: "Tell Us About Your Business Plan?", value: application.businessPlan },
                                { label: "What Is Your Funding Strategy?", value: application.fundingStrategy },
                                { label: "When Do You Project Your Breakeven Point?", value: application.breakevenPoint },
                                { label: "When Do You Project the Need for Additional Hiring Over the Next 24 Months?", value: application.futureHiring },
                                { label: "How Much Space Do You Need? What is Your Current Office Size?", value: application.spaceRequirements },
                                { label: "How Do You Plan To Utilize The COhatch Space? What Will Your Team Use It For?", value: application.spaceUsage },
                            ].map((item, index) => (
                                <div key={index} className={`bg-${index % 2 === 0 ? 'white' : 'gray-50'} px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 rounded-lg shadow-sm`}>
                                    <dt className="text-sm font-medium text-gray-900">{item.label}</dt>
                                    <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">{item.value}</dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                )}

                <div className="mt-6 flex justify-end">
                    <button
                        type="button"
                        onClick={downloadPDF}
                        className="flex items-center px-4 py-2 bg-primary-400 text-white font-semibold rounded-md shadow-lg hover:bg-primary-700 transition duration-300 ease-in-out"
                    >
                        <ArrowDownTrayIcon className="h-5 w-5 mr-2" />
                        Download PDF
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CommunityPartnerAgreementFile;
