import React from "react";
import PropTypes from "prop-types";

function ButtonPrimary({ text, loading, action, icon }) {
  return (
    <button
      type="button"
      className={`w-full ${
        loading ? "cursor-not-allowed" : "cursor-pointer"
      } relative rounded shadow-sm px-2.5 py-2 text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition ease-linear duration-500`}
      onClick={action}
    >
      {icon && <div className="absolute inset-y-0 left-2 top-2"><span className="material-icons-outlined text-md">{icon}</span></div>}
      {text}
    </button>
  );
}

ButtonPrimary.propTypes = {
  text: PropTypes.string,
  loading: PropTypes.bool,
  action: PropTypes.func,
  icon: PropTypes.string,
};

export default ButtonPrimary;
