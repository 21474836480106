/*eslint-disable*/
import React from "react";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import SelectInput from "../../components/select_input/SelectInput";
import ButtonPrimary from "../../components/button/ButtonPrimary";
import notify from "../../services/toast";
import { MAGIC_NUMBERS } from "../../constant";
import updateApplicant from "./updateApplication";
import useUserContext from "../../hooks/useUserContext";
import Confett from "./Confetti";
import { useNavigate } from "react-router-dom";
import MdLogo from "../../assets/NonProfitmed.png";

function ApplicationModal({
	open,
	onClose,

	submissionDate,

	application,
}) {
	const { userState } = useUserContext();
	const navigate = useNavigate();
	const [cmStatus, setCMStatus] = React.useState("");
	const [mlStatus, setMLStatus] = React.useState("");
	const showConfetti = React.useState(false);
	const [updatingApplicant, setUpdatingApplicant] = React.useState(false);

	const statusOPTIONS = [
		{ value: "Rejected", label: "Rejected" },
		{ value: "Approved", label: "Approved" },
		{ value: "Under Consideration", label: "Under Consideration" },
	];
  
	// const appInfo = {
	// 	firstName: application.firstName,
  //   _id: application.id,
	// 	lastName: application.lastName,
	// 	email: application.email,
  //   phoneNumber: application.phoneNumber,
	// 	type: application.type,
	// 	orgName: application.orgName,
	// 	orgMission: application.orgMission,
	// 	address: application.businessAddress.address,
  //   orgAmount: application.orgAmount,
  //   orgAlign: application.orgAlign,
  //   coLocation: application.coLocation,
  //   submissionDate: application.createdAt,
  //   accessToken: userState.accessToken
	// };

	function handleClick() {
		//console.log(application);
		navigate(`/dashboard/profile/${application.id}`);
	}

	function addYears(date, years) {
		date.setFullYear(date.getFullYear() + years);
		return date;
	}
	const date = new Date(submissionDate);
	const newDate = addYears(date, 3);


 
	return (
		<>
			<div className="modal-dialog modal-dialog-scrollable">
				<div className={`${open ? "block" : "hidden"} relative z-10 `}>
					<div className="fixed inset-0 bg-gray-100 bg-opacity-60 transition-opacity"></div>
					<div className="fixed z-10 inset-0 overflow-y-auto">
						<div className="flex items-end justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0">
							<div className="max-w-3xl mx-auto bg-white rounded-md shadow-md p-4 flex flex-col">
								<div className="flex cursor-pointer px-3">
									<button onClick={onClose}>
										<span className="material-icons-outlined text-4xl">
											chevron_left
										</span>
									</button>
								</div>

								<div className="mt-8">
									<section className="mb-20 ">
                    <div className="mb-3">
										<div className="float-left mr-4">
											<img
												src={MdLogo}
												alt="Profile Photo"
												className=""
											/>
                      
										</div>
										<h1 className="text-left  text-4xl font-bold leading-7 text-success-800 ">
											{application.orgName}
										</h1>
                    </div>

										<p className="text-left text-l">GIVE Scholar, Year 1</p>
                    <div className="ml-12">
										<div className="float-left mt-1 mr-3">
											<div className="mt-1">
												<ButtonPrimary
													text="Applicant Profile"
													size="md"
													action={handleClick}
												/>
											</div>
										</div>
								
                    </div>
                    
									</section>
									<div className="mx-3 space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0"></div>
								</div>
								<div className="mt-5 w-full sm:p-4 space-y-5">
									<div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
										<div className="w-full">
											<label
												htmlFor="name"
												className="block text-sm font-medium text-gray-700"
											>
												First Name
											</label>
											<div className="mt-1">
												<input
													className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm text-gray-500 focus:outline-none focus:ring-primary-400 focus:border-primary-400"
													type="text"
													readOnly={true}
													value={application.firstName}
												/>
											</div>
										</div>

										<div className="w-full">
											<label
												htmlFor="name"
												className="block text-sm font-medium text-gray-700"
											>
												Last Name
											</label>
											<div className="mt-1">
												<input
													className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm text-gray-500 focus:outline-none focus:ring-primary-400 focus:border-primary-400"
													type="text"
													name="name"
													id="name"
													readOnly={true}
													value={application.lastName}
												/>
											</div>
										</div>
									</div>
									<div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
										<div className="w-full">
											<label
												htmlFor="jobTitle"
												className="block text-sm font-medium text-gray-700"
											>
												E-mail
											</label>
											<div className="mt-1">
												<input
													className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md text-gray-500"
													type="text"
													name="jobTitle"
													id="jobTitle"
													readOnly={true}
													value={application.email}
												/>
											</div>
										</div>

										<div className="w-full">
											<label
												htmlFor="phone"
												className="block text-sm font-medium text-gray-700"
											>
												Phone Number
											</label>
											<div className="mt-1">
												<input
													className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md text-gray-500"
													type="tel"
													name="phone"
													readOnly={true}
													id="phone"
													value={application.phoneNumber}
												/>
											</div>
										</div>
									</div>

									<div className="w-full">
										<label
											htmlFor="location"
											className="block text-sm font-medium text-gray-700"
										>
											Location
										</label>
										<div className="mt-1">
											<SelectInput
												className="capitalize bg-gray-900"
												noOptionsMessage="No locations found"
												placeholder={application.coLocation}
												isDisabled={true}
											/>
										</div>
									</div>

									<div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
										<div className="w-full">
											<label
												htmlFor="company"
												className="block text-sm font-medium text-gray-700"
											>
												Application Submission Date
											</label>
											<div className="mt-1">
												<input
													className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md text-gray-500"
													type="text"
													name="company"
													id="company"
													isDisabled={true}
                          readOnly={true}
													placeholder={format(
														new Date(submissionDate),
														"iii MMMM do yyyy"
													)}
												/>
											</div>
										</div>

										<div className="w-full">
											<label
												htmlFor="website"
												className="block text-sm font-medium text-gray-700"
											>
												Application Renewal Date
											</label>
											<div className="mt-1">
												<input
													className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md text-gray-500"
													type="url"
													name="website"
													id="website"
													readOnly={true}
													value={format(new Date(newDate), "iii MMMM do yyyy")}
												/>
											</div>
										</div>
									</div>
									<div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
										<div className="w-full">
											<label
												htmlFor="facebook"
												className="block text-sm font-medium text-gray-700"
											>
												CM Approval Status
											</label>
											<div className="mt-1">
												<SelectInput
													className=" block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
											readOnly={true}
												placeholder={application.cmStatus}
													//setSelectedValue={(event) => setCMStatus(event.value)}
                        isDisabled={true}
												/>
											</div>
										</div>

										<div className="w-full">
											{mlStatus === "Approved" && cmStatus === "Approved" && (
												<Confett />
											)}
											<label
												htmlFor="linkedin"
												className="block text-sm font-medium text-gray-700"
											>
												ML Approval Status
											</label>
											<div className="mt-1">
												<SelectInput
													className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													options={statusOPTIONS}
													placeholder={application.mlStatus}
                          isDisabled={true}
												/>
											</div>
										</div>
									</div>

									<div className="w-full">
										<label
											htmlFor="bio"
											className="block text-sm font-medium text-gray-700"
										>
											Notes
										</label>
										<div className="mt-1">
											<textarea
												className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md text-gray-500"
												rows="4"
												name="comment"
												value={application.notes}
												// onChange={(event) =>
												// 	setEditApplicant({
												// 		...application,
												// 		notes: event.target.value,
												// 	})
												// }
											></textarea>
										</div>
										<p className="text-sm text-gray-300">4000 characters max</p>
									</div>
								</div>

								{/* <div className="w-full flex justify-end">
									<div>
										<ButtonPrimary
											text="Save Changes"
											size="md"
											//onClick={handleSave}
										/>
									</div>
								</div> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default ApplicationModal;
