/* eslint-disable */
import config from "../../../../config";
import makeAPIRequest from "../../../../services/makeRequest";



async function getImpactReports(accessToken) {
  console.log(accessToken, "trying")
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/admin/impactreport`;
	const response = await makeAPIRequest(API_ENDPOINT, "GET", null, accessToken);
	const jsonResponse = await response.json();
	return jsonResponse;
}
export default getImpactReports;
