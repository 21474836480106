import config from "../../config";
import makeAPIRequest from "../../services/makeRequest";

const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/admin/administrator/reset-password`;

async function resetAdminPassword(body, accessToken) {
  const response = await makeAPIRequest(API_ENDPOINT, "PUT", body, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default resetAdminPassword;
