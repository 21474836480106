import React from "react";
import { Outlet } from "react-router-dom";
import Logo from "../../../components/logo/Logo";
import { Link } from "react-router-dom";
import useNotificationContext from "../../../hooks/useNotificationContext";
import ImpactNavigation from "../ImpactNavigation";

function ImpactDashboard() {
	const mobileNavigationRef = React.useRef(null);
	const { unreadNotifications } = useNotificationContext();
	const toggleMobileMenu = () => {
		mobileNavigationRef.current.classList.toggle("hidden");
	};

	return (
		<>
			<div className="bg-neutral-50">
				<div
					ref={mobileNavigationRef}
					className="fixed inset-0 flex z-40 md:hidden"
					role="dialog"
					aria-modal="true"
				>
					<div
						className="fixed inset-0 bg-gray-600 bg-opacity-75"
						aria-hidden="true"
					></div>
					<div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
						<div className="absolute top-0 right-0 -mr-12 pt-2">
							<button
								onClick={toggleMobileMenu}
								type="button"
								className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
							>
								<span className="sr-only">Close sidebar</span>
								<span className="material-icons-outlined text-white">
									close
								</span>
							</button>
						</div>

						<div className="flex-shrink-0 flex items-center px-4">
							<Logo color="green" />
						</div>
						
						<div className="mt-5 flex-1 h-0 overflow-y-auto">
							<ImpactNavigation />
						</div>
					</div>

					<div className="flex-shrink-0 w-14" aria-hidden="true"></div>
				</div>
				<div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
					<div className="flex flex-col flex-grow pt-5 bg-white overflow-y-auto">
						<div className="flex items-center flex-shrink-0 px-4">
							<Logo color="green" />
						</div>
						<div className="mt-5 flex-1 flex flex-col">
							<ImpactNavigation />
						</div>
					</div>
				</div>
				<div className="md:pl-64 flex flex-col flex-1">
					<div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
						<button
							onClick={toggleMobileMenu}
							type="button"
							className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-success-800 md:hidden"
						>
							<span className="sr-only">Open sidebar</span>
							<svg
								className="h-6 w-6"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M4 6h16M4 12h16M4 18h7"
								/>
							</svg>
						</button>
						<div className="flex-1 px-4 flex justify-end items-center">
							<div className="ml-4 flex items-center md:ml-6">
								<div className="relative">
									<Link
										to="notification"
										type="button"
										className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500"
									>
										<span className="sr-only">View notifications</span>
										<span
											className={`material-icons-outlined text-3xl ${
												unreadNotifications ? "text-red-400" : ""
											}`}
										>
											notifications
										</span>
									</Link>
									{unreadNotifications ? (
										<span className="absolute top-0 left-4 w-6 h-6 p-3 inline-flex items-center justify-center rounded-full text-xs bg-red-100 text-red-500">
											{unreadNotifications < 100 ? unreadNotifications : "100+"}
										</span>
									) : null}
								</div>
							</div>
						</div>
					</div>
					<div className="bg-neutral-50">
						<Outlet />
					</div>
				</div>
			</div>
		</>
	);
}

export default ImpactDashboard;
