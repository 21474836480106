/* eslint-disable  */
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import useLogout from "../../hooks/useLogout";
import { BLOCKED_PAGES } from "../../constant";
import useUserContext from "../../hooks/useUserContext";

function ImpactNavigation() {
	const [loggingOut, setLoggingOut] = React.useState(false);
	const [handleLogout] = useLogout(setLoggingOut);
	const location = useLocation();
	const { userState } = useUserContext();

	const showNav = (path) => {
		const authorized = userState.role.find(
			(role) => BLOCKED_PAGES[role].indexOf(path) === -1
		);
		return !!authorized;
	};

	return (
		<nav className="flex-1 px-2 pb-4 space-y-1">
    	<NavLink
				to={loggingOut ? "" : "/impact/dashboard"}
				end
				className={({ isActive }) => {
					return isActive
						? "bg-primary-400  group flex items-center px-2 py-2 text-sm font-medium rounded-md transition-all duration-500"
						: "hover:bg-primary-400 group flex items-center px-2 py-2 text-sm font-medium rounded-md transition-all duration-500";
				}}
			>
				<span className="mr-3 material-icons-outlined h-6 w-6 ">
					dashboard
				</span>
				 Impact Home
			</NavLink>
  {showNav("dashboard/all_scholarships") && (
				<NavLink
					to={loggingOut ? "" : "/impact/locations"}
					className={() => {
						const isActive =
							location.pathname.indexOf("/locations") > -1;
						return isActive
							? "bg-primary-400 group flex items-center px-2 py-2 text-sm font-medium rounded-md transition-all duration-500"
							: " hover:bg-primary-400 group flex items-center px-2 py-2 text-sm font-medium rounded-md transition-all duration-500";
					}}
				>
					<span className="mr-3 material-icons-outlined h-6 w-6 ">
						description
					</span>
					All Markets
				</NavLink>
			)}

      {/* {showNav("dashboard/all_scholarships") && (
				<NavLink
					to={loggingOut ? "" : "/impact/locations"}
					className={() => {
						const isActive =
							location.pathname.indexOf("/locations") > -1;
						return isActive
							? "bg-primary-400 group flex items-center px-2 py-2 text-sm font-medium rounded-md transition-all duration-500"
							: " hover:bg-primary-400 group flex items-center px-2 py-2 text-sm font-medium rounded-md transition-all duration-500";
					}}
				>
					<span className="mr-3 material-icons-outlined h-6 w-6 ">
						description
					</span>
					All Markets
				</NavLink>
			)} */}



       {showNav("dashboard/all_applications") && (


				<NavLink
					to={loggingOut ? "" : "/impact/all_applications"}
					className={() => {
						const isActive =
							location.pathname.indexOf("/applications") > -1;
						return isActive
							? "bg-primary-400 group flex items-center px-2 py-2 text-sm font-medium rounded-md transition-all duration-500"
							: " hover:bg-primary-400 group flex items-center px-2 py-2 text-sm font-medium rounded-md transition-all duration-500";
					}}
				>
					<span className="mr-3 material-icons-outlined h-6 w-6 ">
						
            person_search

					</span>
				All Applications
				</NavLink>


			)}
    
        <NavLink
				to={loggingOut ? "" : "/impact/impactreports"}
				end
				className={({ isActive }) => {
					return isActive
							? "bg-primary-400 group flex items-center px-2 py-2 text-sm font-medium rounded-md transition-all duration-500"
							: " hover:bg-primary-400 group flex items-center px-2 py-2 text-sm font-medium rounded-md transition-all duration-500";
				}}
			>
        <span className="mr-3 material-icons-outlined h-6 w-6 ">
						
            sort

					</span>
		Impact Reports
			</NavLink> 


       {/* {showNav("dashboard/all_scholars") && (
				<NavLink
					to={loggingOut ? "" : "/impact/all_scholars"}
					className={() => {
						const isActive =
							location.pathname.indexOf("/all_scholars") > -1;
						return isActive
							? "bg-primary-400 group flex items-center px-2 py-2 text-sm font-medium rounded-md transition-all duration-500"
							: " hover:bg-primary-400 group flex items-center px-2 py-2 text-sm font-medium rounded-md transition-all duration-500";
					}}
				>
					<span className="mr-3 material-icons-outlined h-6 w-6 ">
						description
					</span>
					All Scholars
				</NavLink>
			)} */}
		
     
      {showNav("dashboard/featured") && (
				<NavLink
					to={loggingOut ? "" : "/impact/technical-support"}
					
					className={() => {
						const isActive =
							location.pathname.indexOf("/dashboard/featured") > -1;
						return isActive
							? " group flex items-center px-2 py-2 text-sm font-medium rounded-md transition-all duration-500"
							: " hover:bg-primary-400 group flex items-center px-2 py-2 text-sm font-medium rounded-md transition-all duration-500";
					}}
				>
					<span className="mr-3 material-icons-outlined h-6 w-6 ">
						
          volunteer_activism

					</span>
					Stories
				</NavLink>
			)}

			
			{showNav("dashboard") && (
				<NavLink
					to={loggingOut ? "" : "/dashboard"}
					
					className={() => {
						const isActive = location.pathname.indexOf("/dashboard") > -1;
						return isActive
							? " group flex items-center px-2 py-2 text-sm font-medium rounded-md transition-all duration-500"
							: " hover:bg-primary-400 group flex items-center px-2 py-2 text-sm font-medium rounded-md transition-all duration-500";
					}}
				>
					<span className="mr-3 material-icons-outlined h-6 w-6 ">
						expand_less
					</span>
					Return to Main Dashboard
				</NavLink>
			)}

			{/* <div
				className="cursor-pointer  hover:bg-primary-400 group flex items-center px-2 py-2 text-sm font-medium rounded-md transition-all duration-500"
				onClick={handleLogout}
			>
				<span className="mr-3 material-icons-outlined h-6 w-6 ">logout</span>
				Logout
			</div> */}
		</nav>
	);
}

export default ImpactNavigation;
