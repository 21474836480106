import React from "react";
import PropTypes from "prop-types";

function List({
  columns,
  data,
  selectedItems,
  setSelectedItems,
  onRowClick,
  keyItem,
  isLoading,
  hideCheckbox,
}) {
  const handleSelectItems = (item) => {
    const newSelectedItems = [...selectedItems];
    const idx = selectedItems.indexOf(item[keyItem]);
    if (idx === -1) {
      newSelectedItems.push(item._id);
      setSelectedItems(newSelectedItems);
    } else {
      newSelectedItems.splice(idx, 1);
      setSelectedItems(newSelectedItems);
    }
  };

  return (
    <div className="mt-8 flex flex-col">
      <div className="overflow-x-auto">
        <div className="inline-block min-w-full py-2 align-middle px-1">
          {columns[0].title && <div className="flex bg-gray-200 mb-3 md:rounded-lg shadow ring-1 ring-black ring-opacity-5">
            {!hideCheckbox && <div className="w-6 py-4 mx-3 text-sm font-medium text-gray-900">
              <input
                className="border border-success-800"
                type="checkbox"
              />
            </div>}
            {columns.map((column, index) => (
              <div
                key={`column-${column.name}`}
                className={`whitespace-nowrap py-2 mx-2 text-sm font-medium text-gray-900 ${column.width ? column.width : "basis-full"}`}
              >
                <div className={index === 0 ? "py-2" : "p-2"}>{column.title || ""}</div>
              </div>
            ))}
          </div>}
          {isLoading && <div className="min-w-full text-center">
            <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status"></div>
            <span className="block text-gray-500 text-sm capitalize italic">Loading...</span>
          </div>}
          {!isLoading && data.map((item, index) => (
            <div
              className="flex cursor-pointer bg-white mb-3 md:rounded-lg shadow ring-1 ring-black ring-opacity-5"
              key={`row-${index}`}
              onClick={() => onRowClick(item)}
            >
              {!hideCheckbox && <div className="w-6 py-4 mx-3 text-sm font-medium text-gray-900">
                <input
                  className="border border-success-800"
                  type="checkbox"
                  id={`list-checkbox-${index}`}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleSelectItems(item);
                  }}
                />
              </div>}
              {columns.map((column, jndex) => (
                <div
                  key={`column-${index}-${jndex}`}
                  className={`whitespace-nowrap py-2 mx-2 text-sm font-medium text-gray-900 ${column.width ? column.width : "basis-full"}`}
                >
                  {column.type === "render" && column.value(item)}
                  {column.type !== "render" && <div className="py-2">{(item[column.value] ? item[column.value] : "-")}</div>}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

List.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectedItems: PropTypes.any.isRequired,
  setSelectedItems: PropTypes.any.isRequired,
  onRowClick: PropTypes.any,
  keyItem: PropTypes.string.isRequired,
  hideCheckbox: PropTypes.bool,
};

export default List;
