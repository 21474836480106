/*eslint-disable*/
import React from "react";
import { Line } from "react-chartjs-2";
import { format, parseISO } from "date-fns";
import "chart.js/auto";

const createLineGraphData = (data) => {
	const countsPerMonthGive = {};
	const countsPerMonthBoost = {};

	data.forEach((app) => {
		const formattedDate = format(parseISO(app.createdAt), "MMMM yyyy");

		if (app.type === "give") {
			countsPerMonthGive[formattedDate] =
				(countsPerMonthGive[formattedDate] || 0) + 1;
		} else if (app.type === "boost") {
			countsPerMonthBoost[formattedDate] =
				(countsPerMonthBoost[formattedDate] || 0) + 1;
		}
	});

	const labels = Object.keys(countsPerMonthGive); // Assuming give and boost have the same months
	const countsGive = Object.values(countsPerMonthGive);
	const countsBoost = Object.values(countsPerMonthBoost);

	return {
		labels,
		datasets: [
			{
				label: "Give",
				data: countsGive,
				backgroundColor: "#50C878",
				borderColor: "#50C878",
			},
			{
				label: "Boost",
				data: countsBoost,
				backgroundColor: "#00008B",
				borderColor: "#00008B",
			},
		],
	};
};

const LineGraph = ({ applications }) => {
	const doubleBarGraphData = createLineGraphData(applications);

	return (
		<>
			<div className="rounded bg-white p-4 chart-container">
				<h2 style={{ textAlign: "center" }}>Application Submissions</h2>
				<Line data={doubleBarGraphData} />
			</div>
		</>
	);
};
export default LineGraph;
