/*eslint-disable*/
import React, { useEffect, useMemo, useRef } from "react";
import { useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import useUserContext from "../../../hooks/useUserContext";
import { format } from "date-fns";
import DateRangeCalendar from "../../../components/impact_report/DateRangeCalendar";
import SelectInput from "../../../components/select_input/SelectInput";
import { COHATCH_MARKETS } from "../../../constant";
import getApplicationsForReport from "./requests/getApplicationsForReport";
import { MAGIC_NUMBERS } from "../../../constant";
import notify from "../../../services/toast";
import createImpactReport from "./requests/createImpactReport";
import { capitalizeFirstLetter } from "../../../services/util";

export default function ImpactReportModal({ open, onClose }) {
	//Download Report
	const [loader, setLoader] = useState(false);
	const { userState } = useUserContext();
	const pdfRef = useRef();
	const downloadPDF = () => {
		const input = document.querySelector(".full-application");
		setLoader(true);
		html2canvas(input).then((canvas) => {
			const imgData = canvas.toDataURL("image/png");
			const pdf = new jsPDF("p", "pt", "a4");
			const pdfWidth = pdf.internal.pageSize.getWidth();
			const pdfHeight = pdf.internal.pageSize.getHeight();
			pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
			setLoader(false);
			pdf.save("MarketReport.pdf");
		});
		handleCreateReport();
	};

	//Set Date and Market for Report
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [headerStartDate, setHeaderStartDate] = useState("");
	const [headerEndDate, setHeaderEndDate] = useState("");
	const [market, setMarket] = useState("");
	const [dateRange, setDateRange] = useState("");

	const [reportInfo, setReportInfo] = useState("");
	const events = reportInfo.allEvents;
	const [goalEvent, setGoalEvent] = useState("");

	//Creating Report Payload
	//total locations
	const [totalLocationsGoalGive, setTotalLocationsGoalGive] = useState("");
	const [totalLocationsGoalBoost, setTotalLocationsGoalBoost] = useState("");
	//const [totalLocationsGive, setTotalLocationsGive] = useState("");
	//const [totalLocationsBoost, setTotalLocationsBoost] = useState("");
	//total apps
	const [totalApplicationsGoalGive, setTotalApplicationsGoalGive] =
		useState("");
	const [totalApplicationsGoalBoost, setTotalApplicationsGoalBoost] =
		useState("");
	const [totalApplicationsGive, setTotalApplicationsGive] = useState("");
	const [totalApplicationsBoost, setTotalApplicationsBoost] = useState("");
	//total approved
	const [
		totalApprovedApplicationsGoalGive,
		setTotalApprovedApplicationsGoalGive,
	] = useState("");
	const [
		totalApprovedApplicationsGoalBoost,
		setTotalApprovedApplicationsGoalBoost,
	] = useState("");
	const [totalApprovedApplicationsGive, setTotalApprovedApplicationsGive] =
		useState("");
	const [totalApprovedApplicationsBoost, setTotalApprovedApplicationsBoost] =
		useState("");
	//average $$ scholarship
	const [
		averageAmountScholarshipGoalGive,
		setAverageAmountScholarshipGoalGive,
	] = useState("");
	const [
		averageAmountScholarshipGoalBoost,
		setAverageAmountScholarshipGoalBoost,
	] = useState("");
	const [averageAmountScholarshipGive, setAverageAmountScholarshipGive] =
		useState("");
	const [averageAmountScholarshipBoost, setAverageAmountScholarshipBoost] =
		useState("");
	// total scholarship award
	const [totalAwardGoalGive, setTotalAwardGoalGive] = useState("");
	const [totalAwardGoalBoost, setTotalAwardGoalBoost] = useState("");
	const [totalAwardGive, setTotalAwardGive] = useState("");
	const [totalAwardBoost, setTotalAwardBoost] = useState("");
	const [submitting, setSubmitting] = useState(false);
	const [month, setMonth] = useState("");
	//const [goalEventName, setGoalEventName] = useState()
	const createdBy = `${userState.firstName} ${userState.lastName}`;

	const generateReportInfo = () => {
		fetchApplicationsForReport(
			startDate,
			endDate,
			market,
			userState.accessToken
		);
	};

	const handleGoalEventChange = (key, value) => {
		setGoalEvent({
			...goalEvent,
			[key]: value,
		});
	};

	const renderEvents = (event, index) => {
		const date = event.eventDate;
		const newDate = new Date(date);
		const month = format(newDate, "MMMM");
		const exactDate = format(newDate, "MM / dd / yyyy");
		return (
			<>
				<div className="flex text-center  divide-x divide-black">
					<div key={index} className="w-1/4 font- h-12">
						{event.eventName}
					</div>
					<div className="w-1/4  h-12">{month}</div>
					<div className="w-1/4  h-12">{exactDate}</div>
					<div className="w-1/4  h-12">{event.eventCategory}</div>
					<div className="w-1/4  h-12">{event.userRSVP?.length}</div>
				</div>
			</>
		);
	};

	const handleDateRange = (dates) => {
		const startDate = dates.startDate;
		//const startDate = startRange.setHours(0);
		const beginDate = format(startDate, "yyyy-MM-dd");
		const endDate = dates.endDate;
		//const endDate = endRange.setHours(23);
		const endingDate = format(endDate, "yyyy-MM-dd");
		setMonth(format(startDate, "MMMM"));
		setHeaderStartDate(format(startDate, "MM / dd / yyyy"));
		setHeaderEndDate(format(endDate, "MM / dd / yyyy"));
		setStartDate(beginDate);
		setEndDate(endingDate);
	};

	const fetchApplicationsForReport = async (
		startDate,
		endDate,
		market,
		accessToken
	) => {
		try {
			if (!startDate && !endDate) {
				notify("Please select date range");
			} else if (!market) {
				notify("Please select a market");
			} else {
				const response = await getApplicationsForReport(
					{
						startDate,
						endDate,
						market,
					},
					accessToken
				);
				if (response.status === "success") {
					setReportInfo(response.data);
					notify("Successfully Generated!");
				} else if (response.status === "fail") {
					let displayMessage = "";
					if (Array.isArray(response.message)) {
						displayMessage = response.message[0].message;
					} else {
						displayMessage = response.message;
						if (
							MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)
						) {
							displayMessage =
								"An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
						}
					}
					notify(displayMessage, "error");
				}
			}
		} catch (error) {
			notify("Something went wrong. Please try again later.", "error");
			// console.log(error);
		}
	};

	const handleCreateReport = async (event) => {
		setSubmitting(true);
		try {
			const payload = {
				totalLocationsGoalGive,
				totalLocationsGoalBoost,
				totalApplicationsGoalGive,
				totalApplicationsGive,
				totalApplicationsGoalBoost,
				totalApplicationsBoost,
				totalApprovedApplicationsGoalGive,
				totalApprovedApplicationsGoalBoost,
				totalApprovedApplicationsGive,
				totalApprovedApplicationsBoost,

				averageAmountScholarshipGoalGive,
				averageAmountScholarshipGoalBoost,
				averageAmountScholarshipGive,
				averageAmountScholarshipBoost,
				totalAwardGoalGive,
				totalAwardGoalBoost,
				totalAwardGive,
				totalAwardBoost,
				goalEvent,
				events,
				headerStartDate,
				headerEndDate,
				market,
				month,
				createdBy,
			};

			const response = await createImpactReport(payload, userState.accessToken);
			if (response.status === "success") {
				setSubmitting(false);
				notify("Successfully Created Impact Report");
				setStartDate(null);
				onClose();
			} else if (response.status === "fail") {
				setSubmitting(false);
				let displayMessage = "";
				if (Array.isArray(response.message)) {
					displayMessage = response.message[0].message;
				} else {
					displayMessage = response.message;
					if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
						displayMessage =
							"Missing fields. Please fill out all fields and try again.";
					}
				}
				notify(displayMessage, "error");
			}
		} catch (error) {
			setSubmitting(false);
			notify("Unable to create report. Please try again later.");
		}
	};

	useEffect(() => {
		{
			dateRange && handleDateRange(dateRange);
		}
	}, [dateRange]);

	useEffect(() => {
		setTotalApplicationsGive(reportInfo.totalApplicationsNumberGive);
		setTotalApplicationsBoost(reportInfo.totalApplicationsNumberBoost);
		setTotalApprovedApplicationsGive(reportInfo.totalApplicantsApprovedGive);
		setTotalApprovedApplicationsBoost(reportInfo.totalApplicantsApprovedBoost);
		setAverageAmountScholarshipGive(reportInfo.averageAmountScholarshipGive);
		setAverageAmountScholarshipBoost(reportInfo.averageAmountScholarshipBoost);
		setTotalAwardGive(
			reportInfo?.totalAwardedScholarshipGive?.[0]?.annualAward
				? reportInfo?.totalAwardedScholarshipGive?.[0].annualAward
				: 0
		);
		setTotalAwardBoost(
			reportInfo?.totalAwardedScholarshipBoost?.[0]?.annualAward
				? reportInfo?.totalAwardedScholarshipBoost?.[0]?.annualAward
				: 0
		);
		// setGoalEvent(reportInfo.allEvents);
	}, [reportInfo]);
	//console.log(totalApplicationsGive ,"loc")
	return (
		<>
			<div className="modal-dialog modal-dialog-scrollable">
				<div className={`${open ? "block" : "hidden"} relative z-10`}>
					<div className="fixed inset-0 bg-gray-400 bg-opacity-80 transition-opacity"></div>
					<div className="fixed z-10 inset-0 overflow-y-auto">
						<div className="flex items-end justify-center min-w-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
							<span
								className="hidden sm:inline-block sm:align-middle sm:h-screen"
								aria-hidden="true"
							>
								&#8203;
							</span>

							<div
								className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 "
								style={{
									overflowY: "auto",
								}}
							>
								<div className="flex cursor-pointer px-3">
									<button onClick={onClose} className="font-bold text-xl pb-3">
										<span className="material-icons-outlined text-5xl">
											chevron_left
										</span>
									</button>
								</div>

								<div className="w-full space-y-5 flex justify-between">
									<div className="w-full">
										<DateRangeCalendar setDateRange={setDateRange} />
									</div>

									<div className="">
										<div className="w-full ">
											<label className="text-lg text-gray-700">
												Choose Market for Report
											</label>
										</div>
										<div className="w-80">
											<SelectInput
												options={COHATCH_MARKETS}
												noOptionsMessage="No categories found"
												placeholder="Select Market"
												defaultValue={userState.market}
												setSelectedValue={(event) => setMarket(event.value)}
												// onChange={console.log(event)}
											/>
										</div>
									</div>
								</div>
								<div className="w-full">
									<button
										className="m-3 w-48 bg-success-800 text-white text-center rounded py-2 shadow-sm px-2.5 py-2 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800 transition ease-linear duration-500"
										onClick={generateReportInfo}
									>
										Generate
									</button>
								</div>

								{/* BEGINNING OF PAGE To PRINT*/}
								<div
									className=" border-solid border-2 border-black  "
									ref={pdfRef}
								>
									<div className="bg-white border-solid border-2 border-black full-application">
										<div className="pt-5 pb-10 p-3 text-white font-bold text-4xl bg-primary-700 tracking-wide">
											<h1>Market Impact Business Plan & Report</h1>
											<h1>
												Market:{" "}
												{market ? `${capitalizeFirstLetter(market)}` : ""}
											</h1>
											<h1>Month: {month ? month : ""}</h1>
											<h1>
												Dates: {headerStartDate} - {headerEndDate}
											</h1>
										</div>
										<div className=" mb-15 "></div>

										<div className="divide-y divide-black goal">
											<div className="text-center p-3 font-bold">
												<h1>Scholarships</h1>
											</div>
											<div className="text-center p-2 text-white bg-primary-600 font-bold">
												<h1>Goal</h1>
											</div>
											<form
												//onSubmit={onSubmit}
												noValidate
												autoComplete="off"
												className="container"
											>
												<div className=" divide-y divide-black bg-white border-2 border-black">
													{/* HEADER */}
													<div className="flex text-center tracking-wide font-bold divide-x divide-black">
														<div className="w-1/6  h-12">Scholarships</div>
														<div className="w-1/6  h-12">Total Locations #</div>
														<div className="w-1/6  h-12">
															Total Applications Received #
														</div>
														<div className="w-1/6  h-12">
															Total Scholarships Awarded #
														</div>
														<div className="w-1/6  h-12">
															Average Amount Scholarships $
														</div>
														<div className="w-1/6  h-12">
															Total Awarded Scholarships $
														</div>
													</div>
													{/* GIVE */}
													<div className="flex text-center  divide-x  divide-black">
														<div className=" w-1/6 bg-white h-12">
															Give Scholarship
														</div>
														<div className=" w-1/6 bg-white h-12">
															<input
																className="block w-full h-full "
																type="text"
																name=""
																id=""
																placeholder="Enter Value"
																onChange={(evt) => {
																	setTotalLocationsGoalGive(evt.target.value);
																}}
															/>
														</div>
														<div className=" w-1/6 bg-white h-12">
															<input
																className="block w-full h-full "
																type="text"
																name=""
																id=""
																placeholder="Enter Value"
																onChange={(evt) => {
																	setTotalApplicationsGoalGive(
																		evt.target.value
																	);
																}}
															/>
														</div>
														<div className=" w-1/6 bg-white h-12">
															<input
																className="block w-full h-full "
																type="text"
																name=""
																id=""
																placeholder="Enter Value"
																onChange={(evt) => {
																	setTotalApprovedApplicationsGoalGive(
																		evt.target.value
																	);
																}}
															/>
														</div>
														<div className=" w-1/6 bg-white h-12">
															<input
																className="block w-full h-full "
																type="text"
																name=""
																id=""
																placeholder="Enter Value"
																onChange={(evt) => {
																	setAverageAmountScholarshipGoalGive(
																		evt.target.value
																	);
																}}
															/>
														</div>
														<div className=" w-1/6 bg-white h-12">
															<input
																className="block w-full h-full "
																type="text"
																name=""
																id=""
																placeholder="Enter Value"
																onChange={(evt) => {
																	setTotalAwardGoalGive(evt.target.value);
																}}
															/>
														</div>
													</div>
													{/* BOOST */}
													<div className="flex text-center  divide-x  divide-black">
														<div className=" w-1/6 bg-white h-12">
															Boost Scholarship
														</div>
														<div className=" w-1/6 bg-white h-12">
															<input
																className="block w-full h-full "
																type="text"
																name=""
																id=""
																placeholder="Enter Value"
																onChange={(evt) => {
																	setTotalLocationsGoalBoost(evt.target.value);
																}}
															/>
														</div>
														<div className=" w-1/6 bg-white h-12">
															<input
																className="block w-full h-full "
																type="text"
																name=""
																id=""
																placeholder="Enter Value"
																onChange={(evt) => {
																	setTotalApplicationsGoalBoost(
																		evt.target.value
																	);
																}}
															/>
														</div>
														<div className=" w-1/6 bg-white h-12">
															<input
																className="block w-full h-full "
																type="text"
																name=""
																id=""
																placeholder="Enter Value"
																onChange={(evt) => {
																	setTotalApprovedApplicationsGoalBoost(
																		evt.target.value
																	);
																}}
															/>
														</div>
														<div className=" w-1/6 bg-white h-12">
															<input
																className="block w-full h-full "
																type="text"
																name="average"
																id="average"
																placeholder="Enter Value"
																onChange={(evt) => {
																	setAverageAmountScholarshipGoalBoost(
																		evt.target.value
																	);
																}}
															/>
														</div>
														<div className=" w-1/6 bg-white h-12">
															<input
																className="block w-full h-full "
																type="text"
																name=""
																id=""
																placeholder="Enter Value"
																onChange={(evt) => {
																	setTotalAwardGoalBoost(evt.target.value);
																}}
															/>
														</div>
													</div>
													{/* COMMUNITY PARTNER  */}
													<div className="flex text-center  divide-x  divide-black">
														<div className=" w-1/6 bg-white h-12">
															Community Partner Scholarship
														</div>
														<div className=" w-1/6 bg-white h-12">n/a</div>
														<div className=" w-1/6 bg-white xh-12">n/a</div>
														<div className=" w-1/6 bg-white h-12">n/a</div>
														<div className=" w-1/6 bg-white h-12">n/a</div>
														<div className=" w-1/6 bg-white h-12">n/a</div>
													</div>
												</div>
											</form>

											<div className="divide-y divide-black new-this-month">
												<div className="text-center p-2 text-white bg-primary-600 font-bold">
													<h1>
														{market ? `${capitalizeFirstLetter(market)}` : ""}
													</h1>
												</div>
												{/* HEADERS */}
												<div className="flex text-center tracking-wide font-bold divide-x divide-black">
													<div className="w-1/4  h-12">Scholarships</div>
													<div className="w-1/4  h-12">
														Total Applications Received #
													</div>
													<div className="w-1/4  h-12">
														Total Scholarships Awarded #
													</div>
													<div className="w-1/4  h-12">
														Average Amount Scholarships ${" "}
													</div>
													<div className="w-1/4  h-12">
														Total Awarded Scholarships $
													</div>
												</div>
												{/* GIVE */}
												<div className="flex text-center  divide-x divide-black">
													<div className="w-1/4 font- h-12">
														Give Scholarships
													</div>
													<div className=" w-1/4 bg-white h-12">
														{reportInfo?.totalApplicationsNumberGive
															? reportInfo.totalApplicationsNumberGive
															: "0"}
													</div>
													<div className=" w-1/4 bg-white h-12">
														{reportInfo?.totalApplicantsApprovedGive
															? reportInfo.totalApplicantsApprovedGive
															: "0"}
													</div>
													<div className=" w-1/4 bg-white h-12">
														${" "}
														{reportInfo?.averageAmountScholarshipGive
															? reportInfo.averageAmountScholarshipGive.toLocaleString()
															: "0"}
													</div>
													<div className=" w-1/4 bg-white h-12">
														${" "}
														{reportInfo?.totalAwardedScholarshipGive?.[0]
															?.annualAward
															? reportInfo.totalAwardedScholarshipGive?.[0]?.annualAward.toLocaleString()
															: "0"}
													</div>
												</div>
												{/* BOOST */}
												<div className="flex text-center   divide-x divide-black">
													<div className="w-1/4  h-12">Boost Scholarships</div>
													<div className=" w-1/4 bg-white h-12">
														{reportInfo?.totalApplicationsNumberBoost
															? reportInfo.totalApplicationsNumberBoost
															: "0"}
													</div>
													<div className=" w-1/4 bg-white h-12">
														{reportInfo?.totalApplicantsApprovedBoost
															? reportInfo.totalApplicantsApprovedBoost
															: "0"}
													</div>
													<div className=" w-1/4 bg-white h-12">
														${" "}
														{reportInfo?.averageAmountScholarshipBoost
															? reportInfo.averageAmountScholarshipBoost.toLocaleString()
															: "0"}
													</div>
													<div className=" w-1/4 bg-white h-12">
														${" "}
														{reportInfo?.totalAwardedScholarshipBoost?.[0]
															?.annualAward
															? reportInfo.totalAwardedScholarshipBoost?.[0]?.annualAward.toLocaleString()
															: "0"}
													</div>
												</div>
												{/* COMMUNITY PARTNER  */}
												<div className="flex text-center  divide-x divide-black">
													<div className="w-1/4  h-12">
														Community Partner Scholarships
													</div>
													<div className="w-1/4  h-12">0</div>
													<div className="w-1/4  h-12">0</div>
													<div className="w-1/4  h-12">$ 0</div>
													<div className="w-1/4  h-12">$ 0</div>
												</div>
											</div>

											<div className="divide-x divide-black bg-white mb-25 "></div>

											<div className="text-center p-3 font-bold">
												<h1>Events</h1>
											</div>
											<div className="divide-y divide-x divide-black new-this-month">
												<div className="text-center p-2 text-white bg-primary-600 font-bold">
													<h1>Goal</h1>
												</div>
												{/* HEADERs */}
												<div className="flex text-center tracking-wide font-bold divide-x divide-black">
													<div className="w-1/4  h-12">All Events</div>
													<div className="w-1/4  h-12">Month</div>
													<div className="w-1/4  h-12">Type of Event</div>
													<div className="w-1/4  h-12">
														Estimate Total Invites #
													</div>
													<div className="w-1/4  h-12">RSVP Target #</div>
												</div>
												{/* All Goal Events By Market */}

												{events?.length ? (
													<div className="divide-y divide-black">
														{/* {events.map((event, index) => {
           // handleGoalEventChange("eventName", event.eventName)
            return renderGoalevents(event, index); 
            
           })} */}
														<EventList
															goalEvents={events}
															onToggle={handleToggleMyList}
														/>
													</div>
												) : (
													<div className="flex text-center  divide-x divide-black">
														<div className="w-1/5 font- h-12">No events</div>
														<div className="w-1/5  h-12">-</div>
														<div className="w-1/5  h-12">-</div>
														<div className="w-1/5  h-12">-</div>
														<div className="w-1/5  h-12">-</div>
													</div>
												)}
											</div>
											<div className="divide-y divide-x divide-black new-this-month">
												<div className="text-center p-2 text-white bg-primary-600 font-bold">
													<h1>Actual</h1>
												</div>
												{/* HEADERs */}
												<div className="flex text-center tracking-wide font-bold divide-x divide-black">
													<div className="w-1/5  h-12">All Events</div>
													<div className="w-1/5  h-12">Month</div>
													<div className="w-1/5  h-12">Date</div>
													<div className="w-1/5  h-12"> Type of Event</div>

													<div className="w-1/5  h-12">Total RSVPS #</div>
												</div>
												{/* All Events By Market */}
												{events?.length ? (
													<div className="divide-y divide-black">
														{events.map((event, index) => {
															return renderEvents(event, index);
														})}
													</div>
												) : (
													<div className="flex text-center  divide-x divide-black">
														<div className="w-1/5 font- h-12">No events</div>
														<div className="w-1/5  h-12">-</div>
														<div className="w-1/5  h-12">-</div>
														<div className="w-1/5  h-12">-</div>

														<div className="w-1/5  h-12">-</div>
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
								<div className="">
									<div className="mt-5 p-3 flex flex-row-start float-right">
										<div>
											<button
												onClick={handleCreateReport}
												type="submit"
												className="m-3 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 "
											>
												Save
											</button>
										</div>
										<div className="">
											<button
												className="m-3 w-full bg-success-800 text-white text-center rounded py-2 shadow-sm px-2.5 py-2 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800 transition ease-linear duration-500"
												onClick={downloadPDF}
												disabled={!(loader === false)}
											>
												{loader ? (
													<span>Downloading</span>
												) : (
													<span>Save & Download</span>
												)}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);

	function handleToggleMyList(eventId, key, value) {
		setGoalEvent(
			events.map((event) => {
				if (event._id === eventId) {
					// Create a *new* object with changes
					return setGoalEvent({ ...goalEvent, [key]: value });
				} else {
					// No changes
					return event;
				}
			})
		);
	}

	//   function EventList({ goalEvents, onToggle }) {
	//   return (
	//      <>
	//    {goalEvents.map((event, index) => (
	//     <div className="flex text-center  divide-x divide-black">
	//     <div className=" w-1/4 bg-white h-12" key={index}>
	//            {event.eventName}
	//             </div>
	//             <div className=" w-1/4 bg-white h-12">
	//                 <input
	//                   className="block w-full h-full "
	//                   type="text"
	//                   name="average"
	//                   id="average"
	//                   options={COHATCH_MARKET}
	//                 	//onChange={(evt) => {
	//                  // handleGoalEventChange("eventMonth", evt.target.value)}}
	//                 onChange={(evt) => { onToggle( event._id, "eventMonth", evt.target.value);
	//               }}
	//                 />
	//               </div>
	//             <div className=" w-1/4 bg-white h-12">
	//                 <input
	//                   className="block w-full h-full "
	//                   type="text"
	//                   name="average"
	//                   id="average"
	//                   placeholder="Enter Type"
	//                 	onChange={(evt) => { handleGoalEventChange("eventType", evt.target.value)  }}
	//                 />
	//               </div>
	//             <div className=" w-1/4 bg-white h-12">
	//                 <input
	//                   className="block w-full h-full "
	//                   type="number "
	//                   name="average"
	//                   id="average"
	//                   placeholder="Enter Value"
	//                 	onChange={(evt) => { handleGoalEventChange("totalInvites", evt.target.value)  }}
	//                 />
	//               </div>
	//             <div className=" w-1/4 bg-white h-12">
	//                 <input
	//                   className="block w-full h-full "
	//                   type="n"
	//                   name="average"
	//                   id="average"
	//                   placeholder="Enter Value"
	//                 	onChange={(evt) => { handleGoalEventChange("rsvpTarget", evt.target.value)  }}
	//                 />
	//               </div>
	//               </div>

	//       ))}
	//    </>
	//   );
	// }

	function EventList({ goalEvents, onToggle }) {
		const [newEvents, setNewEvents] = useState([...goalEvents]);

		const saveChanges = () => {
			setNewEvents([...goalEvents]);
		};

		const updateField = (eventId, fieldName, newValue) => {
			const updatedEvents = goalEvents.map((event) => {
				if (event._id === eventId) {
					return { ...event, [fieldName]: newValue };
				}
				return event;
			});
			setGoalEvents(updatedEvents);
		};

		const addRow = () => {
			const newEvent = {
				_id: (goalEvents.length + 1).toString(),
				eventName: "",
				eventDate: "",
				eventCategory: "",
				userRSVP: [],
			};
			setGoalEvents([...goalEvents, newEvent]);
		};

		const deleteRow = (eventId) => {
			const updatedEvents = goalEvents.filter((event) => event._id !== eventId);
			setGoalEvents(updatedEvents);
		};

		return (
			<>
				{goalEvents?.map((event, index) => (
					<div className="flex text-center divide-x divide-black" key={index}>
						<tr key={event._id}>
							<td
								contentEditable
								onBlur={(e) =>
									updateField(event._id, "eventName", e.target.innerText)
								}
								suppressContentEditableWarning={true}
							>
								{event.eventName}
							</td>
							<td
								contentEditable
								onBlur={(e) =>
									updateField(event._id, "eventDate", e.target.innerText)
								}
								suppressContentEditableWarning={true}
							>
								{event.eventDate}
							</td>
							<td
								contentEditable
								onBlur={(e) =>
									updateField(event._id, "eventCategory", e.target.innerText)
								}
								suppressContentEditableWarning={true}
							>
								{event.eventCategory}
							</td>
							<td>{event.userRSVP.map((user) => user.name).join(", ")}</td>
							<td>
								<button onClick={() => deleteRow(event._id)}>Delete</button>
							</td>
						</tr>

						<div className="w-1/4 bg-white h-12">
							<input
								className="block w-full h-full"
								type="number"
								value={event.rsvpTarget}
								onChange={(evt) => {
									onToggle(event._id, "rsvpTarget", parseInt(evt.target.value));
								}}
							/>
						</div>
					</div>
				))}
			</>
		);
	}
}
