/* eslint-disable no-unused-vars */
import React from "react";
import CouponCard from "../../components/coupon_card/CouponCard";
import { MAGIC_NUMBERS } from "../../constant";
import useUserContext from "../../hooks/useUserContext";
import notify from "../../services/toast";
import DefaultState from "./../../components/default_state/DefaultState";
import getDiscounts from "./getCoupons";
import Select from "react-select";
import Pagination from "../../components/pagination/Pagination";
import { debounce } from "../../services/util";
import { BUSINESS_CATEGORY } from "../../constant";
import Spinner from "../../components/spinner/Spinner";

function generateCategory() {
  const categories = [];

  for (const category in BUSINESS_CATEGORY) {
    categories.push(BUSINESS_CATEGORY[category]["mainCategory"]);
  }

  return categories;
}

function TechnicalSupportCoupon() {
  const { userState } = useUserContext();
  const [coupons, setCoupons] = React.useState([]);
  const [keyword, setKeyword] = React.useState("");
  const [isDiscountLoading, setIsDiscountLoading] = React.useState(true);
  const [pagination, setPagination] = React.useState({ limit: 30, total: 0, page: 0, remaining: 0 });

  const fetchDiscounts = async (accessToken, page, _keyword) => {
    setIsDiscountLoading(true);
    const response = await getDiscounts(
      {
        page,
        keyword: _keyword,
        limit: pagination.limit,
      },
      accessToken
    );
    if (response.status === "success") {
      const { limit, page, remaining, total, results } = response.data;
      setPagination({ limit, page: page, remaining, total });
      setCoupons(results);
    } else if (response.status === "fail") {
      let displayMessage = "";
      if (Array.isArray(response.message)) {
        displayMessage = response.message[0].message;
      } else {
        displayMessage = response.message;
        if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
          displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
        }
      }
      notify(displayMessage, "error");
    }
    setIsDiscountLoading(false);
  };

  const handleSearch = event => {
    const query = event.target.value;
    fetchDiscounts(userState.accessToken, 0, query);
    setKeyword(query);
  };

  const debouncedHandleSearch = React.useCallback(debounce(handleSearch, 500), []);

  const handlePrevFetch = async () => {
    await fetchDiscounts(userState.accessToken, pagination.page - 1, keyword);
  };

  const handleNextFetch = async () => {
    await fetchDiscounts(userState.accessToken, pagination.page + 1, keyword);
  };

  const handleReload = async () => {
    await fetchDiscounts(userState.accessToken, pagination.page, keyword);
  };

  React.useEffect(() => {
    fetchDiscounts(userState.accessToken, 0, "", "");
  }, []);
  return (
    <div className="w-full">
      <div className="w-full flex justify-between px-5">
        <input
          type="text"
          name="search"
          onInput={debouncedHandleSearch}
          placeholder="Search coupon by business name"
          className="block border w-[50%] border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 max-w-md sm:text-base"
        />
      </div>
      {!coupons.length ? (
        <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
          <DefaultState icon="discount" heading="No coupons found" description="No coupons found in database." />
        </div>
      ) : (
        <div className="w-full">
          {isDiscountLoading ? (
            <Spinner displayText="Loading..." />
          ) : (
            <div className="mt-5 w-full flex gap-4 flex-wrap px-5">
              {coupons.map((coupon, index) => (
                <CouponCard key={index} coupon={coupon} />
              ))}
            </div>
          )}
        </div>
      )}
      <Pagination pagination={pagination} next={handleNextFetch} prev={handlePrevFetch} />
    </div>
  );
}

export default TechnicalSupportCoupon;
