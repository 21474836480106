import React from "react";
import Title from "../../components/title/Title";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import useUserContext from "../../hooks/useUserContext";
import Pagination from "../../components/pagination/Pagination";
import { MAGIC_NUMBERS } from "./../../constant";
import notify from "./../../services/toast";
import Spinner from "./../../components/spinner/Spinner";
import getNotification from "./getNotification";
import ContactMessageAssign from "../../components/notification_type/contact_message_assign/ContactMessageAssign";

function determineNotification(notification, index, reload) {
  if (notification.type === "ASSIGN_CONTACT_MESSAGE" || notification.type === "NEW_GROUP") {
    return <ContactMessageAssign key={index} notification={notification} reload={reload} />;
  }
  return null;
}

function Notification() {
  const { userState } = useUserContext();
  const [notifications, setNotifications] = React.useState([]);
  const [fetching, setFetching] = React.useState(true);
  const [pagination, setPagination] = React.useState({ total: 0, page: 0, remaining: 0, limit: 10 });

  const fetchNotifications = async (accessToken, offset, limit) => {
    try {
      setFetching(true);

      const response = await getNotification({
      	offset,
      	limit,
      }, accessToken);
      if (response.status === "success") {
        const { limit, page, remaining, total, results } = response.data;
        setPagination({ limit, page: page, remaining, total });
        setNotifications(results);
      } else {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    } finally {
      setFetching(false);
    }
  };

  const reload = () => {
    fetchNotifications(userState.accessToken, 0, pagination.limit);
  };

  const handlePrevFetch = async () => {
    await fetchNotifications(userState.accessToken, pagination.page - 1, pagination.limit);
  };

  const handleNextFetch = async () => {
    await fetchNotifications(userState.accessToken, pagination.page + 1, pagination.limit);
  };

  React.useEffect(() => {
    fetchNotifications(userState.accessToken, 0, pagination.limit);
  }, []);

  return (
    <>
      <Title name="Notifications" />
      <Breadcrumb routes={["Dashboard", "Notification"]} />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {fetching ? (
          <Spinner displayText="Loading..." />
        ) : notifications.length ? (
          <div className="max-w-3xl mt-3 mx-auto bg-white rounded-md shadow-md p-4 flex flex-col">
            <ul role="list" className="divide-y divide-gray-200">
              {notifications.map((notification, index) => {
                return determineNotification(notification, index, reload);
              })}
            </ul>
            <Pagination pagination={pagination} next={handleNextFetch} prev={handlePrevFetch} />
          </div>
        ) : (
          <div className="mt-20 w-full text-center">
            No Notifications Found
          </div>
        )}
      </div>
    </>
  );
}

export default Notification;
