/* eslint-disable react/prop-types */
/*eslint-disable*/
import {FaUsers, FaDollarSign, FaLayerGroup, FaObjectGroup} from "react-icons/fa";
import {IoIosPeople} from "react-icons/io";
import {TbDiscount} from "react-icons/tb";
import {HiUsers} from "react-icons/hi";
import {TiBusinessCard} from "react-icons/ti";

const statsData = [
  { id: 1, description: "Total COhatch Members", key: "totalMembers", icon: HiUsers},
  { id: 2, description: "Total Active COhatch+ Users", key: "totalActiveMembers", icon: FaUsers},
  { id: 3, description: "Total Online Users", key: "totalOnlineMembers", icon: IoIosPeople},
  { id: 4, description: "Total Business Listings", key: "totalListings", icon: TiBusinessCard},
  { id: 5, description: "Total Discount Codes", key: "totalCoupons", icon: TbDiscount},
  { id: 6, description: "Total Revenue", key: "totalRevenue", icon: FaDollarSign},
  // { id: 7, description: "Total Neighborhood Groups", key: "totalGroups", icon: FaLayerGroup},
  // { id: 8, description: "Monthly Active Groups", key: "totalMonthlyGroups", icon: FaObjectGroup},
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Stats({
  stats
}) {
  return (
    <div>
      <dl className=" grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {statsData.map((item) => (
          <div
            key={item.id}
            className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-6 shadow sm:px-6 sm:pt-6"
          >
            <dt>
              <div className="absolute rounded-md bg-primary-400 p-3">
                <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <p className="ml-16 truncate text-sm font-medium text-gray-500">{item.description}</p>
            </dt>
            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
              <p className="text-2xl font-semibold text-gray-900">{item.key === "totalRevenue" ? "$ " : ""}{(stats[item.key] || 0).toString().replace(/(.)(?=(\d{3})+$)/g, "$1,")}</p>
              <p
                className={classNames(
                  item.changeType === "increase" ? "text-green-600" : "text-red-600",
                  "ml-2 flex items-baseline text-sm font-semibold"
                )}
              >
              
              </p>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}