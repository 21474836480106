import config from "../../config";
import makeAPIRequest from "../../services/makeRequest";

async function getOfficernd(location, accessToken, page) {
  const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/admin/referral_codes/admin?location=${location}&page=${page}&limit=10`;
  const response = await makeAPIRequest(API_ENDPOINT, "GET", null, accessToken);
  const jsonResponse = await response.json();
  return jsonResponse;
}

export default getOfficernd;
