/* eslint-disable no-unused-vars */
import React from "react";
import { MAGIC_NUMBERS } from "../../constant";
import useUserContext from "../../hooks/useUserContext";
import notify from "../../services/toast";
import { generateClassName } from "../../services/util";
import { validateEmail, validateName } from "../../services/validation";
import createMember from "./createMember";

function CommunityManagersMessages() {
  const { userState } = useUserContext();
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [officerndId, setOfficerndId] = React.useState("");
  const [emailAddress, setEmailAddress] = React.useState("");
  const [submitting, setSubmitting] = React.useState(false);

  const [validFirstName, setValidFirstName] = React.useState(false);
  const [validLastName, setValidLastName] = React.useState(false);
  const [validEmail, setValidEmail] = React.useState(false);

  React.useEffect(() => {
    const result = validateName(firstName);
    setValidFirstName(result);
  }, [firstName]);

  React.useEffect(() => {
    const result = validateName(lastName);
    setValidLastName(result);
  }, [lastName]);

  React.useEffect(() => {
    const result = validateEmail(emailAddress);
    setValidEmail(result);
  }, [emailAddress]);

  const handleCreateMember = async event => {
    event.preventDefault();
    try {
      setSubmitting(true);
      const payload = {
        firstName,
        lastName,
        emailAddress: emailAddress.toLocaleLowerCase(),
        officerndId,
      };

      const response = await createMember(userState.accessToken, payload);
      if (response.status === "success") {
        notify(`Successfully sent invite to ${firstName} ${lastName}`, "success");
        setFirstName("");
        setLastName("");
        setEmailAddress("");
        setOfficerndId("");
      } else if (response.status === "fail") {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support";
          }
        }
        notify(displayMessage, "error");
      }
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      {/* <!-- We've used 3xl here, but feel free to try other max-widths based on your needs --> */}
      <div className="mx-auto max-w-3xl">
        {/* <!-- Content goes here --> */}
        <>
          <div className="rounded-md bg-yellow-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                {/* <!-- Heroicon name: mini/exclamation-triangle --> */}
                <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path
                    fillRule="evenodd"
                    d="M8.485 3.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 3.495zM10 6a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 6zm0 9a1 1 0 100-2 1 1 0 000 2z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="ml-3">
                <h3 className="text-lg font-medium text-yellow-800">In order to create a new user, please make sure these requirements are met:</h3>
                <div className="mt-2 text-sm text-yellow-700">
                  <ul className="list-disc pl-3">
                  <li>The user you are trying to invite must be an active COhatch member and have a membership setup in OfficeRnD.</li>
                  <li>The user’s first name, last name, and email address must match the user's OfficeRnD profile exactly.</li>
                  <li>You must obtain the member ID from OfficeRnD. This ID usually starts with a “6” and is 24 characters long. If you cannot find their ID, please refer back to the training in Rise.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <form onSubmit={handleCreateMember} className="mt-5 space-y-6">
            <div>
              <label htmlFor="firstname" className="block text-sm font-medium text-gray-700">
                First Name
              </label>
              <div className="mt-1">
                <input
                  onChange={event => setFirstName(event.target.value)}
                  value={firstName}
                  id="firstname"
                  name="firstname"
                  type="text"
                  autoComplete="off"
                  className={generateClassName(!validFirstName && firstName)}
                />
              </div>
            </div>

            <div>
              <label htmlFor="lastname" className="block text-sm font-medium text-gray-700">
                Last Name
              </label>
              <div className="mt-1">
                <input
                  onChange={event => setLastName(event.target.value)}
                  value={lastName}
                  id="lastname"
                  name="lastname"
                  type="text"
                  autoComplete="off"
                  className={generateClassName(!validLastName && lastName)}
                />
              </div>
            </div>

            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email Address
              </label>
              <div className="mt-1">
                <input
                  onChange={event => setEmailAddress(event.target.value)}
                  value={emailAddress}
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="off"
                  className={generateClassName(!validEmail && emailAddress)}
                />
              </div>
            </div>

            <div>
              <label htmlFor="officerndId" className="block text-sm font-medium text-gray-700">
                Officernd ID
              </label>
              <div className="mt-1">
                <input
                  onChange={event => setOfficerndId(event.target.value)}
                  value={officerndId}
                  id="officerndId"
                  name="officerndId"
                  type="text"
                  autoComplete="off"
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <button
                type="submit"
                disabled={!validFirstName || !validLastName || !validEmail || !officerndId || submitting ? true : false}
                className="flex w-full justify-center rounded-md border border-transparent bg-primary-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                {submitting ? "Please wait..." : "Send Invite"}
              </button>
            </div>
          </form>
        </>
      </div>
    </div>
  );
}

export default CommunityManagersMessages;
