/*eslint-disable*/
import React, { useEffect, useRef } from "react";
import { PaperClipIcon } from "@heroicons/react/20/solid";
import { useLocation, useParams } from "react-router-dom";
import { useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import useUserContext from "../../../hooks/useUserContext";
//import getAppById from "./requests/getAppById";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { capitalizeFirstLetter } from "../../../services/util";
import getImpactReportById from "./requests/getImpactReportById";
import notify from "../../../services/toast";

function ImpactReportFile() {
	//const app = location.state.appInfo;
	const [loader, setLoader] = useState(false);
	const [showApplicantModal, setShowApplicantModal] = useState(false);
	const navigate = useNavigate();
	const { userState } = useUserContext();
	//const [submitting, setSubmitting] = useState(false);
	const params = useParams();
	const { reportId } = params;
	const [reportInfo, setReportInfo] = useState("");
	const [fetching, setFetching] = React.useState(false);
	const [application, setApplication] = React.useState("");
	const [submitting, setSubmitting] = useState(false);
	//const market = reportInfo.market;

	const pdfRef = useRef();

	const downloadPDF = () => {
		const input = document.querySelector(".full-application");
		//const input = pdfRef.current
		setLoader(true);
		html2canvas(input).then((canvas) => {
			const imgData = canvas.toDataURL("image/png");
			const pdf = new jsPDF("p", "pt", "a4");
			const pdfWidth = pdf.internal.pageSize.getWidth();
			const pdfHeight = pdf.internal.pageSize.getHeight();
			const imgWidth = canvas.width;
			const imgHeight = canvas.height;
			const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
			const imgX = (pdfWidth * ratio) / 2;
			const imgY = 30;
			pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
			setLoader(false);
			pdf.save("MarketReport.pdf");
		});
	};

	const renderGoalEvents = (event, index) => {
		return (
			<>
				<div className=" w-1/4 bg-white h-12" key={index}>
					{event.eventName}
				</div>
				<div className=" w-1/4 bg-white h-12">0</div>
				<div className=" w-1/4 bg-white h-12">0</div>
				<div className=" w-1/4 bg-white h-12">0</div>
				<div className=" w-1/4 bg-white h-12">0</div>
			</>
		);
	};

	const fetchImpactReportById = async (reportId, accessToken) => {
		try {
			const response = await getImpactReportById(reportId, accessToken);
			if (response.status === "success") {
				console.log(response, "reports");
				setReportInfo(response.data);

				//  window.location.reload();
				//notify("Successfully Generated!")
			} else if (response.status === "fail") {
				let displayMessage = "";
				if (Array.isArray(response.message)) {
					displayMessage = response.message[0].message;
				} else {
					displayMessage = response.message;
					if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
						displayMessage =
							"An error has occurred. Please try again later. If this error persists please contact support@cohatchplus.com.";
					}
				}
				notify(displayMessage, "error");
			}
		} catch (error) {
			notify("Something went wrong. Please try again later.", "error");
		}
	};

	useEffect(() => {
		fetchImpactReportById(reportId, userState.accessToken);
	}, []);

	return (
		<>
			<div className="m-10 mb-10 max-w-7xl">
				<div className="w-36 mt-10 mb-10 ">
					<div className="">
						<button
							className="w-full bg-success-800 text-white text-center rounded py-2 shadow-sm px-2.5 py-2 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800 transition ease-linear duration-500"
							onClick={downloadPDF}
							disabled={!(loader === false)}
						>
							{loader ? <span>Downloading</span> : <span>Download</span>}
						</button>
					</div>
				</div>
				<div className=" border-solid border-2 border-black  " ref={pdfRef}>
					<div className="bg-white border-solid border-2 border-black full-application">
						<div className="pt-5 pb-10 p-3 text-white font-bold text-5xl bg-primary-700 tracking-wide">
							<h1>Market Impact Business Plan & Report</h1>
							<h1>
								Market:{" "}
								{reportInfo.market
									? `${capitalizeFirstLetter(reportInfo.market)}`
									: ""}{" "}
							</h1>
							<h1>Month: {reportInfo.month}</h1>
							<h1>
								Dates: {reportInfo.headerStartDate} - {reportInfo.headerEndDate}
							</h1>
						</div>

						<div className=" mb-15 "></div>

						<div className=" divide-y divide-black bg-white border-2 border-black">
							<div className="divide-y divide-black goal">
								<div className="text-center p-3 font-bold">
									<h1>Scholarships</h1>
								</div>
								<div className="text-center p-2 text-white bg-primary-600 font-bold">
									<h1>Goal</h1>
								</div>
								{/* HEADER */}
								<div className="flex text-center tracking-wide font-bold divide-x divide-black">
									<div className="w-1/6  h-12">Scholarships</div>
									<div className="w-1/6  h-12">Total Locations #</div>
									<div className="w-1/6  h-12">
										Total Applications Received #
									</div>
									<div className="w-1/6  h-12">
										Total Scholarships Awarded #
									</div>
									<div className="w-1/6  h-12">
										Average Amount Scholarships $
									</div>
									<div className="w-1/6  h-12">
										Total Awarded Scholarships $
									</div>
								</div>
								{/* GIVE */}
								<div className="flex text-center  divide-x  divide-black">
									<div className=" w-1/6 bg-white h-12">Give Scholarship</div>
									<div className=" w-1/6 bg-white h-12">
										{reportInfo.totalLocationsGoalGive}
									</div>
									<div className=" w-1/6 bg-white h-12">
										{reportInfo.totalApplicationsGoalGive}
									</div>
									<div className=" w-1/6 bg-white h-12">
										{reportInfo.totalApprovedApplicationsGoalGive}
									</div>
									<div className=" w-1/6 bg-white h-12">
										${reportInfo.averageAmountScholarshipGoalGive}
									</div>
									<div className=" w-1/6 bg-white h-12">
										${reportInfo.totalAwardGoalGive}
									</div>
								</div>
								{/* BOOST */}
								<div className="flex text-center  divide-x  divide-black">
									<div className=" w-1/6 bg-white h-12">Boost Scholarship</div>
									<div className=" w-1/6 bg-white h-12">
										{reportInfo.totalLocationsGoalBoost}
									</div>
									<div className=" w-1/6 bg-white h-12">
										{reportInfo.totalApplicationsGoalBoost}
									</div>
									<div className=" w-1/6 bg-white h-12">
										{reportInfo.totalApprovedApplicationsGoalBoost}
									</div>
									<div className=" w-1/6 bg-white h-12">
										${reportInfo.averageAmountScholarshipGoalBoost}
									</div>
									<div className=" w-1/6 bg-white h-12">
										${reportInfo.totalAwardGoalBoost}
									</div>
								</div>
								{/* COMMUNITY PARTNER  */}
								<div className="flex text-center  divide-x  divide-black">
									<div className=" w-1/6 bg-white h-12">
										Community Partner Scholarship
									</div>
									<div className=" w-1/6 bg-white h-12">n/a</div>
									<div className=" w-1/6 bg-white h-12">n/a</div>
									<div className=" w-1/6 bg-white h-12">n/a</div>
									<div className=" w-1/6 bg-white h-12">n/a</div>
									<div className=" w-1/6 bg-white h-12">n/a</div>
								</div>
							</div>

							<div className="divide-y divide-black new-this-month">
								<div className="text-center p-2 text-white bg-primary-600 font-bold">
									<h1>Actual</h1>
								</div>
								{/* HEADERs */}
								<div className="flex text-center tracking-wide font-bold divide-x divide-black">
									<div className="w-1/5  h-12">Scholarships</div>
									<div className="w-1/5  h-12">
										Total Applications Received #
									</div>
									<div className="w-1/5  h-12">
										Total Scholarships Awarded #
									</div>
									<div className="w-1/5  h-12">
										Average Amount Scholarships ${" "}
									</div>
									<div className="w-1/5  h-12">
										Total Awarded Scholarships $
									</div>
								</div>
								{/* GIVE */}
								<div className="flex text-center  divide-x divide-black">
									<div className="w-1/5 font- h-12">Give Scholarship</div>
									<div className=" w-1/5 bg-white h-12">
										{reportInfo.totalApplicationsGive}
									</div>
									<div className=" w-1/5 bg-white h-12">
										{reportInfo.totalApprovedApplicationsGive}
									</div>
									<div className=" w-1/5 bg-white h-12">
										$
										{reportInfo?.averageAmountScholarshipGive
											? reportInfo.averageAmountScholarshipGive.toLocaleString()
											: "0"}
									</div>
									<div className=" w-1/5 bg-white h-12">
										$
										{reportInfo?.totalAwardGive
											? reportInfo.totalAwardGive.toLocaleString()
											: "0"}
									</div>
								</div>
								{/* BOOST */}
								<div className="flex text-center   divide-x divide-black">
									<div className="w-1/5  h-12">Boost Scholarships</div>
									<div className=" w-1/5 bg-white h-12">
										{reportInfo.totalApplicationsBoost}
									</div>
									<div className=" w-1/5 bg-white h-12">
										{reportInfo.totalApprovedApplicationsBoost}
									</div>
									<div className=" w-1/5 bg-white h-12">
										$
										{reportInfo?.averageAmountScholarshipBoost
											? reportInfo.averageAmountScholarshipBoost.toLocaleString()
											: "0"}
									</div>
									<div className=" w-1/5 bg-white h-12">
										$
										{reportInfo?.totalAwardBoost
											? reportInfo.totalAwardBoost.toLocaleString()
											: "0"}
									</div>
								</div>
								{/* COMMUNITY PARTNER  */}
								<div className="flex text-center  divide-x divide-black">
									<div className="w-1/5  h-12">
										Community Partner Scholarships
									</div>
									<div className="w-1/5  h-12">n/a</div>
									<div className="w-1/5  h-12">n/a</div>
									<div className="w-1/5  h-12">n/a</div>
									<div className="w-1/5  h-12">n/a</div>
								</div>
							</div>

							<div className="divide-x divide-black bg-white mb-15"></div>

							<div className="text-center p-3 font-bold">
								<h1>Events</h1>
							</div>
							<div className="divide-y divide-x divide-black new-this-month">
								<div className="text-center p-2 text-white bg-primary-600 font-bold">
									<h1>Goal</h1>
								</div>
								{/* HEADERs */}
								<div className="flex text-center tracking-wide font-bold divide-x divide-black">
									<div className="w-1/4  h-12">All Events</div>
									<div className="w-1/4  h-12">Month</div>
									<div className="w-1/4  h-12">Type of Event</div>
									<div className="w-1/4  h-12">Estimate Total Invites #</div>
									<div className="w-1/4  h-12">RSVP Target #</div>
								</div>
								{/* GIVE */}

								{reportInfo.events?.length ? (
									<div className="flex text-center  divide-x divide-black">
										{reportInfo.events.map((event, index) => {
											return renderGoalEvents(event, index);
										})}
									</div>
								) : (
									<div className="flex text-center  divide-x divide-black">
										<div className="w-1/5 font- h-12">No events</div>
										<div className="w-1/5  h-12">-</div>
										<div className="w-1/5  h-12">-</div>
										<div className="w-1/5  h-12">-</div>

										<div className="w-1/5  h-12">-</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default ImpactReportFile;
