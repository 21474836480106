/* eslint-disable id-blacklist */
/* eslint-disable no-console */

import React, { useState, useEffect, useRef, useCallback } from "react";
import { format } from "date-fns";
import Pagination from "../../components/pagination/Pagination";
import Prompt from "../../components/prompt/Prompt";
import List from "../../components/list/List";
import ButtonDanger from "../../components/button/ButtonDanger";
import ButtonPrimary from "../../components/button/ButtonPrimary";
import ButtonSecondary from "../../components/button/ButtonSecondary";
import AdminListModal from "../../components/admin_list_modal/AdminListModal";
import useUserContext from "../../hooks/useUserContext";
import { debounce, showPrompt, generateClassName } from "../../services/util";
import { MAGIC_NUMBERS, ADMINTYPE } from "../../constant";
import notify from "../../services/toast";
import getContactMessages from "./getContactMessages";
import deleteContactMessage from "./deleteContactMessage";
import updateContactMessage from "./updateContactMessage";
import createContactMessageReply from "./createContactMessageReply";

function TechnicalSupportContact() {
  const { userState } = useUserContext();
  const [contactMessages, setContactMessages] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [isContactMessagesLoading, setIsContactMessagesLoading] = useState(true);
  const [isUpdatingContactMessage, setIsUpdatingContactMessage] = useState(false);
  const [pagination, setPagination] = useState({ limit: 10, total: 0, page: 0, remaining: 0 });
  const [displayPromptMessage, setDisplayPromptMessage] = useState({ heading: "", body: "" });
  const [selectedContactMessages, setSelectedContactMessages] = useState([]);
  const [selectedContactMessage, setSelectedContactMessage] = useState(null);
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [showReply, setShowReply] = useState(false);
  const [reply, setReply] = useState("");

  const deletePromptRef = useRef(null);

  const fetchContactMessages = async (accessToken, page, _keyword) => {
    setIsContactMessagesLoading(true);
    const response = await getContactMessages({
      page,
      keyword: _keyword,
      limit: pagination.limit,
    }, accessToken);
    if (response.status === "success") {
      const { limit, page, remaining, total, results } = response.data;
      setPagination({ limit, page: page, remaining, total });
      setContactMessages(results);
    } else if (response.status === "fail") {
      let displayMessage = "";
      if (Array.isArray(response.message)) {
        displayMessage = response.message[0].message;
      } else {
        displayMessage = response.message;
        if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
          displayMessage = "An error has occurred. Please try again later. If this error persists please contact support";
        }
      }
      notify(displayMessage, "error");
    }
    setIsContactMessagesLoading(false);
  };

  const handleSearch = event => {
    const query = event.target.value;
    fetchContactMessages(userState.accessToken, 0, query);
    setKeyword(query);
  };

  const debouncedHandleSearch = useCallback(debounce(handleSearch, 500), []);

  const handlePrevFetch = async () => {
    await fetchContactMessages(userState.accessToken, pagination.page - 1, keyword);
  };

  const handleNextFetch = async () => {
    await fetchContactMessages(userState.accessToken, pagination.page + 1, keyword);
  };

  const handleDeletePrompt = () => {
    showPrompt(
      "Delete Contact Message",
      "Are you sure you want to delete selected contact messages? This action can't be undone.",
      deletePromptRef,
      "open",
      setDisplayPromptMessage
    );
  };

  const handleDeleteContactMessage = async () => {
    const response = await deleteContactMessage(selectedContactMessages, userState.accessToken);
    if (response.status === "success") {
      notify("Contact message successfully deleted", "info");
      deletePromptRef.current.closePrompt();
      setSelectedContactMessages([]);
      fetchContactMessages(userState.accessToken, 0, keyword);
    } else if (response.status === "fail") {
      let displayMessage = "";
      if (Array.isArray(response.message)) {
        displayMessage = response.message[0].message;
      } else {
        displayMessage = response.message;
        if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
          displayMessage = "An error has occurred. Please try again later. If this error persists please contact support";
        }
      }
      deletePromptRef.current.closePrompt();
      notify(displayMessage, "error");
    }
  };

  const handleRowClick = async (item) => {
    setSelectedContactMessage(item);
    setSelectedContactMessages([]);
    if (!item.read) {
      try {
        const response = await updateContactMessage(item._id, {
          read: true
        }, userState.accessToken);
        if (response.status === "success") {
          fetchContactMessages(userState.accessToken, pagination.page, keyword);
        } else if (response.status === "fail") {
          let displayMessage = "";
          if (Array.isArray(response.message)) {
            displayMessage = response.message[0].message;
          } else {
            displayMessage = response.message;
            if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
              displayMessage = "An error has occurred. Please try again later. If this error persists please contact support";
            }
          }
          notify(displayMessage, "error");
        }
      } catch (error) {
        notify("Something went wrong. Please try again later", "error");
      }
    }
  };

  const handleAssign = async (admin) => {
    if (!selectedContactMessage.assignee || selectedContactMessage.assignee._id !== admin) {
      setIsUpdatingContactMessage(true);
      try {
        const response = await updateContactMessage(selectedContactMessage._id, {
          assignee: admin
        }, userState.accessToken);
        if (response.status === "success") {
          fetchContactMessages(userState.accessToken, pagination.page, keyword);
          notify("Admin successfully assigned", "info");
          setShowAdminModal(false);
          setSelectedContactMessage({
            ...selectedContactMessage,
            assignee: {
              ...selectedContactMessage.assignee,
              _id: admin
            }
          });
        } else if (response.status === "fail") {
          let displayMessage = "";
          if (Array.isArray(response.message)) {
            displayMessage = response.message[0].message;
          } else {
            displayMessage = response.message;
            if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
              displayMessage = "An error has occurred. Please try again later. If this error persists please contact support";
            }
          }
          notify(displayMessage, "error");
        }
        setIsUpdatingContactMessage(false);
      } catch (error) {
        notify("Something went wrong. Please try again later", "error");
        setIsUpdatingContactMessage(false);
      }
    }
  };

  const handleReply = async () => {
    setIsUpdatingContactMessage(true);
    try {
      const response = await createContactMessageReply({
        message: reply,
        messageId: selectedContactMessage._id
      }, userState.accessToken);
      if (response.status === "success") {
        notify("Successfully replied", "info");
        setShowReply(false);
        setReply("");
      } else if (response.status === "fail") {
        let displayMessage = "";
        if (Array.isArray(response.message)) {
          displayMessage = response.message[0].message;
        } else {
          displayMessage = response.message;
          if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
            displayMessage = "An error has occurred. Please try again later. If this error persists please contact support";
          }
        }
        notify(displayMessage, "error");
      }
      setIsUpdatingContactMessage(false);
    } catch (error) {
      notify("Something went wrong. Please try again later", "error");
      setIsUpdatingContactMessage(false);
    }
  };

  useEffect(() => {
    fetchContactMessages(userState.accessToken, 0, "");
  }, []);

  return (
    <>
      {!selectedContactMessage && <>
        <div className="w-full flex flex-col sm:flex-row justify-between">
          <input
            type="text"
            name="search"
            onInput={debouncedHandleSearch}
            placeholder="Search messages"
            className="block border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 max-full sm:max-w-md sm:text-base"
          />
        </div>
        {selectedContactMessages.length > 0 && <div className="text-right mt-2">
          <div className="w-32 ml-auto">
            <ButtonDanger
              text="Delete"
              action={handleDeletePrompt}
            />
          </div>
        </div>}
        <List
          isLoading={isContactMessagesLoading}
          columns={[{
            name: "Name",
            width: "w-56",
            value: (message) => <div className="py-2">
              {message.firstName} {message.lastName}
            </div>,
            type: "render"
          }, {
            name: "type",
            width: "w-60",
            value: (message) => <div className="w-60 bg-success-800 text-white text-center rounded-md p-2">
              {message.emailAddress}
            </div>,
            type: "render"
          }, {
            name: "Message",
            value: (message) => <div className="p-2">
              {message.message.length > 30 ? `${message.message.substring(0, 50)}...` : message.message}
            </div>,
            type: "render"
          }, {
            name: "Date",
            width: "w-20",
            value: (message) => <div className="py-2">
              {format(new Date(message.createdAt), "MMM dd")}
            </div>,
            type: "render"
          }, {
            name: "Read",
            width: "w-6",
            value: (message) => <div className="py-3">
              <div className={`w-3 h-3 rounded-md ${!message.read ? "bg-primary-400" : "bg-success-800"}`}></div>
            </div>,
            type: "render"
          }]}
          data={contactMessages}
          selectedItems={selectedContactMessages}
          setSelectedItems={setSelectedContactMessages}
          onRowClick={handleRowClick}
          keyItem="_id"
        />
        <Pagination pagination={pagination} next={handleNextFetch} prev={handlePrevFetch} />
      </>}
      {selectedContactMessage &&
        <div className="mt-8">
          <div className="flex justify-between bg-white mb-3 py-2 items-center shadow ring-1 ring-black ring-opacity-5">
            <div className="flex items-center" onClick={() => setSelectedContactMessage(null)}>
              <div className="flex cursor-pointer px-3">
                <span className="material-icons-outlined text-md">chevron_left</span>
              </div>
              <div>
                <div className="text-success-800 text-sm">{selectedContactMessage.firstName} {selectedContactMessage.lastName}</div>
                <div className="text-gray-400 text-sm">{selectedContactMessage.emailAddress}</div>
              </div>
            </div>
            <div className="cursor-pointer px-3" onClick={() => setShowReply(!showReply)}>
              <span className="material-icons-outlined text-md">reply</span>
            </div>
          </div>
          <div className="flex mt-6 justify-between">
            <div>
              <div className="text-success-800 text-sm">{selectedContactMessage.firstName} {selectedContactMessage.lastName}</div>
              <div className="text-gray-400 text-xs">{format(new Date(selectedContactMessage.createdAt), "iiii, MMM dd")}</div>
            </div>
            <div>
              <ButtonSecondary
                text="Assign"
                action={() => setShowAdminModal(true)}
              />
            </div>
          </div>
          <div className="mt-3 text-gray-400 text-sm mb-3">{selectedContactMessage.message}</div>
          {showReply &&
            <>
              <textarea
                rows="8"
                onChange={(event) => setReply(event.target.value)}
                value={reply}
                name="reply"
                id="reply"
                className={generateClassName(false)}
              ></textarea>
              <div className="text-right w-28 ml-auto mt-3">
                <ButtonPrimary text="Send" action={handleReply} />
              </div>
            </>
          }
        </div>
      }
      <Prompt header={displayPromptMessage.heading} body={displayPromptMessage.body} ref={deletePromptRef} action={handleDeleteContactMessage} />
      <AdminListModal
        open={showAdminModal}
        loading={isUpdatingContactMessage}
        types={[ADMINTYPE.COMMUNITY_MANAGER, ADMINTYPE.TECHNICAL_SUPPORT]}
        defaultValue={selectedContactMessage && selectedContactMessage.assignee ? selectedContactMessage.assignee._id : ""}
        onAssign={handleAssign}
        onClose={() => setShowAdminModal(false)}
      />
    </>
  );
}

export default TechnicalSupportContact;
